@charset "utf-8";

@function svgIcon($bgColor: 1) {
	@if $bgColor == "none" {
		$bgColor: $bgColor;
	} @else {
		$bgColor: "%23" + $bgColor;
	}

	$icon: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12'%3E%3Cpath d='M4.314 4.186L0 4.572 3.276 7.56l-.984 4.439L6 9.644l3.708 2.355-.984-4.439 3.277-2.988-4.314-.386L6 0z' fill='#{$bgColor}' fill-rule='evenodd'/%3E%3C/svg%3E";
	@return url($icon);
}

/* reset */
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
textarea,
p,
blockquote,
th,
td,
input,
select,
textarea,
button {
	margin: 0;
	padding: 0;
}
fieldset,
img {
	border: 0;
}
dl,
ul,
ol,
menu,
li {
	list-style: none;
}
blockquote,
q {
	quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
	content: "";
	content: none;
}
input,
select,
textarea,
button {
	font-size: 100%;
	vertical-align: middle;
	box-sizing: border-box;
}
button {
	border: 0 none;
	background-color: transparent;
	cursor: pointer;
	-webkit-tap-highlight-color: none;
}
textarea:focus,
.inp:focus,
.box_price_frm.focus .inp:focus,
.box_task .on .inp.mdf_mode:focus {
	outline: none;
	border: 1px solid #ffc000;
}
table {
	border-spacing: 0;
	border-collapse: collapse;
	width: 100%;
	table-layout: fixed;
}
body {
	-webkit-text-size-adjust: none;
	overflow-x: hidden;
}
input[type="text"],
input[type="password"],
input[type="submit"],
input[type="search"],
input[type="tel"],
input[type="number"],
input[type="email"] {
	border-radius: 0;
	-webkit-appearance: none;
}
input:checked[type="checkbox"] {
	background-color: #666;
	-webkit-appearance: checkbox;
}
button,
input[type="button"],
input[type="submit"],
input[type="reset"],
input[type="file"] {
	border-radius: 0;
	-webkit-appearance: button;
}
input[type="number"],
input[type="tel"],
input[type="date"],
textarea,
select {
	border-radius: 0;
}
input[type="search"]::-webkit-search-cancel-button {
	-webkit-appearance: none;
}
body,
th,
td,
input,
select,
textarea,
button {
	font-family: "AppleSDGothicNeo-Regular", sans-serif;
	font-size: 14px;
	line-height: 1.5;
	color: #555;
}
a {
	color: inherit;
	text-decoration: none;
}
a:active,
a:hover {
	text-decoration: none;
}
address,
caption,
cite,
code,
dfn,
em,
var {
	font-weight: normal;
	font-style: normal;
}
.blind {
	visibility: hidden;
	overflow: hidden;
	position: absolute;
	top: -1px;
	left: -1px;
	width: 1px;
	height: 1px;
	font-size: 0;
	line-height: 0;
}
html,
body {
	height: 100%;
}

/* common */
.ir,
legend {
	display: block;
	overflow: hidden;
	font-size: 0px;
	line-height: 0;
	text-indent: -9999px;
} /* 사용된 이미지내 의미있는 텍스트의 대체텍스트를 제공할때 */
.ir_wa {
	display: block;
	overflow: hidden;
	position: relative;
	z-index: -10;
	width: 100%;
	height: 100%;
} /* 중요한 이미지 대체텍스트로 이미지off시에도 대체 텍스트를 보여주고자 할때 */
.fl {
	float: left;
}
.fr {
	float: right;
}
.clearfix {
	width: 0;
	height: 0;
	font-size: 0;
	line-height: 0;
	clear: both;
}
.show {
	display: block;
}
.hide,
caption {
	display: none;
}
.tbl {
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
} /* 테이블 초기화 */
.clear_g {
	display: block;
	overflow: visible;
	width: auto;
	clear: both;
	*zoom: 1;
}
.clear_g:after {
	display: block;
	visibility: hidden;
	height: 0;
	font-size: 0;
	clear: both;
	content: "";
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
	color: #cecece;
}
.ellipsis {
	width: 100%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.ellipsis2 {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	word-wrap: break-word;
	line-height: 15px;
	max-height: 30px;
}
.break {
	word-break: keep-all;
	word-wrap: break-word;
}

.rd,
.chk,
.rd[type="radio"]:checked,
.chk[type="checkbox"]:checked,
.select {
	appearance: none;
	-moz-appearance: none; /* Firefox */
	-webkit-appearance: none; /* Safari and Chrome */
}
.rd:focus,
.chk:focus {
	outline: none;
}
.btn_cm {
	display: inline-block;
	font-size: 0;
	vertical-align: middle;
	box-sizing: border-box;
}
.ico_cm {
	display: inline-block;
	vertical-align: middle;
}
.btn_cm:before,
.ico_cm:before {
	display: inline-block;
	vertical-align: middle;
	content: "";
}
.btn_cm:before,
.btn_cm:after,
.rd,
.chk {
	background: url(../img/comm_btn.png) no-repeat;
	background-size: 320px auto;
} /* 버튼 */
.ico_cm:before,
.ico_cm:after {
	background: url(../img/comm_ico.png) no-repeat;
	background-size: 320px auto;
} /* 아이콘 */

.splitCell,
.splitFrm {
	display: table;
	width: 100%;
}
.splitCell > .cell,
.splitFrm > .cell {
	display: table-cell;
	vertical-align: middle;
}
.splitBox {
	display: -webkit-box;
	-webkit-box-pack: justify;
	-webkit-box-align: center;
}
.col05 {
	width: 5%;
}
.col1 {
	width: 10%;
}
.col2 {
	width: 20%;
}
.col3 {
	width: 30%;
}
.col4 {
	width: 40%;
}
.col5 {
	width: 50%;
}
.col6 {
	width: 60%;
}
.col7 {
	width: 70%;
}
.col8 {
	width: 80%;
}
.col9 {
	width: 90%;
}
.auto {
	width: auto;
}
.fix {
	table-layout: fixed;
}
.full_inp .inp_wrap {
	width: 100%;
}
.splitFrm > .cell {
	padding: 0 2px;
}
.splitFrm > .cell:first-child {
	padding-left: 0 !important;
}
.splitFrm > .cell:last-child {
	padding-right: 0 !important;
}

.al {
	text-align: left !important;
}
.ar {
	text-align: right !important;
}
.ac {
	text-align: center !important;
}
.vt {
	vertical-align: top !important;
}
.vm {
	vertical-align: middle !important;
}
.vb {
	vertical-align: bottom !important;
}
.marT0 {
	margin-top: 0 !important;
}
.marT5 {
	margin-top: 5px !important;
}
.marT10 {
	margin-top: 10px !important;
}
.marT25 {
	margin-top: 25px !important;
}
.marB0 {
	margin-bottom: 0 !important;
}
.marTm1 {
	margin-top: -1px !important;
}

.underline {
	text-decoration: underline;
}
.t_fix {
	table-layout: fixed;
}
.link_line,
.link_line:hover {
	text-decoration: underline;
}
.color_point {
	color: #ff5b59;
}

/* btn */
button,
a,
a:focus {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0.3);
}
.btnA,
.btnB,
.btnC,
.btnD,
.btnE,
.btnF {
	display: inline-block;
	box-sizing: border-box;
	padding: 0 15px;
	color: #fff;
	font-size: 16px;
	text-align: center;
	line-height: 45px;
	vertical-align: middle;
}
.btnB {
	background-color: #555;
}
.btnC,
.btn_area > .btnC:first-child {
	background-color: #fff;
	border: 1px solid #555;
	color: #444;
	line-height: 35px;
}
.btnC.sizeL,
.btn_area > .btnC.sizeL {
	line-height: 43px;
}
.btnD,
.btn_area > .btnD:first-child {
	border: 1px solid #e6e6e6;
	color: #444;
	line-height: 43px;
}
.btnE {
	background: #e85656;
}
.btnF {
	background: #ff5b59;
	&[disabled] {
		background-color: #ccc;
		color: #fff;
		pointer-events: none;
	}
}
.btn_area {
	display: -webkit-box;
	-webkit-box-align: center;
	-webkit-box-pack: justify;
	-webkit-box-orient: horizontal;
	width: 100%;
	margin: 15px 0 0 0;
	&.ty_flex {
		display: flex;
		align-items: center;
		button {
			flex: 0 1 auto;
		}
	}
}
.btn_area > button,
.btn_area > a {
	display: block;
	-webkit-box-flex: 1;
	width: 100%;
	padding: 0;
	border-left: 1px solid #fff;
	font-weight: bold;
}
.btn_area > button:first-child,
.btn_area > a:first-child {
	border-left: 0;
}
.btn_foot {
	margin-top: 15px;
	text-align: center;
}
.btn_wbox {
	display: inline-block;
	padding: 3px 10px;
	border: 1px solid #d8dde0;
	font-size: 12px;
}
.btn_select {
	position: relative;
	display: block;
	width: 100%;
	padding: 10px 30px 10px 10px;
	box-sizing: border-box;
}
/* btn_size*/
.btnA.sizeM,
.btnB.sizeM {
	font-size: 13px;
	line-height: 30px;
}
.btnC.sizeM {
	font-size: 13px;
	line-height: 28px;
}
.btnA.sizeL,
.btnB.sizeL {
	font-size: 16px;
	line-height: 45px;
}
.btnC.sizeL {
	font-size: 16px;
	line-height: 43px;
}
.btnB.sizeXL {
	font-size: 15px;
	line-height: 50px;
}
/* btn_txt */
.btn_cm .txt_l,
.btn_cm .txt_t {
	display: inline-block;
	vertical-align: middle;
	font-size: 14px;
	line-height: 1.2;
}
.btn_cm .txt_l:before,
.btn_cm .txt_t:before {
	font-size: 14px;
}
.btn_cm .txt_l {
	margin-left: 8px;
}
.btn_cm .txt_t {
	display: block;
	margin-top: 4px;
}
/* btn_icon */
a.btn_com,
button.btn_cm {
	font-size: 0;
}
.btn_cm.confirm:before {
	width: 22px;
	height: 15px;
	background-position: -15px 0;
}
.btn_cm.cancel:before {
	width: 15px;
	height: 15px;
	background-position: 0 0;
}
.btn_cm.delete:before {
	width: 19px;
	height: 19px;
	background-position: -136px 0;
}
.btn_cm.write,
.btn_cm.tel {
	width: 40px;
	height: 40px;
	border-radius: 50px;
	border: 1px solid #d7d7d7;
	text-align: center;
	line-height: 40px;
}
.btn_cm.write:before {
	background-position: -173px 0;
	width: 18px;
	height: 17px;
}
.btn_cm.write2:before {
	background-position: -212px 0;
	width: 24px;
	height: 22px;
}
.btn_cm.tel:before {
	background-position: -192px 0;
	width: 19px;
	height: 19px;
}
.btn_cm.addfile,
.btn_cm.addfile2:before {
	width: 45px;
	height: 45px;
	border-radius: 55px;
	border: 1px solid #d7d7d7;
	text-align: center;
	line-height: 40px;
	background: none;
}
.btn_cm.addfile:before,
.btn_cm.addfile2:after {
	display: inline-block;
	content: "";
	background-position: 0 -15px;
	width: 18px;
	height: 17px;
}
.btn_cm.addfile2 {
	position: relative;
	color: #696969;
}
.btn_cm.addfile2:before {
	display: block;
	margin: 0 auto 4px;
	background-color: #fff;
}
.btn_cm.addfile2:after {
	position: absolute;
	top: 13px;
	left: 50%;
	margin-left: -9px;
}
.btn_cm.addfile2 .txt {
	display: block;
	font-size: 11px;
	vertical-align: middle;
	text-align: center;
}
.btn_cm.close:before {
	width: 20px;
	height: 20px;
	background-position: -236px 0;
}
.btn_cm.delete2 {
	position: relative;
}
.btn_cm.delete2:before {
	width: 23px;
	height: 23px;
	background: #e77752;
	border-radius: 40px;
}
.btn_cm.delete2:after {
	position: absolute;
	top: 10px;
	left: 6px;
	content: "";
	width: 11px;
	height: 3px;
	background: #fff;
	text-align: center;
}
.btn_cm.increase,
.btn_cm.decrease {
	position: relative;
	width: 33px;
	height: 33px;
	border: 1px solid #e5e5e5;
	border-radius: 50px;
	text-align: center;
}
.btn_cm.increase:before,
.btn_cm.decrease:before,
.btn_cm.decrease:after {
	position: absolute;
	top: 14px;
	left: 8px;
	width: 15px;
	height: 3px;
	background: #9a9a9a;
}
.btn_cm.decrease:after {
	top: 8px;
	left: 14px;
	content: "";
	width: 3px;
	height: 15px;
}
.btn_cm.checktr {
	border: 2px solid #2cc6c8;
	width: 18px;
	height: 18px;
	border-radius: 20px; /* vertical-align:top; */
}
.btn_cm.checktr:before {
	display: block;
	width: 7px;
	height: 8px;
	margin: 3px 4px;
	background-position: -156px -10px;
}
.body_po .btn_cm.checktr {
	border-color: #2d95c8;
}
.body_po .btn_cm.checktr:before {
	background-position: -165px -10px;
}
.btn_cm.home:before {
	width: 21px;
	height: 18px;
	background-position: -77px -19px;
}
.btn_cm.menu:before {
	width: 21px;
	height: 14px;
	background-position: -256px 0;
}
.btn_cm.sggtalk:before {
	width: 23px;
	height: 17px;
	background-position: -278px 0;
}
.btn_cm.back:before {
	width: 25px;
	height: 18px;
	background-position: -100px -15px;
}
.btn_cm.arr_bt:before {
	width: 16px;
	height: 9px;
	background-position: -20px -19px;
}
.btn_cm.arr_bt.open:before {
	background-position: -54px -19px;
}
.body_po .btn_cm.arr_bt.open:before {
	background-position: -37px -19px;
}
.btn_cm.drag:before {
	width: 23px;
	height: 12px;
	background-position: -147px -21px;
}
.btn_cm.tel2 {
	display: inline-block;
	width: 34px;
	height: 34px;
	border-radius: 50px;
}
.btn_cm.tel2:before {
	display: table;
	width: 19px;
	height: 19px;
	margin: 8px auto 0;
	background-position: -126px -20px;
}
.btn_cm.search:before {
	width: 20px;
	height: 20px;
	background-position: -171px -19px;
}
.btn_cm.close2 {
	position: relative;
	width: 15px;
	height: 16px;
}
.btn_cm.close2:before,
.btn_cm.close2:after {
	position: absolute;
	top: 0;
	left: 0;
	content: "";
	width: 1px;
	height: 20px;
	background: #555;
}
.btn_cm.close2:before {
	-webkit-transform: translate3d(7px, -2px, 0) rotate(45deg);
	transform: translate3d(7px, -2px, 0) rotate(45deg);
}
.btn_cm.close2:after {
	display: inline-block;
	-webkit-transform: translate3d(7px, -2px, 0) rotate(-45deg);
	transform: translate3d(7px, -2px, 0) rotate(-45deg);
}
.btn_cm.prev:before,
.btn_cm.next:before {
	width: 8px;
	height: 13px;
}
.btn_cm.prev:before {
	background-position: -247px -22px;
}
.btn_cm.next:before {
	background-position: -255px -22px;
}
.btn_cm.prev[disabled]:before,
.paging > button[disabled] .prev:before {
	background-position: -231px -22px;
}
.btn_cm.next[disabled]:before,
.paging > button[disabled] .next:before {
	background-position: -239px -22px;
}
.btn_cm.add:before {
	width: 20px;
	height: 20px;
	background-position: -264px -20px;
}
.btn_cm.ssg-tooltip {
	line-height: 0;
}
.btn_cm.ssg-tooltip:before {
	width: 15px;
	height: 15px;
	background-position: -285px -20px;
}
.btn_cm.camera:before {
	width: 20px;
	height: 15px;
	background-position: 0 -33px;
}
.btn_cm.album:before {
	width: 20px;
	height: 14px;
	background-position: -21px -33px;
}
.btn_cm.alb_camera:before {
	width: 28px;
	height: 19px;
	background-position: -42px -31px;
}
.btn_cm.delete3:before {
	width: 20px;
	height: 24px;
	background-position: -71px -39px;
}
.btn_cm.add2:before {
	width: 21px;
	height: 21px;
	background-position: -92px -38px;
}
.btn_cm.noti {
	position: relative;
}
.btn_cm.noti:before {
	width: 35px;
	height: 35px;
	background-position: 0px -50px;
}
.btn_cm.noti sup {
	position: absolute;
	right: -7px;
	top: -7px;
	width: 22px;
	height: 22px;
	border-radius: 11px;
	font-weight: bold;
	font-size: 16px;
	color: #fff;
	background: #f24642;
	line-height: 22px;
	text-align: center;
}
.btn_cm.delete4:before {
	width: 20px;
	height: 20px;
	background-position: -158px -40px;
}
.btn_cm.add3:before {
	width: 26px;
	height: 26px;
	background-position: -270px -45px;
}
.btn_cm.resetpwd:before {
	width: 22px;
	height: 13px;
	background-position: -298px -45px;
}
.btn_cm.calendar:before {
	width: 19px;
	height: 19px;
	background-position: -81px -65px;
}
.btn_cm.regist_guide {
	height: 17px;
	padding-top: 2px;
	line-height: 15px;
	vertical-align: middle;
}
.btn_cm.regist_guide span {
	display: inline-block;
	color: #444;
	font-size: 13px;
	letter-spacing: -0.5px;
	vertical-align: middle;
}
.btn_cm.regist_guide:before {
	width: 17px;
	height: 17px;
	margin: -2px 3px 0 0;
	background-position: -102px -65px;
}
.btn_cm.media_delete {
	position: absolute;
	top: 10px;
	right: 10px;
	width: 30px;
	height: 30px;
	background: rgba(34, 34, 34, 0.15);
	z-index: 10;
}
.btn_cm.media_delete:before {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 17px;
	height: 19px;
	margin: -9px 0 0 -8px;
	background-position: -153px -65px;
}
.btn_cm.prod_delete:before {
	width: 42px;
	height: 42px;
	background-position: -44px -90px;
}
.btn_cm.change_order:before {
	width: 42px;
	height: 42px;
	background-position: 0 -90px;
}
.btn_cm.prod_remove {
	position: absolute;
	top: -10px;
	right: -10px;
	padding: 5px;
}
.btn_cm.prod_remove:before {
	width: 16px;
	height: 16px;
	background-position: -121px -65px;
}
.btn_cm.play_video {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 60px;
	height: 60px;
	margin: -30px 0 0 -30px;
	z-index: 10;
}
.btn_cm.play_video:before {
	width: 60px;
	height: 60px;
	background-position: -260px -73px;
}
.btn_cm.alert {
	margin-top: 1px;
	vertical-align: top;
}
.btn_cm.alert:before {
	width: 18px;
	height: 18px;
	background-position: -88px -86px;
}

/* point_color */
.pointCol1,
.modal .list_check .rd[type="radio"]:checked + .lab_txt,
.use_store_shipping .tbl_col1 .on td,
.modal .list_check > .on a,
.modal .list_check > .on .lab_txt {
	color: #2dc6c8;
}
.pointBg1,
.btnA,
.header,
.pop_layer .tit_dep1,
.sup_num sup,
.btn_cm.tel2,
.modal .tit_dep1,
.cm_toggle:checked + label {
	background-color: #9a6cf7;
}
/* .body_po .pointCol1,
.body_po .p_select .list_check .rd[type="radio"]:checked + .lab_txt,
.body_po .use_store_shipping .tbl_col1 .on td,
.body_po .p_select .list_check > li.on a,
.body_po .txt_deli_status .pointCol1{color:#2d95c8}
.body_po .pointBg1,
.body_po .btnA,
.body_po .header,
.body_po .pop_layer .tit_dep1,
.body_po .use_store_shipping .sup_num,
.body_po .btn_cm.tel2,
.body_po .modal .tit_dep1,
.body_po .cm_toggle:checked  + label{background-color:#2d95c8;} */
.orange {
	color: #ef633f !important;
}
.point_red {
	color: #ff2f2f !important;
}

/* radio/checkbox */
.rd,
.chk {
	display: inline-block;
	border-radius: 0;
	border: 0;
	vertical-align: middle;
}
.rd {
	width: 15px;
	height: 15px;
	background-position: -106px 0;
}
.rd[disabled] {
	background-position: -121px 0;
}
.rd[type="radio"]:checked {
	background-position: -91px 0;
}
.chk {
	width: 18px;
	height: 18px;
	background-position: -37px 0;
}
.chk[disabled] {
	background-position: -73px 0;
}
.chk[type="checkbox"]:checked {
	background-position: -55px 0;
}
.body_po .chk[type="checkbox"]:checked {
	background-position: -212px -22px;
}
.rd + label,
.chk + label {
	display: inline-block;
	padding-left: 7px;
	vertical-align: middle;
	padding-top: 3px;
}
.fld_chk {
	display: inline-block;
	vertical-align: middle;
}
.fld_area:not(:only-child) {
	margin-bottom: 6px;
}
.fld_area .fld_chk {
	margin-right: 20px;
}
.fld_area .fld_chk:last-child {
	margin-right: 0 !important;
}
.fld_area.type2 .fld_chk {
	margin-right: 10px;
}
.fld_area.no_space {
	overflow: hidden;
}
.fld_area.no_space .fld_chk {
	float: left;
	margin-right: 0;
	width: 100%;
}
.fld_area.no_space .fld_chk.w50 {
	width: 50%;
}

/* selectbox */
.select {
	border: none;
	height: 43px;
	padding: 0 34px 0 10px;
	background: #fff url(../img/comm_select.png) 100% 50% no-repeat;
	background-size: 26px auto;
}
.select.sty2 {
	width: 100%;
	height: 43px;
}
.select[disabled],
.select[readonly] {
	border-color: #e5e5e5;
	color: #555;
	background-color: #f7f7f7;
	background-image: url(../img/comm_select_disabled.png);
}

/* input */
.inp,
.picker__input.inp[readonly] {
	width: 100%;
	border: none;
	height: 43px;
	padding: 0 6px;
	border: 1px solid #e6e6e6;
	background-color: #fff; /* color:#999; */
}
.inp[disabled],
.inp[readonly] {
	border-color: #e5e5e5;
	color: #555 !important;
	background-color: #f7f7f7;
	opacity: 1 !important;
}
.inp[disabled]:focus,
.inp[readonly]:focus {
	border-color: #e6e6e6;
}
.inp_wrap {
	position: relative;
	display: inline-block;
	box-sizing: border-box;
}
.inp_wrap .inp {
	padding-right: 26px;
}
.inp_wrap .inp.no_clear {
	padding: 0 6px;
}
.inp_wrap .inp.no_clear + .clear {
	display: none;
}
.inp_wrap .clear {
	display: none;
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
	width: 28px;
	text-align: center;
}
.inp_wrap .inp[type="search"] {
	padding-right: 66px;
}
.inp_wrap .inp[type="search"] + .clear {
	right: 40px;
}
.inp_wrap .clear.on {
	display: box;
	display: -webkit-box;
	-webkit-box-pack: center;
	-webkit-box-align: center;
}
.inp_wrap .clear:before {
	display: inline-block;
	width: 20px;
	height: 20px;
	line-height: 100%;
	vertical-align: middle;
	content: "";
	background-position: -136px 0;
}
.inp_wrap svg {
	-webkit-transform: translate3d(0, 0, 0);
}
.inp_wrap .inp.ty_scan {
	overflow: hidden;
	padding: 0 8px 0 44px;
	background: url("../img/ico_barcode.png") 12px 50% no-repeat;
	background-size: 20px 21px;
}

/* textarea */
.tf {
	width: 100%;
	border: none;
	padding: 15px 10px;
	border: 1px solid #e6e6e6;
	-webkit-appearance: none;
}
.ta_wrap .clear {
	display: none;
	position: absolute;
	right: -1px;
	top: 50%;
	height: 28px;
	width: 28px;
	margin-top: -14px;
	padding: 4px;
}
.ta_wrap .clear.on {
	display: block;
}
.ta_wrap .clear:before {
	display: inline-block;
	width: 20px;
	height: 20px;
	background-position: -136px 0;
	content: "";
}

/* table_row */
.tbl_row .inp_wrap {
	display: block;
}
.tbl_row .select {
	width: 100%;
}
.tbl_row .file_attach2 {
	display: -webkit-box;
	display: flex;
}
.tbl_row .file_attach2 > .inp_wrap {
	-webkit-box-flex: 1;
	flex: 1;
}
.tbl_row .file_attach2 > .btnA {
	margin-left: 1px;
	line-height: 43px;
}
.tbl_row th {
	padding: 15px 10px 15px 0;
	vertical-align: top;
	text-align: left;
	color: #555;
}
.tbl_row td {
	padding: 15px 0 15px 10px;
	border-bottom: 1px solid #e5e5e5;
}
.tbl_row.data .cm_frm {
	padding: 8px 0;
	padding-left: 0;
}
.tbl_row.frm .cm_txt {
	padding: 13px 0;
}
.tbl_row.frm .cm_txt.line {
	border-bottom: 1px solid #e5e5e5;
}
.tbl_row.frm .cm_txt.line {
	border-bottom: 1px solid #e5e5e5;
}
.tbl_row.frm td {
	padding: 3px 0;
	border: none;
}
.tbl_row.frm .marT5 {
	margin-bottom: 0;
}
.tbl_row.frm .line_btm th,
.tbl_row.frm .line_btm td {
	padding-bottom: 16px;
	border-bottom: 1px solid #e5e5e5;
}
.tbl_row.frm .line_btm + tr th,
.tbl_row.frm .line_btm + tr td {
	padding-top: 16px;
}
.tbl_row.data tr:last-child th,
.tbl_row.data tr:last-child td {
	border: none;
}
.tbl_row .line1 th,
.tbl_row .line1 td {
	padding-top: 0;
	padding-bottom: 0;
}
.tbl_row.data td.nodata {
	padding-left: 0;
}

.tbl_row2 {
	border-top: 1px solid #e5e5e5;
	border-bottom: 1px solid #e5e5e5;
}
.tbl_row2 th,
.tbl_row2 td {
	padding: 12px 10px;
	border-bottom: 1px dotted #e5e5e5;
	border-bottom: 1px dotted #e5e5e5;
	line-height: 17px;
}
.tbl_row2 th {
	background-color: #f7f7f7;
	text-align: center;
	font-weight: normal;
}
.tbl_row2 .al {
	padding-left: 10px;
	padding-right: 10px;
}

.tbl_row3 {
	table-layout: auto;
	border-top: 1px solid #e5e5e5;
	border-bottom: 1px solid #e5e5e5;
	background-color: #f7f7f7;
}
.tbl_row3 th,
.tbl_row3 td {
	padding: 12px 10px;
	border-bottom: 1px dotted #e5e5e5;
	border-bottom: 1px dotted #e5e5e5;
	line-height: 17px;
	word-break: break-all;
	word-wrap: break-word;
}
.tbl_row3 th {
	font-weight: normal;
	text-align: left;
	white-space: nowrap;
}
.tbl_row3 td a.pointCol1 {
	text-decoration: underline;
}
.tbl_row3 td .type_flag {
	vertical-align: -4px;
}

/* table_col */
.tbl_col1 .tbl_hd,
.tbl_col1 > thead > tr > th {
	padding: 0 15px;
	background-color: #f7f7f7;
	border-top: 1px solid #e5e5e5;
	border-bottom: 1px solid #e5e5e5;
}
.tbl_col1 .tbl_bd {
	padding: 0 15px;
}
.tbl_col1 thead th,
.tbl_col1 > thead > tr > th {
	position: relative;
	height: 30px;
	padding: 9px 5px;
	color: #555;
	font-size: 13px;
	font-weight: normal;
	line-height: 15px;
}
.tbl_col1 tbody td {
	position: relative;
	height: 25px;
	padding: 12px 6px;
	border-bottom: 1px solid #e5e5e5;
	vertical-align: middle;
	text-align: center;
	line-height: 19px;
}
.tbl_col1 tfoot th,
.tbl_col1 tfoot td {
	height: 30px;
	padding: 9px 6px;
	background-color: #f7f7f7;
	border-top: 1px solid #e5e5e5;
	border-bottom: 1px solid #e5e5e5;
	color: #555;
	font-size: 13px;
	font-weight: normal;
	text-align: center;
	font-weight: normal;
}
.tbl_col1 tfoot .al {
	padding-left: 15px;
}
.tbl_col1 .nodata {
	border-bottom: none;
}
.tbl_col1.sty2 thead th {
	height: auto;
	padding-top: 8px;
	padding-bottom: 8px;
}
.tbl_col1 td.bdnone {
	border: 0;
}
.tbl_col1 td.dashed {
	border-bottom-style: dashed;
}

.tbl_col2 thead th {
	padding: 12px 5px 11px;
	background-color: #999;
	color: #fff;
	text-align: center;
	font-weight: normal;
}
.tbl_col2 tbody td {
	padding: 12px 5px 13px;
	border-bottom: 1px solid #e5e5e5;
	font-size: 15px;
	text-align: center;
	line-height: 19px;
	vertical-align: middle;
}

.tbl_col3 {
	border-bottom: 1px solid #e5e5e5;
}
.tbl_col3 thead th {
	padding: 14px 5px;
	border-top: 1px solid #e5e5e5;
	background-color: #f7f7f7;
	font-weight: normal;
	color: #222;
}
.tbl_col3 tbody td {
	padding: 14px 5px;
	border-top: 1px solid #e5e5e5;
	color: #222;
	text-align: center;
	word-break: break-all;
}
.tbl_col3 tbody tr.active td {
	background: #ebe2fd;
}
.tbl_col3 tbody tr.active + tr.active td {
	border-top-color: #f2f2f2;
}
.tbl_col3 tbody tr.complete td {
	background: #e8e8e8;
}
.tbl_col3 tbody tr.complete + tr.complete td {
	border-top-color: #e8e8e8;
}
.tbl_col3 tbody .ico_chk {
	display: inline-block;
	width: 18px;
	height: 18px;
	background: url(../img/comm_btn.png) no-repeat;
	background-size: 320px auto;
	background-position: -55px 0;
	vertical-align: middle;
}
.tbl_col3 tbody .type_flag {
	vertical-align: middle;
}
.tbl_col3 tbody .type_flag em,
.tbl_col3 tbody .status_flag {
	font-size: 14px;
	line-height: 18px;
}
.tbl_col3 tbody .deli_star_box {
	vertical-align: top;
}
.tbl_col3.row_divide thead {
	padding: 5px 0;
}
.tbl_col3.row_divide thead th {
	padding: 5px;
}
.tbl_col3.row_divide thead tr:first-child th {
	padding-top: 10px;
}
.tbl_col3.row_divide thead tr:last-child th {
	padding-bottom: 10px;
}
.tbl_col3.row_divide thead tr.division th {
	position: relative;
	border-top: 0 none;
}
.tbl_col3.row_divide thead tr.division th:before {
	position: absolute;
	top: 0;
	left: 5px;
	right: 5px;
	height: 1px;
	background: #d8d8d8;
	content: "";
}
.tbl_col3.row_divide tbody td {
	padding: 5px;
}
.tbl_col3.row_divide tbody tr.division td {
	border-top: 0 none;
}

.nodata {
	padding: 12px 0;
	text-align: center;
}

/* 플리킹 */
.btn_flik {
	position: relative;
	overflow: hidden;
	box-sizing: border-box;
}
.btn_flik .btn_wrap {
	visibility: hidden;
	position: absolute;
	top: 0;
	height: 100%;
	transition: all 0.3s ease;
	z-index: 1;
	border-left: 1px solid #fff;
	border-right: 1px solid #fff;
}
.btn_flik .btn_left {
	left: 0;
}
.btn_flik .btn_right {
	right: 0;
}
.btn_flik .btn_wrap > a {
	float: left;
	height: 100%;
	line-height: 20px;
	padding: 0 30px;
}
.btn_flik .btn_wrap > a:before {
	display: inline-block;
	height: 100%;
	content: "";
	vertical-align: middle;
}
.btn_flik .btn_wrap .txt {
	display: inline-block;
	vertical-align: middle;
}

/* seach_from_box */
.search_wrap .inp_wrap {
	display: block;
}
.search_wrap .inp,
.search_wrap .select {
	width: 100%;
	height: 44px;
	border: none;
}
.search_wrap .select {
	color: #555;
	font-weight: bold;
}
.search_wrap > .splitCell > .cell {
	height: 44px;
	border-right: 1px solid #e5e5e5;
	border-bottom: 1px solid #e5e5e5;
}
.search_wrap > .splitCell.com_h > .cell {
	height: 24px;
	padding: 10px;
}
.search_wrap > .splitCell > .cell:first-child {
	border-left: 1px solid #e5e5e5;
}
.search_wrap > .splitCell:first-child > .cell {
	border-top: 1px solid #e5e5e5;
}
.search_wrap .date_area {
	padding: 0 5px;
	box-sizing: border-box;
}
.search_wrap .date_area .inp {
	height: 26px;
	padding: 0 3px;
	border: 1px solid #e2e7e9;
	border-radius: 30px;
	text-align: center;
	font-size: 13px;
}
.tit_srh {
	padding: 0 10px;
	color: #555;
	font-weight: bold;
	white-space: nowrap;
}

/* date_area */
.date_area .cell {
	width: 50%;
	box-sizing: border-box;
}
.date_area .cell.txt_c,
.tel_area .cell.txt_c {
	width: 5px;
	padding: 0 1px;
	white-space: nowrap;
	text-align: center;
}

/* img_area */
.img_area .cell.img_c {
	width: 37px;
}
.img_area .cell.img_c .img_fix {
	width: 37px;
	height: 37px;
	border: 1px solid #e5e5e5;
	box-sizing: border-box;
}
.img_area .cell.img_c img {
	width: 100%;
	height: 100%;
	vertical-align: top;
}

/* top_area */
.tbl_top .cell {
	padding: 11px 15px;
}
.list_chk_top .cell {
	height: 30px;
	padding: 7px 10px;
}
.list_chk_top .fld_chk label {
	color: #444;
}
.tit_total {
	color: #555;
	font-size: 13px;
}
.tit_total .pointCol1 {
	font-size: 15px;
}
.tit_type1 {
	font-size: 15px;
	font-weight: bold;
	color: #222;
}

/* 필수입력 / 수정가능 */
.txt_necs,
.dashed_order_info .txt_necs {
	font-size: 0;
}
.txt_necs:before,
.list_dot .txt_necs:before {
	display: inline-block;
	content: "*";
	text-indent: 0;
	margin-left: 2px;
	font-size: 14px;
	color: #ffc000;
}
.txt_mdf {
	overflow: hidden;
	display: inline-block;
	font-size: 0;
	margin: 0 3px;
	vertical-align: middle;
}
.txt_mdf:before,
.list_dot .txt_mdf:before {
	display: inline-block;
	content: "";
	width: 4px;
	height: 4px;
	border-radius: 100%;
	background-color: #2dc6c8;
	text-indent: 0;
}
sup.txt_mdf {
	vertical-align: top;
}

/* txt */
.txt_desc {
	color: #555;
}

/* 테이블 form+form 아래간격 */
.group_top {
	margin-top: 6px;
}

/* 토스트팝업 */
.txt_confirm {
	display: none;
	position: fixed;
	bottom: 25px;
	left: 25px;
	right: 25px;
	padding: 10px;
	border-radius: 7px;
	background-color: rgba(85, 85, 85, 0.7);
	color: #fff;
	text-align: center;
	box-sizing: border-box;
	z-index: 80;
}
.txt_confirm2 {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	bottom: auto;
	top: 50%;
	left: 25px;
	right: 25px;
	transform: translateY(-50%);
	padding: 6px;
	min-height: 60px;
	border-radius: 12px;
	background-color: #000;
	color: #fff;
	text-align: center;
	box-sizing: border-box;
	z-index: 80;
}
.box_confirm .dim {
	display: none;
	position: fixed;
	height: 100%;
	width: 100%;
	background-color: rgba(85, 85, 85, 0.5);
}

/* list */
.list_dot li {
	position: relative;
	font-size: 12px;
	padding-left: 9px;
}
.list_dot li:before {
	display: block;
	position: absolute;
	top: 7px;
	left: 0;
	content: "";
	width: 3px;
	height: 3px;
	border-radius: 10px;
	background-color: #cecece;
}
.list_dot .txt_necs:before {
	background: none;
	top: 2px;
	left: -1px;
	font-size: 12px;
	margin-left: 0;
}
.list_dot .txt_mdf:before {
}
.list_dot.sty2 li:before {
	top: 3px;
	content: "*";
	background: none;
	color: #fdc100;
}
.list_dot .txt_mdf {
	margin: 0;
}

/* caution */
.box_caution {
	padding: 25px;
	background-color: #f7f7f7;
}
.tit_cat {
	margin-bottom: 2px;
	font-size: 12px;
	font-weight: bold;
}

/* tooltip */
.ssg-tooltip-wrap {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	height: 17px;
}
.ssg-tooltip-wrap .ssg-tooltip {
	vertical-align: top;
}
.ssg-tooltip-wrap .tipbox {
	display: none;
	position: absolute;
	top: 24px;
	left: 0;
	width: 200px;
	padding: 15px;
	z-index: 50;
	border: 1px solid #535b5e;
	background: #fff;
	font-size: 11px;
	line-height: 17px;
	text-align: left;
	font-weight: normal;
}
.ssg-tooltip-wrap .tipbox.right {
	left: auto;
	right: 0;
}
.ssg-tooltip-wrap.on .tipbox {
	display: block;
}

/* tab */
.tab_chk .fld_chk {
	margin-left: -2px;
}
.tab_chk .fld_chk:first-child {
	margin-left: 0;
}
.tab_chk .rd {
	position: absolute;
	left: -999em;
}
.tab_chk .rd:before {
	display: none;
}
.tab_chk label {
	overflow: hidden;
	display: table-cell;
	position: relative;
	padding: 0 15px;
	height: 30px;
	background-color: #fff;
	border: 1px solid #555;
	box-sizing: border-box;
	text-align: center;
	vertical-align: middle;
	font-size: 13px;
	cursor: pointer;
}
.tab_chk input[type="radio"]:checked + label {
	background-color: #555;
	color: #fff;
	font-weight: bold;
}
.tab_area li {
	display: inline-block;
	margin-left: -2px;
}
.tab_area li:first-child {
	margin-left: 0;
}
.tab_area li > a {
	display: table-cell;
	position: relative;
	padding: 0 15px;
	height: 30px;
	background-color: #fff;
	border: 1px solid #555;
	box-sizing: border-box;
	text-align: center;
	vertical-align: middle;
	font-size: 13px;
}
.tab_area li.on > a {
	background-color: #555;
	color: #fff;
	font-weight: bold;
}
.tab_txt {
	overflow: hidden;
}
.tab_txt > li {
	float: left;
}
.tab_txt > li > a {
	position: relative;
	display: block;
	padding: 7px 15px;
	font-size: 13px;
}
.tab_txt > li > a:before {
	position: absolute;
	left: 0;
	top: 50%;
	margin-top: -8px;
	height: 15px;
	content: "";
	border-left: 1px solid #e1e2e3;
}
.tab_txt > li:first-child > a,
.tab_txt.line > li:first-child > a {
	border-left: none;
	padding-left: 0;
}
.tab_txt > li:first-child > a:before {
	display: none;
}
.tab_txt > li.on > a {
	font-weight: bold;
}
.tab_txt.line {
	padding: 7px 15px;
	border-bottom: 1px solid #e5e5e5;
}
.tab_txt.line > li > a {
	font-size: 15px;
	color: #999;
	padding-left: 8px;
	padding-right: 8px;
}
.tab_txt.line > li.on > a {
	color: #555;
}
.tab_gray_box {
	background: #e5e5e5;
	background: -webkit-linear-gradient(top, #bdbdbd 0%, #e5e5e5 13%, #e5e5e5 88%, #d9d9d9 100%);
	background: linear-gradient(to top, #bdbdbd 0%, #e5e5e5 13%, #e5e5e5 88%, #d9d9d9 100%);
}
.tab_gray_box .tab_box {
	display: table;
	table-layout: fixed;
	width: 100%;
	padding: 15px 15px 0;
	box-sizing: border-box;
}
.tab_gray_box .tab_box > li {
	position: relative;
	display: table-cell;
	vertical-align: middle;
}
.tab_gray_box .tab_box > li > a {
	position: relative;
	display: block;
	z-index: 1;
	margin: 0 3px;
	padding: 12px 6px;
	text-align: center;
	box-sizing: border-box;
	color: #cecece;
}
.tab_gray_box .tab_box > li:after {
	position: absolute;
	left: 3px;
	right: 3px;
	top: 0;
	bottom: 0;
	display: block;
	border-radius: 5px 5px 0 0;
	content: "";
	background-color: #fff;
	box-shadow: 2px -2px 2px rgba(0, 0, 0, 0.1);
	background: #f7f7f7;
	background: -webkit-linear-gradient(top, #f7f7f7 0%, #f7f7f7 83%, #e1e1e1 100%);
	background: linear-gradient(to bottom, #f7f7f7 0%, #f7f7f7 83%, #e1e1e1 100%);
}
.tab_gray_box .tab_box > li:first-child:after {
	left: 0;
	right: 3px;
}
.tab_gray_box .tab_box > li:last-child:after {
	right: 0;
	left: 3px;
}
.tab_gray_box .tab_box > li.on:after {
	background: #fff;
	border-bottom: 1px dotted #e5e5e5;
}
.tab_gray_box .tab_box > li.on > a {
	color: #555;
	font-weight: bold;
}
@media screen and (device-aspect-ratio: 40/71) {
	/* 아이폰5 */
	.tab_txt.line > li > a {
		font-size: 14px;
		letter-spacing: -1px;
	}
}

/* listTopbox */
.box_gray_top {
	padding: 0 15px;
	background-color: #f7f7f7;
	border-bottom: 1px solid #e5e5e5;
	box-sizing: border-box;
}
.box_gray_top .cell {
	padding: 10px 0;
}

/* list */
.txt_ov {
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	-o-text-overflow: ellipsis;
	-ms-text-overflow: ellipsis;
	white-space: nowrap;
}
.list_cm > li {
	position: relative;
	width: 100%;
	border-bottom: 1px solid #e5e5e5;
	box-sizing: border-box;
}
.list_cm > li > a {
	position: relative;
	width: 100%;
	padding: 15px 10px;
	margin-right: 0;
	color: #555;
	box-sizing: border-box;
}
.list_cm > li > a:before {
	display: none;
}
.list_cm > li > a:after {
	display: block;
	position: absolute;
	top: 0;
	right: 10px;
	bottom: 0;
	margin: auto 0;
	content: "";
}
.list_cm .new {
	font-size: 0;
}
.list_cm .new:before {
	position: absolute;
	top: 15px;
	left: 0;
	display: block;
	content: "";
	width: 4px;
	height: 4px;
	border-radius: 10px;
	background-color: #fdc100;
}
.list_cm .ico_cm .txt_base {
	display: table;
	width: 100%;
	box-sizing: border-box;
}
.list_cm .txt_base {
	padding-right: 22px;
}
.list_cm .txt_base .date {
	width: 50px;
	font-size: 12px;
	color: #999;
	padding-left: 10px;
}
.list_cm .txt_base .txt_ov {
	font-weight: normal;
}
.list_cm .txt_base .txt_s {
	color: #949494;
}
.list_cm .txt_info {
	margin-top: 3px;
	font-size: 14px;
	letter-spacing: -0.8px;
	> span:not(:first-child) {
		margin-left: 5px;
	}
	&_num {
		color: #2dc6c8;
		font-weight: bold;
	}
	&_status {
		color: #444;
	}
}
.list_cm .loading {
	height: 52px;
}
.list_cm .list_all_select {
	font-size: 14px;
	padding: 7px 15px;
	.inp {
		height: 29px;
	}
}
.list_cm.type2 > li {
	padding: 15px 10px;
}
.list_cm.type3 > li {
	> a {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		flex: 0 1 auto;
		min-height: 45px;
		padding: 11px 32px 11px 15px;
		background-color: #f7f7f7;
		font-size: 16px;
		color: #222;
		letter-spacing: -0.7px;
		line-height: 1.2;
		box-sizing: border-box;
	}
	.ico_cm.arr_bt {
		&:after {
			right: 13px;
			width: 8px;
			height: 8px;
			margin-top: -7px;
			background: none;
			border: solid #888;
			border-width: 0 1px 1px 0;
			transform: rotate(45deg);
		}
		&.open {
			font-weight: bold;
			.txt_info_status {
				font-weight: normal;
			}
		}
		&.open:after {
			margin-top: -3px;
			transform: rotate(225deg);
		}
	}
}
/* list_icon */
.ico_cm.arr:after {
	width: 9px;
	height: 17px;
	background-position: 0 0;
}
.ico_cm.logout:after {
	width: 20px;
	height: 22px;
	background-position: -10px 0;
}
.ico_cm.arr_bt:after {
	position: absolute;
	top: 50%;
	right: 10px;
	width: 16px;
	height: 9px;
	margin-top: -4px;
	background-position: -211px 0;
}
.ico_cm.arr_bt.open:after {
	background-position: -245px 0;
}
.body_po .ico_cm.arr_bt.open:after {
	background-position: -228px 0;
}

/* infinite_scroll */
.infinite_scroll .loading {
	display: block;
	text-align: center;
}
.infinite_scroll .loading:before {
	display: inline-block;
	height: 100%;
	line-height: 100%;
	content: "";
	vertical-align: middle;
}
.infinite_scroll .loading .ico_comm {
	display: inline-block;
	font-size: 0;
	line-height: 0;
	vertical-align: middle;
}
.infinite_scroll .loading .ico_comm:before {
	display: inline-block;
	content: "";
	width: 20px;
	height: 20px;
	background: url(../img/bg_loading.gif) 0 0 no-repeat;
	background-size: 20px auto;
}

/* loadng bar */
.loading_bar {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 999999;
}
.loading_bar > div {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 36px;
	height: 36px;
	margin: -18px 0 0 -18px;
	background: rgba(0, 0, 0, 0.5);
	border-radius: 18px;
}

/* switch_toggle */
.switch_area {
	display: inline-block;
	vertical-align: middle;
}
.cm_toggle {
	position: absolute;
	left: -9999px;
	visibility: hidden;
}
.cm_toggle + label {
	display: block;
	position: relative;
	cursor: pointer;
	outline: none;
	-webkit-tap-highlight-color: transparent;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	width: 60px;
	height: 30px;
	background-color: #e5e5e5;
	border-radius: 60px;
	vertical-align: middle;
	box-sizing: border-box;
}
.cm_toggle + label:before {
	display: block;
	position: absolute;
	top: 10px;
	left: 32px;
	content: "OFF";
	border-radius: 60px;
	font-size: 13px;
	font-weight: bold;
	color: #fff;
	line-height: 1;
}
.cm_toggle + label:after {
	display: block;
	position: absolute;
	top: 2px;
	left: 2px;
	bottom: 2px;
	right: 2px;
	width: 28px;
	background-color: #fff;
	border-radius: 100%;
	box-sizing: border-box;
	content: "";
	-webkit-transition: transform 0.3s;
	transition: transform 0.3s;
}
.cm_toggle:checked + label:after {
	-webkit-transform: translateX(100%);
	transform: translateX(100%);
}
.cm_toggle:checked + label:before {
	content: "ON";
	left: 7px;
}

/* error_page */
.box_error {
	position: absolute;
	width: 100%;
	display: box;
	display: -webkit-box;
	-webkit-box-align: center;
	-webkit-box-pack: center;
	height: 100%;
	text-align: center;
	color: #555;
}
.box_error .txt_desc {
	margin-bottom: 12px;
	font-size: 13px;
	color: #555;
}
.txt_error {
	font-size: 22px;
	margin-bottom: 30px;
	line-height: 27px;
}
.txt_error .txt_s {
	color: #999;
}
.box_error .ico_cm {
	position: relative;
	padding: 0 15px;
	margin-bottom: 35px;
}
.box_error .ico_cm:before {
	display: inline-block;
	content: "";
	width: 90px;
	height: 90px;
	border-radius: 100px;
	border: 1px solid #555;
	background: none;
}
.box_error .ico_cm:after {
	position: absolute;
	left: 50%;
	content: "";
	text-align: center;
}
.box_error .page_error:after {
	top: 32px;
	width: 44px;
	height: 29px;
	margin-left: -22px;
	background-position: -31px 0;
}
.box_error .system_check:after {
	top: 26px;
	width: 42px;
	height: 40px;
	margin-left: -21px;
	background-position: -77px 0;
}
.box_error .system_error:after {
	top: 32px;
	width: 44px;
	height: 40px;
	margin-left: -22px;
	background-position: -119px 0;
}
.box_error .service_ing:after {
	top: 32px;
	width: 44px;
	height: 40px;
	margin-left: -22px;
	background-position: -165px 0;
}

/* paging */
.paging {
	text-align: center;
	margin-top: 15px;
}
.paging > strong,
.paging > span {
	display: inline-block;
	padding: 0 10px;
	vertical-align: middle;
	font-size: 16px;
}
.paging > button {
	width: 30px;
	text-align: center;
}

/* layout */
.wrap {
	position: relative;
	overflow-x: hidden;
	min-height: 100%;
	box-sizing: border-box;
	padding-bottom: 70px;
}
.section,
.section2 {
	position: relative;
	background-color: #fff;
}
.section:before {
	display: block;
	height: 7px;
	content: "";
	background-color: #e5e5e5;
	box-shadow: inset 1px 2px 2px rgba(0, 0, 0, 0.1);
}
.wbox1,
.wbox2,
.wbox3,
.wbox4,
.wbox5 {
	box-sizing: border-box;
}
.wbox1 {
	padding: 0 15px 15px;
} /* 아래버튼있는경우 */
.wbox2 {
	padding: 0 15px;
} /* 버튼없는경우 */
.wbox3 {
	padding: 15px;
} /* 예외 */
.wbox4 {
	padding-bottom: 15px;
} /* 아래 */
.wbox5 {
	padding: 0 15px 20px;
}
.hasFixbottom {
	padding-bottom: 0;
} /* 아래 */
.hr {
	border-bottom: 1px solid #e5e5e5;
}
.bg_color1 {
	background-color: #e5e5e5;
}
.header {
	position: relative;
}
.header .tit_dep1 {
	position: absolute;
	width: 100%;
	padding: 14px 88px 0;
	color: #fff;
	font-size: 20px;
	text-align: center;
	box-sizing: border-box;
	line-height: 1.2;
}
.header .splitCell {
	position: relative;
	z-index: 1;
}
.header .splitCell .cell {
	padding: 11px 15px;
}
.header .splitCell .btn_cm {
	padding: 5px;
}
.header .splitCell .btn_cm:first-child {
	margin-left: 0;
}
.header .splitCell .sggtalk {
	margin-top: 2px;
}
.footer {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 12px 50px 12px 25px;
	background: #e5e5e5;
}
.footer dl {
	display: table;
	width: 100%;
	table-layout: fixed;
	color: #999;
	font-size: 12px;
	line-height: 15px;
}
.footer dt {
	display: table-cell;
	width: 65px;
	letter-spacing: -1px;
}
.footer dd {
	display: table-cell;
}
.footer .ft_logo {
	position: absolute;
	top: 50%;
	right: 25px;
	overflow: hidden;
	width: 45px;
	height: 8px;
	margin-top: -4px;
	background: url(../img/comm_logo.png) 0 0 no-repeat;
	background-size: 100%;
	line-height: 999px;
	vertical-align: top;
}

/* select_popup */
.scroll_lock {
	overflow: hidden;
	height: 100%;
	pointer-events: none;
}
.scroll_lock .step_select {
	pointer-events: auto;
}
.init {
	z-index: -1 !important;
}
.selectric-select {
	position: relative;
	cursor: pointer;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.selectric-select .selectric,
.btn_select {
	position: relative;
	border: 1px solid #e5e5e5;
	background: #fff;
	box-sizing: border-box;
	text-align: left;
}
.selectric-select:first-child .selectric {
	margin-top: 0;
}
.selectric-select .selectric:after,
.btn_select:after {
	position: absolute;
	top: 50%;
	right: 10px;
	width: 16px;
	height: 9px;
	margin-top: -4px;
	background: url(../img/comm_ico.png) no-repeat -211px 0px;
	background-size: 320px auto;
	content: "";
}
.selectric-select.selectric-sty2 .selectric,
.btn_select.sty2 {
	border-color: #999;
}
/* .selectric-select.selectric-sty3{display:inline-block;width:auto;} */
.selectric-select.selectric-sty3 .selectric {
	border: none;
}
.selectric-select.selectric-sty3 .selectric:after {
	right: 5px;
	width: 6px;
	height: 3px;
	margin-top: -2px;
	background-position: -211px -20px;
}
.selectric-select.selectric-sty3 .selectric .label {
	font-size: 12px;
	margin: 0 15px 0 5px;
	padding: 6px 0;
	text-align: center;
}
.selectric-select .selectric .label {
	display: block;
	overflow: hidden;
	margin: 0 30px 0 10px;
	padding: 10px 0;
	color: #999;
	white-space: nowrap;
	text-overflow: ellipsis;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.selectric-select .selectric .button {
	display: none;
}
.selectric-select .selectric-items {
	position: fixed;
	top: 0;
	left: 100%;
	z-index: 11;
	width: 100%;
	padding: 0;
	outline: none;
	-webkit-transition: left 0.4s ease;
	transition: left 0.4s ease;
	pointer-events: auto;
	-webkit-backface-visibility: hidden;
}
.selectric-select .selectric-items:after {
	visibility: hidden;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: -1;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.8);
	content: "";
	opacity: 0;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
	-webkit-backface-visibility: hidden;
}
.selectric-select.selectric-open .selectric-items {
	top: 0;
	left: 0;
	z-index: 100;
	margin-right: 0 !important;
	opacity: 1;
}

.sch_panel .selectric-select.selectric-open .selectric-items {
	right: 0;
}
.sch_panel .selectric-select .selectric-items {
	right: 200%;
}

.selectric-select.selectric-open .selectric-items:after {
	visibility: visible;
	z-index: 10;
	opacity: 1;
}
.selectric-select .selectric-items .selectric-scroll {
	overflow: auto;
	position: relative;
	max-height: 420px;
	background: #fff; /*-webkit-overflow-scrolling:touch*/
}
.selectric-select .selectric-items-inner {
	position: relative;
	z-index: 11;
}
.selectric-select .selectric-items-inner:after {
	display: none;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: #000;
	content: "";
	opacity: 0.5;
}
.selectric-select .selectric-label {
	position: relative;
	height: 50px;
	padding: 0 30px;
	line-height: 50px;
	box-sizing: border-box;
	background-color: #2cc6c8;
	font-size: 20px;
	color: #fff;
	text-align: center;
}
.selectric-select .selectric-label button {
	position: absolute;
	top: 50%;
	right: 10px;
	width: 20px;
	height: 20px;
	margin-top: -10px;
	background: url(../img/comm_btn.png) no-repeat;
	background-position: -236px 0;
	background-size: 320px auto;
	content: "";
}
.selectric-select .selectric-items ul {
	margin: 0;
	padding: 0;
}
.selectric-select .selectric-items li {
	display: block;
	position: relative;
	float: none;
	margin: 0;
	padding: 15px 10px;
	border-bottom: 1px dotted #e5e5e5;
	text-align: center;
	background-color: #fff;
	color: #999;
	cursor: pointer;
}
.selectric-select .selectric-items li.default_off {
	display: none;
}
.selectric-select .selectric-items li:last-child {
	border-bottom: 0;
}
.selectric-select .selectric-items li.selected {
	color: #2dc6c8;
}
.selectric-select .selectric-items .disabled {
	background: none !important;
	color: #555 !important;
	cursor: default !important;
	opacity: 0.5;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	filter: alpha(opacity=50);
}
.step_select {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.step_select .s-select {
	display: none;
}
.step_select .selectric-s-select {
	position: relative;
	cursor: pointer;
}
.selectric-responsive {
	width: 100%;
}
.step_select .selectric-s-select .selectric {
	display: none;
	position: relative;
	margin-top: 7px;
	border: 1px solid #e5e5e5;
	background: #fff;
}
.step_select .selectric-s-select:first-child .selectric {
	margin-top: 0;
}
.step_select .selectric-s-select .selectric:after {
	position: absolute;
	top: 50%;
	right: 10px;
	width: 9px;
	height: 17px;
	margin-top: -9px;
	background: url(../img/comm_ico.png) no-repeat;
	background-size: 320px auto;
	content: "";
}
.step_select .selectric-s-select .selectric.on {
	display: block;
}
.step_select .selectric-s-select .selectric .label {
	display: block;
	overflow: hidden;
	margin: 0 30px 0 10px;
	padding: 11px 0;
	color: #999;
	white-space: nowrap;
	text-overflow: ellipsis;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.step_select .selectric-s-select .selectric .button {
	display: none;
}
.step_select .selectric-s-select.selectric-open .selectric-items {
	top: 0;
	left: 0;
	z-index: 100;
	margin-right: 0 !important;
	opacity: 1;
}
.selectric-disabled {
	cursor: default;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.selectric-disabled.selectric-select .selectric {
	border-color: #e5e5e5;
	background-color: #f7f7f7;
}
.selectric-disabled.selectric-select .selectric .label {
	color: #555;
}
.selectric-disabled.selectric-select .selectric:after {
	background-position: -211px -10px;
}
.selectric-hide-select {
	overflow: hidden;
	position: relative;
	width: 0;
	height: 0;
}
.selectric-hide-select select {
	display: none;
	position: absolute;
	left: -100%;
}
.selectric-input {
	overflow: hidden !important;
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;
	width: 1px !important;
	height: 1px !important;
	margin: 0 !important;
	padding: 0 !important;
	border: none !important;
	background: none !important;
	outline: none !important;
	clip: rect(0, 0, 0, 0) !important;
	*font: 0/0 a !important;
}
.selectric-temp-show {
	display: block !important;
	visibility: hidden !important;
	position: absolute !important;
}
/* Items box */
.step_select .selectric-s-select .selectric-items {
	position: fixed;
	top: 0;
	left: 100%;
	z-index: 11;
	width: 100%;
	padding: 0;
	outline: none;
	-webkit-transition: left 0.4s ease;
	transition: left 0.4s ease;
	-webkit-backface-visibility: hidden;
}
.step_select .selectric-s-select .selectric-items .selectric-scroll {
	overflow: auto;
	position: relative;
	max-height: 390px;
	background: #fff; /*-webkit-overflow-scrolling:touch*/
}
.step_select .selectric-s-select .selectric-items-inner {
	position: relative;
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
}
.step_select .selectric-s-select .selectric-items-inner:after {
	display: none;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: #000;
	content: "";
	opacity: 0.5;
}
.step_select .selectric-s-select .selectric-label {
	position: relative;
	height: 50px;
	padding: 10px 30px 0;
	box-sizing: border-box;
	background-color: #2cc6c8;
	font-size: 20px;
	color: #fff;
	text-align: center;
}
.step_select .selectric-s-select .selectric-label button {
	position: absolute;
	top: 50%;
	right: 10px;
	width: 20px;
	height: 20px;
	margin-top: -10px;
	background: url(../img/comm_btn.png) no-repeat;
	background-position: -236px 0;
	background-size: 320px auto;
	content: "";
}
.step_select .selectric-s-select .selectric-items li {
	display: block;
	position: relative;
	margin: 0 15px;
	padding: 15px 30px 15px 10px;
	border-bottom: 1px solid #e5e5e5;
	background-color: #fff;
	color: #999;
	cursor: pointer;
}
.step_select .selectric-s-select .selectric-items li.default_off {
	display: none;
}
.step_select .selectric-s-select .selectric-items li:after {
	position: absolute;
	top: 50%;
	right: 10px;
	width: 9px;
	height: 17px;
	margin-top: -9px;
	background: url(../img/comm_ico.png) no-repeat;
	background-size: 320px auto;
	content: "";
}
.step_select .selectric-s-select .selectric-items li:last-child {
	border-bottom: 0;
}
.step_select .selectric-s-select .selectric-items li.selected {
	color: #2dc6c8;
}
.step_select .selectric-s-select .selectric-items .disabled {
	background: none !important;
	color: #555 !important;
	cursor: default !important;
	opacity: 0.5;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	filter: alpha(opacity=50);
}
.step_select .selectric-s-select:nth-of-type(2) .selectric-items {
	top: 15px;
}
.step_select .selectric-s-select:nth-of-type(3) .selectric-items {
	top: 30px;
}
.step_select .selectric-s-select:nth-of-type(1) .selectric-items-inner {
	margin-right: 10px;
}
.step_select .selectric-s-select:nth-of-type(2) .selectric-items-inner {
	margin: 0 10px;
}
.step_select .selectric-s-select:nth-of-type(3) .selectric-items-inner {
	margin: 0 10px;
}
.step_select.step2 .selectric-s-select:nth-of-type(1) .selectric-items {
	left: -80%;
}
.step_select.step3 .selectric-s-select:nth-of-type(1) .selectric-items {
	left: -83%;
}
.step_select.step3 .selectric-s-select:nth-of-type(2) .selectric-items {
	left: -78%;
}
.step_select.step2 .selectric-s-select:nth-of-type(1) .selectric-items-inner:after {
	display: block;
}
.step_select.step3 .selectric-s-select:nth-of-type(1) .selectric-items-inner:after {
	display: block;
}
.step_select.step3 .selectric-s-select:nth-of-type(2) .selectric-items-inner:after {
	display: block;
}
.step_select .select_dim {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: -1;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.8);
	opacity: 0;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
	-webkit-backface-visibility: hidden;
}
.step_select.step1 .select_dim {
	z-index: 10;
	opacity: 1;
}
.step_select.step2 .select_dim {
	z-index: 10;
	opacity: 1;
}
.step_select.step3 .select_dim {
	z-index: 10;
	opacity: 1;
}

select.select_multi {
	visibility: hidden;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.sort_area {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex: 1;
}
.sort_select {
	width: 50%;
}
.ms-parent {
	display: inline-block;
	position: relative;
	vertical-align: middle;
}
.ms-choice {
	display: block;
	overflow: hidden;
	width: 100%;
	border: 1px solid #e5e5e5;
	background-color: #fff;
	outline: none;
	text-align: left;
	text-decoration: none;
	white-space: nowrap;
	cursor: pointer;
}
.ms-choice.disabled {
	border: 1px solid #ddd;
	background-color: #f4f4f4;
	background-image: none;
	cursor: default;
}
.ms-choice > span {
	display: block;
	overflow: hidden;
	margin: 0 30px 0 10px;
	padding: 10px 0;
	color: #999;
	white-space: nowrap;
	text-overflow: ellipsis;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.ms-choice > span.placeholder {
	color: #999;
}
.ms-choice > div {
	position: absolute;
	top: 50%;
	right: 10px;
	width: 16px;
	height: 9px;
	margin-top: -4px;
	background: url(../img/comm_ico.png) no-repeat -211px 0;
	background-size: 320px auto;
	content: "";
}
.ms-drop {
	position: fixed;
	top: 0;
	left: 100%;
	z-index: 11;
	width: 100%;
	padding: 0;
	outline: none;
	-webkit-transition: left 0.4s ease;
	transition: left 0.4s ease;
	pointer-events: auto;
	-webkit-backface-visibility: hidden;
}
.ms-drop:after {
	visibility: hidden;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: -1;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.8);
	content: "";
	opacity: 0;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
	-webkit-backface-visibility: hidden;
}
.ms-parent._open .ms-drop {
	top: 0;
	left: 0;
	z-index: 100;
	margin-right: 0 !important;
	opacity: 1;
}
.ms-parent._open .ms-drop:after {
	visibility: visible;
	z-index: 10;
	opacity: 1;
}
.ms-drop .ms-drop-inner {
	position: relative;
	z-index: 11;
}
.ms-drop .ms-drop-inner:after {
	display: none;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: #000;
	content: "";
	opacity: 0.5;
}
.ms-drop .ms-drop-scroll {
	overflow: auto;
	position: relative;
	max-height: 420px;
	background: #fff; /*-webkit-overflow-scrolling:touch*/
}
.ms-drop .ms-drop-label {
	position: relative;
	height: 50px;
	padding: 0 30px;
	box-sizing: border-box;
	background-color: #2cc6c8;
	font-size: 20px;
	line-height: 50px;
	color: #fff;
	text-align: center;
}
.ms-drop .ms-drop-label button {
	position: absolute;
	top: 50%;
	right: 10px;
	width: 20px;
	height: 20px;
	margin-top: -10px;
	background: url(../img/comm_btn.png) no-repeat;
	background-position: -236px 0;
	background-size: 320px auto;
	content: "";
}
.ms-drop ul li {
	display: block;
	width: auto !important;
	border-top: 1px dotted #e5e5e5;
	background-color: #fff;
	color: #999;
	text-align: center;
	cursor: pointer;
}
.ms-drop ul li.default_off {
	display: none;
}
.ms-drop ul li:first-child {
	border-top: 0;
}
.ms-drop ul li label {
	display: block;
	position: relative;
	padding: 15px 10px;
	color: #555;
}
.ms-drop ul li input[type="checkbox"] {
	display: inline-block;
	position: absolute;
	top: 50%;
	left: 15px;
	width: 18px;
	height: 18px;
	margin-top: -9px;
	border: 0;
	border-radius: 0;
	background: url(../img/comm_btn.png) no-repeat;
	background-position: -37px 0;
	background-size: 320px auto;
	vertical-align: middle;
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
}
.ms-drop ul li.selected input[type="checkbox"]:checked {
	background-position: -55px 0;
}
.select_list .ms-choice {
	white-space: normal;
}
.select_list .ms-choice > span {
	white-space: normal;
	text-overflow: initial;
}

/* popup */
.pop_scroll_lock {
	overflow: hidden;
	position: fixed;
}
.pop_layer {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 50;
}
.pop_layer .dimbg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #000;
	opacity: 0.5;
}
.pop_layer .tit_dep1,
.modal .tit_dep1 {
	padding: 11px 0;
	font-size: 20px;
	color: #fff;
	text-align: center;
}
.pop_container {
	background-color: #fff;
}
.pop_wrap {
	position: absolute;
	top: 0;
	left: 0;
	right: 0; /* margin-top:0 !important; */
	background-color: #fff;
	z-index: 10;
}
.pop_content {
	width: 100%;
	height: 100%; /*ico-bottom:20px; max-height:480px;overflow:scroll;-webkit-overflow-scrolling:touch; */
}
.pop_layer .btn_cm.close,
.modal .btn_cm.close {
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	width: 60px;
	height: 52px;
	padding-top: 16px;
	z-index: 1;
	text-align: center;
}
.pop_layer.alert {
	text-align: center;
}
.pop_layer.alert .pop_wrap {
	top: 50%;
	left: 30px;
	right: 30px;
	height: auto;
}
.pop_layer.alert .pop_content {
	overflow: hidden;
	height: auto;
	padding: 30px 10px;
	box-sizing: border-box;
}
.pop_layer.alert .btn_area {
	margin-top: 0;
}
.pop_layer.alert .txt_desc {
	color: #555;
}
.pop_layer.alert .txt_desc2 {
	font-size: 14px;
	color: #555;
}
.pop_layer.alert .tit_alert + .txt_desc2 {
	margin-top: -6px;
}
.pop_layer.alert .tit_alert {
	display: block;
	margin-bottom: 6px;
	color: #555;
	font-size: 16px;
}
.pop_layer.alert .pop_wrap {
	left: 40px;
	right: 40px;
}
.pop_layer.alert .btn_area > button,
.pop_layer.alert .btn_area > a {
	border-left: none;
}
.pop_layer.p_select .pop_content {
	height: auto;
	padding: 0;
}
.modal .txt_top_desc {
	padding: 12px 10px;
	color: #555;
	background-color: #f7f7f7;
	border-bottom: 1px dotted #e5e5e5;
	line-height: 18px;
	text-align: center;
}
.modal .list_check .rd {
	position: absolute;
	left: -9999em;
}
.modal .list_check .chk {
	position: absolute;
	right: 15px;
	top: 50%;
	margin-top: -9px;
}
.modal .list_check > li {
	position: relative;
}
.modal .list_check > li > a,
.modal .list_check .lab_txt {
	display: block;
	padding: 12px;
	border-top: 1px dotted #e5e5e5;
	line-height: 18px;
	text-align: center;
	color: #999;
}
.modal .list_check .multi_chk .lab_txt {
	padding-right: 36px;
	padding-left: 36px;
}
.modal .list_check > li:first-child > a,
.modal .list_check li:first-child .lab_txt {
	border-top: none !important;
}
.modal .list_check > label {
	display: block;
}
.modal .list_check > li.layer_calc .lab_txt {
	text-align: left;
}
.modal .list_check > li.layer_calc .plus_minus {
	margin-top: 20px;
	position: relative;
}
.modal .list_check > li.layer_calc .plus_minus input[type="number"] {
	width: 100px;
}
.modal .list_check > li.layer_calc .plus_minus .increase {
	position: absolute;
	left: 0;
	top: 0;
}
.modal .list_check > li.layer_calc .plus_minus .decrease {
	position: absolute;
	right: 0;
	top: 0;
}
.modal .select_lst_big {
	border-top: 1px solid #b6b6b6;
}
.modal .select_lst_big li {
	height: 70px;
}
.modal .select_lst_big li:not(:first-child) {
	border-top: 1px dashed #b6b6b6;
}
.modal .select_lst_big li button {
	display: block;
	overflow: hidden;
	width: 100%;
	height: 70px;
	font-size: 26px;
	line-height: 70px;
}
.modal .modal_msg_area {
	font-size: 15px;
	text-align: center;
	line-height: 20px;
}
.modal .modal_msg_area p {
	margin: 26px 0;
}
.modal .modal_msg_area p:first-child {
	margin-top: 40px;
}
.modal .modal_msg_area p:last-child {
	margin-bottom: 40px;
}
.modal .modal_msg_area .msg_bold {
	font-weight: bold;
}
.modal .modal_msg_area .msg_color_red {
	color: #e86c6c;
}
.modal .modal_imgbx {
	text-align: center;
}
.modal .modal_imgbx img {
	width: 100%;
	max-width: 750px;
	vertical-align: top;
}

.modal-delivery .fld_area .fld_chk {
	padding: 19px 15px;
	border-bottom: 1px dotted #e5e5e5;
	color: #555;
	font-size: 15px;
	box-sizing: border-box;
}
.modal-delivery .delivery_box {
	margin: 15px;
}

/* @media only screen and (min-device-width :320px) and (max-device-height :480px) {
  .pop_content{max-height:320px}
} */
/* modal_popup */
/*.disable-mouse {pointer-events:none;}
.disable-scroll {overflow:hidden;height:100%;position:fixed}*/
.modal {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 100%;
	right: 0; /* -webkit-overflow-scrolling:touch; */
	padding: 0;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 20;
	visibility: hidden;
	opacity: 0;
	pointer-events: none;
	-webkit-transition: 200ms ease;
	transition: 200ms ease;
	overflow: auto;
}
.modal .inner {
	background-color: #fff;
}
.modal.show {
	opacity: 1;
	visibility: visible;
	pointer-events: auto;
	left: 0;
}

/* modal 서제스트 */
.srh_suggest {
	padding: 1px 8px 1px 15px;
	box-sizing: border-box;
}
.srh_suggest .w5 {
	font-weight: bold;
	padding-right: 10px;
	white-space: nowrap;
}
.srh_suggest .frm_search .inp_wrap {
	display: block;
}
.srh_suggest .frm_search .inp {
	border: none;
}

/* modal 우편번호찾기 */
.modal-epost .txt_desc {
	color: #999;
}
.modal-epost .tab_box_area {
	display: none;
}
.modal-epost .tab_box_area.on {
	display: block;
}
.box_detail_adrr {
	padding: 15px;
	background-color: #f7f7f7;
	border-top: 1px dotted #e5e5e5;
	border-bottom: 1px dotted #e5e5e5;
}
.box_detail_adrr .info_txt {
}
.box_detail_adrr .dot_line {
	padding-bottom: 10px;
	margin-bottom: 10px;
	border-bottom: 1px dotted #e5e5e5;
}
.box_detail_adrr .info_txt dt {
	font-weight: bold;
}
.box_detail_adrr .addr {
	font-size: 12px;
}
.box_detail_adrr .inp_wrap {
	display: block;
}
.box_detail_adrr .txt_sm {
	margin-top: 7px;
	font-size: 12px;
	color: #999;
	line-height: 15px;
}

/* modal 오퍼정보 */
.modal-offer-info .list_dot li {
	margin-top: 0;
	margin-bottom: 6px;
	font-size: 14px;
	color: #555;
}

/* modal 상품제안관리*/
.box_gray_tit {
	padding: 12px 15px;
	background-color: #f7f7f7;
	border-bottom: 1px dotted #e5e5e5;
	font-size: 14px;
}
.list_suggest > li {
	line-height: 18px;
}
.list_suggest .box_gray {
	margin-top: 8px;
	padding: 12px 15px;
	background-color: #f7f7f7;
	border: 1px solid #e6e6e6;
	line-height: 22px;
}
.modal-prod-detail .img_view img {
	max-width: 100%;
	vertical-align: top;
}

/*** 컨텐츠 영역 ***/
.tit_dep2 {
	color: #555;
	font-size: 14px;
	padding: 23px 15px 10px;
	font-weight: bold;
}
.tit_wrap {
	margin-bottom: 10px;
}
.tit_wrap .cell {
	vertical-align: bottom;
}
.tit_wrap .tit_dep2 {
	padding: 0;
	height: 28px;
}

/* MD그룹검색 */
.md_group li {
	border: 1px solid #e5e5e5;
	margin-bottom: 7px;
}
.md_group li:last-child {
	margin-bottom: 0;
}
.md_group li > a {
	padding-top: 11px;
	padding-bottom: 11px;
}

/* 비밀번호 변경 */
.pw_modify .txt_desc {
	padding: 30px 0;
}

/* 메모 상세보기 */
.meno_tf .tf {
	margin-bottom: 5px;
}
.meno_tf .btn_area {
	margin-top: 0;
}

/* 공지사항 view */
.list_view .tit_area {
	position: relative;
	padding: 15px 20px;
	background-color: #f7f7f7;
	border-bottom: 1px solid #e5e5e5;
}
.list_view .txt_tit {
	padding-right: 65px;
	margin-bottom: 2px;
	color: #555;
	font-weight: normal;
}
.list_view .txt_date {
	color: #999;
}
.list_view .btn_file {
	position: absolute;
	top: 0;
	right: 19px;
	height: 100%;
}
.list_view .btn_file:before {
	display: inline-block;
	height: 100%;
	content: "";
	vertical-align: middle;
}
.list_view .edit_wrap {
	padding: 25px 20px;
}

/* faq */
.list_faq .cont_area {
	display: none;
	padding: 20px 15px;
	background-color: #f7f7f7;
	border-top: 1px solid #e5e5e5;
	font-size: 13px;
}
.list_faq .firstShow .cont_area {
	display: block !important;
}

/* CBT 설문 */
.survey_info {
	position: relative;
	padding: 13px 15px;
	border-bottom: 7px solid #e5e5e5;
	color: #555;
}
.survey_info .date {
	display: block;
	margin-bottom: 2px;
	padding-left: 5px;
	font-size: 13px;
	line-height: 1.5;
}
.survey_info .tit {
	display: block;
	margin-right: 100px;
	padding-left: 5px;
	font-size: 15px;
	line-height: 1.4;
	letter-spacing: -0.5px;
}
.survey_info .btn_report {
	position: absolute;
	right: 15px;
	bottom: 15px;
	width: 59px;
	padding: 4px 0 3px;
	border: 1px solid #d0021b;
	font-size: 12px;
	line-height: 1.1;
	color: #d0021b;
	letter-spacing: -1px;
	text-align: center;
}
.survey_detail {
	font-size: 15px;
	color: #555;
	letter-spacing: -1px;
}
.survey_detail .survey_detail_bx {
	padding-top: 5px;
}
.survey_detail .survey_detail_bx:first-of-type {
	padding-top: 0;
}
.survey_detail .survey_img img {
	width: 100%;
	vertical-align: top;
}
.survey_detail .survey_q {
	padding: 14px 15px;
	border-bottom: 1px solid #e5e5e5;
	background: #f7f7f7;
	font-weight: bold;
	line-height: 1.1;
}
.survey_detail .answer_list li {
	border-top: 1px solid #e8e8e8;
	padding: 8px 15px;
}
.survey_detail .answer_list li:first-child {
	border-top: 0;
}
.survey_detail .answer_list li .fld_chk {
	position: relative;
	line-height: 1.2;
}
.survey_detail .answer_list li .fld_chk .rd {
	position: absolute;
	top: 10px;
}
.survey_detail .answer_list li .fld_chk .chk {
	position: absolute;
	top: 8px;
}
.survey_detail .answer_list li .fld_chk .rd + label,
.survey_detail .answer_list li .fld_chk .chk + label {
	padding: 9px 0 6px 25px;
}
.survey_detail .answer_list li .splitCell > .cell:first-child {
	width: 63px;
}
.survey_detail .answer_list li .inp_wrap {
	display: block;
}
.survey_detail .answer_txarea {
	padding: 13px 15px 0;
}
.survey_complete {
	margin-bottom: 78px;
	padding-top: 73.334%;
	background: url(../img/bg_survey_complete.jpg) 50% 0 no-repeat;
	background-size: 100% auto;
	text-align: center;
}
.survey_complete .tit {
	display: block;
	margin: 19px 0 14px;
	font-weight: normal;
	font-size: 40px;
	font-family: "AppleSDGothicNeo-Light", sans-serif;
	line-height: 1.2;
	color: #222;
}
.survey_complete .subtit {
	display: block;
	margin: 7px 0;
	font-size: 16px;
	line-height: 1.2;
	color: #2fd0d8;
}
.survey_complete .txt {
	display: block;
	margin: 7px 0;
	font-size: 14px;
	line-height: 1.3;
	color: #666;
}
.survey_complete .txt em {
	color: #ff5b59;
}
.report_info {
	padding: 20px;
	border-bottom: 7px solid #e5e5e5;
	color: #555;
}
.report_info .tit {
	display: block;
	font-weight: bold;
	font-size: 17px;
	line-height: 1.15;
}
.report_info .txt {
	display: block;
	margin-top: 4px;
	font-size: 15px;
	line-height: 1.35;
	letter-spacing: -0.5px;
}
.report_form .report_form_sec {
	position: relative;
	border-top: 1px solid #e5e5e5;
	font-size: 16px;
}
.report_form .report_form_sec:first-child {
	border-top: 0;
}
.report_form .fld_area {
	padding: 15px;
}
.report_form .fld_area .fld_chk {
	margin-right: 25px;
	color: #222;
}
.report_form .rd,
.report_form .chk {
	width: 28px;
	height: 28px;
	background-position: -210px -45px;
}
.report_form .rd[type="radio"]:checked,
.report_form .chk[type="checkbox"]:checked {
	background-position: -240px -45px;
	background-color: transparent;
}
.report_form .inp_wrap {
	display: block;
}
.report_form .inp_wrap .inp {
	border: 0;
	padding-left: 15px;
	font-size: 15px;
	color: #222;
}
.report_form .inp_wrap .clear {
	right: 11px;
}
.report_form .tf {
	margin-bottom: 47px;
	padding: 20px 15px;
	border: 0;
	font-size: 15px;
	color: #222;
}
.report_form .data_count {
	position: absolute;
	bottom: 10px;
	right: 15px;
	font-size: 14px;
	line-height: 1.6;
	color: #a3a3a3;
}
.report_form .data_count strong {
	font-weight: normal;
}
.report_attach {
	border-top: 1px solid #bdbdbd;
}
.report_attach .tit {
	padding: 26px 15px;
	font-size: 16px;
	line-height: 1.1;
	color: #222;
}
.report_attach .tit .ico_cm.addfile {
	margin-right: 5px;
}
.report_attach .tit .ico_cm.addfile:before {
	width: 29px;
	height: 22px;
	background-position: 0 -30px;
}
.report_attach .tit .point {
	color: #a07fd3;
}
.report_attach .report_attach_list {
	overflow: hidden;
	margin: -1px 0 0 -1px;
}
.report_attach .report_attach_list:after {
	display: block;
	clear: both;
	content: "";
}
.report_attach .report_attach_list li {
	position: relative;
	float: left;
	width: 25%;
	padding-bottom: 25%;
}
.report_attach .report_attach_list .img {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: 1px 0 0 1px;
}
.report_attach .report_attach_list .img img {
	width: 100%;
	height: 100%;
	vertical-align: top;
}
.report_attach .report_attach_list .img .btn_cm.delete4 {
	overflow: hidden;
	position: absolute;
	right: 0;
	top: 0;
	width: 20px;
	height: 20px;
}
.report_attach .report_attach_list .btn {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: 1px 0 0 1px;
}
.report_attach .report_attach_list .btn .btn_cm.add3 {
	width: 100%;
	height: 100%;
	background: #e8e8e8;
}
.report_attach .report_attach_list .btn .btn_cm.add3:before {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
.report_attach .report_attach_list .btn input[type="file"] {
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	-webkit-appearance: none;
}

/* 주문내역 */
.list_odr {
	margin: 0 15px;
}
.list_odr > li {
	border: 0;
}
.list_odr .arr_bt {
	padding-left: 0;
	border-bottom: 1px solid #e5e5e5;
	font-size: 16px;
}
.list_odr .arr_bt.open {
	border: 0;
}
.list_odr .cont_area {
	display: none;
}
.list_odr .tbl_row3 {
	margin-top: 5px;
}
.list_odr .tbl_col2 {
	margin-top: 20px;
}
.list_odr .tbl_row3:first-of-type,
.list_odr .tbl_col2:first-of-type {
	margin-top: 0;
}

/* TMS 배송목록 */
.srch_nodata {
	padding: 35px 0;
	border-bottom: 1px solid #e5e5e5;
	font-size: 15px;
	text-align: center;
}

.deli_status_info {
	padding: 17px 15px;
	width: 100%;
	box-sizing: border-box;
	position: relative;
	&.ty2 {
		padding: 13px 15px 12px;
		font-size: 15px;
	}
}
.deliverty_status.line {
	position: relative;
	border-bottom: 1px solid #c7c7c7;
	margin-bottom: -1px;
}
.deliverty_status .deli_date {
	font-size: 17px;
	line-height: 1.2;
}
.deliverty_status .deli_dimension {
	color: #999;
	position: absolute;
	right: 10px;
	top: 7px;
}
.deliverty_status .deli_status_badge {
	font-size: 0;
}
.deliverty_status .deli_tit_badge {
	display: inline-block;
	padding: 6px 10px;
	margin-left: 10px;
	border: 1px solid #ede6fd;
	border-radius: 17px;
	background: #f4f0fe;
	font-size: 17px;
	font-weight: bold;
	line-height: 1.2;
	color: #222;
}
.deliverty_status .deli_tit_badge:first-child {
	margin-left: 0;
}
.deli_status_info .txt_sm {
	font-size: 14px;
	color: #999;
}
.ic_deli_grade {
	display: inline-block;
	width: 28px;
	height: 28px;
	line-height: 30px;
	border-radius: 50%;
	color: #fff;
	text-align: center;
}
.deliverty_status .ic_deli_grade {
	margin-right: 5px;
}

.txt_deli_status {
	padding: 14px 4px;
	text-align: center;
	background: #e5e5e5;
	font-weight: bold;
	font-size: 17px;
	line-height: 21px;
	box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.15) inset;
}
.txt_deli_status.in_sch {
	padding-top: 28px;
}
.txt_deli_status.al {
	padding-left: 15px;
}
.txt_deli_status em {
	font-weight: bold;
	font-size: 19px;
}
.txt_deli_status .pointCol1 {
	color: #17acae;
} /* 디자인요청으로 별도처리 */
.txt_deli_status span {
	color: #999;
	display: block;
	font-size: 12px;
}
.txt_deli_status.type2 {
	padding: 20px 4px;
	font-size: 16px;
	line-height: 25px;
	color: #222;
}
.txt_deli_status.type2.in_sch {
	padding-top: 34px;
}
.txt_deli_status.type2 em {
	font-size: 16px;
}

.fake_thead {
	width: 100%;
	display: table;
	background: #f7f7f7;
	border-top: 1px solid #e5e5e5;
	border-bottom: 1px solid #e5e5e5;
	box-sizing: border-box;
	padding: 0 15px;
}
.fake_thead > li {
	display: table-cell;
	vertical-align: middle;
	text-align: center;
	padding: 10px 0;
}
.fake_thead > li:first-child {
	text-align: left;
}

.deli_total_count {
	width: 100%;
	display: table;
	border-bottom: 1px dashed #e0e0e0;
}
.deli_total_count > li {
	width: 24%;
	display: table-cell;
	text-align: center;
	padding: 10px 0;
}
.deli_total_count > li strong {
	font-size: 15px;
}
.deli_total_count > li:first-child {
	width: 28%;
}
.deli_total_count > li:first-child strong {
	font-size: 18px;
	position: relative;
	top: 1px;
}

.tbl_status_flag {
	margin-top: 5px;
}
.status_flag {
	padding: 1px 2px 0 2px;
	border: 1px solid #2bc6cb;
	display: inline-block;
	color: #2bc6cb;
	background-color: #fff;
	font-weight: bold;
	font-size: 14px;
	line-height: 16px;
}
.status_flag.type2 {
	border-color: #daa851;
	color: #daa851;
}
.status_flag.type3 {
	border-color: #f27777;
	color: #f27777;
}
.status_flag.type4 {
	border-color: #5f7ce9;
	color: #5f7ce9;
}
.status_flag.type5 {
	border-color: #000;
	color: #000;
}
.status_flag.type6 {
	border-color: #9a6cf7;
	color: #9a6cf7;
}
.status_flag.type7 {
	border-color: #5f7ce9;
	background: #5f7ce9;
	color: #fff;
}
.status_flag.type8 {
	border-color: #ff6967;
	background: #ff6967;
	color: #fff;
}
.status_flag.type9 {
	border-color: #b12536;
	background: #b12536;
	color: #fff;
}
.status_flag.type10 {
	border-color: #b69d81;
	background: #b69d81;
	color: #fff;
}
.status_flag.type11 {
	border-color: #93c333;
	background: #93c333;
	color: #fff;
}
.status_flag.type12 {
	border-color: #6b1c65;
	background: #6b1c65;
	color: #fff;
}
.status_flag.type13 {
	border-color: #cf9d0d;
	background: #cf9d0d;
	color: #fff;
}
.status_flag.complete {
	border-color: #cecece;
	color: #cecece;
}
.status_flag.re_delivery {
	border-color: #7e548a;
	color: #742a88;
}
.status_flag.no_delivery {
	border-color: #d24f4f;
	background: #e85656;
	color: #fff;
}

.flag_list {
	text-align: center;
	line-height: 0;
	font-size: 0;
	padding: 12px 0;
	width: 100%;
}
.flag_list li {
	display: inline-block;
	margin-right: 5px;
}
.flag_list li:last-child {
	margin-right: 0;
}
.flag_list li .type_flag {
	margin-right: 0;
}
.flag_list.new {
	padding: 0 0 12px 0;
}

.type_flag {
	font-weight: bold;
	display: inline-block;
	margin-right: 5px;
}
.type_flag em {
	float: left;
	display: inline-block;
	padding: 1px 2px 0 2px;
	font-weight: bold;
	text-align: center;
	font-size: 12px;
	line-height: 16px;
}
.type_flag .flag_count {
	color: #fff;
	font-size: 14px;
	min-width: 15px;
}
.type_flag .flag_tit {
	background: #fff;
	white-space: nowrap;
}
.type_flag.type1 {
	border: 1px solid #a9a251;
	background: #a9a251;
}
.type_flag.type1 em:first-child {
	color: #a9a251;
}
.type_flag.type1_1 {
	border: 1px solid #ff6a2f;
	background: #ff6a2f;
}
.type_flag.type1_1 em:first-child {
	color: #ff6a2f;
}
.type_flag.type1_2 {
	border: 1px solid #535860;
	background: #535860;
}
.type_flag.type1_2 em:first-child {
	color: #535860;
}
.type_flag.type2 {
	border: 1px solid #7fb4ff;
	background: #7fb4ff;
}
.type_flag.type2 em:first-child {
	color: #7fb4ff;
}
.type_flag.type3 {
	border: 1px solid #975fdc;
	background: #975fdc;
}
.type_flag.type3 em:first-child {
	color: #975fdc;
}
.type_flag.type4 {
	border: 1px solid #ffc000;
	background: #ffc000;
}
.type_flag.type4 em:first-child {
	color: #ffc000;
}
.type_flag.type5 {
	border: 1px solid #93c333;
	background: #93c333;
}
.type_flag.type5 em:first-child {
	color: #93c333;
}
.type_flag.type6 {
	border: 1px solid #525252;
	background: #525252;
}
.type_flag.type6 em:first-child {
	color: #fff;
	background: #525252;
}
.type_flag.type6 .flag_count {
	background: #fff;
	color: #525252;
}
.type_flag.type7 {
	border: 1px solid #eb3b3d;
	background: #eb3b3d;
}
.type_flag.type7 em:first-child {
	color: #fff;
	background: #eb3b3d;
}
.type_flag.type7 .flag_count {
	background: #fff;
	color: #eb3b3d;
}
.type_flag.type7_1 {
	border: 1px solid #ff6a2f;
	background: #ff6a2f;
}
.type_flag.type7_1 em:first-child {
	color: #ff6a2f;
	background: #fff;
}
.type_flag.type7_1 .flag_count,
.type_flag.type7_1 em:nth-of-type(2),
.type_flag.type7_1 em:nth-of-type(4),
.type_flag.type7_1 em:nth-of-type(5) {
	font-size: 14px;
	background: #ff6a2f;
	color: #fff;
}
.type_flag.type7_2 {
	border: 1px solid #535860;
	background: #535860;
}
.type_flag.type7_2 em:first-child {
	color: #535860;
	background: #fff;
}
.type_flag.type7_2 .flag_count,
.type_flag.type7_2 em:nth-of-type(2),
.type_flag.type7_2 em:nth-of-type(4),
.type_flag.type7_2 em:nth-of-type(5) {
	font-size: 14px;
	background: #535860;
	color: #fff;
}
.type_flag.type8 {
	border: 1px solid #b0a153;
	background: #b0a153;
}
.type_flag.type8 em:first-child {
	color: #b0a153;
}
.type_flag.type9 {
	border: 1px solid #fc1934;
}
.type_flag.type9 em:first-child {
	color: #fc1934;
}
.type_flag.type10 {
	border: 1px solid #fbad48;
}
.type_flag.type10 em:first-child {
	color: #fbad48;
}
.type_flag.type11 {
	border: 1px solid #89c910;
}
.type_flag.type11 em:first-child {
	color: #89c910;
}
.type_flag.type12 {
	border: 1px solid #ff504f;
}
.type_flag.type12 em:first-child {
	color: #ff504f;
}
.type_flag.type13 {
	border: 1px solid #a2624f;
}
.type_flag.type13 em:first-child {
	color: #a2624f;
}
.type_flag.type14 {
	border: 1px solid #975edc;
}
.type_flag.type14 em:first-child {
	color: #975edc;
}
.type_flag.type15 {
	border: 1px solid #93c231;
}
.type_flag.type15 em:first-child {
	color: #93c231;
}
.type_flag.type16 {
	border: 1px solid #77b7ff;
}
.type_flag.type16 em:first-child {
	color: #77b7ff;
}
.type_flag.type17 {
	border: 1px solid #59ab7f;
}
.type_flag.type17 em:first-child {
	color: #59ab7f;
}
.type_flag.type18 {
	border: 1px solid #60a6a7;
}
.type_flag.type18 em:first-child {
	color: #60a6a7;
}
.type_flag.type19 {
	border: 1px solid #999;
}
.type_flag.type19 em:first-child {
	color: #999;
}
.type_flag.type20 {
	border: 1px solid #cecece;
}
.type_flag.type20 em:first-child {
	color: #cecece;
}
.type_flag.type21 {
	border: 1px solid #555;
}
.type_flag.type21 em:first-child {
	color: #555;
}
.type_flag.type22 {
	border: 1px solid #4569ec;
}
.type_flag.type22 em:first-child {
	color: #4569ec;
}
.type_flag.type23 {
	border: 1px solid #ff9000;
}
.type_flag.type23 em:first-child {
	color: #ff9000;
}
.type_flag.type24 {
	border: 1px solid #2dc6c8;
}
.type_flag.type24 em:first-child {
	color: #2dc6c8;
}
.type_flag.type25 {
	border: 1px solid #7bad89;
}
.type_flag.type25 em:first-child {
	color: #7bad89;
}
.type_flag.type26 {
	border: 1px solid #5b57ff;
}
.type_flag.type26 em:first-child {
	color: #5b57ff;
}
.type_flag.type27 {
	border: 1px solid #888;
}
.type_flag.type27 em:first-child {
	color: #888;
}
.type_flag.highlight {
	border: 1px solid #2dc6c8;
}
.type_flag.highlight em:first-child {
	color: #2dc6c8;
}
.type_flag.default {
	border: 1px solid #555;
}
.type_flag.default em:first-child {
	color: #555;
}
.type_flag.infotype {
	border: 1px solid #999;
}
.type_flag.infotype em:first-child {
	color: #fff;
	background: #999;
}
.type_flag.vvip {
	border: 1px solid #5169b9;
}
.type_flag.vvip em:first-child {
	color: #5169b9;
}
.type_flag.gift_packing {
	border: 1px solid #f6448b;
}
.type_flag.gift_packing em:first-child {
	color: #f6448b;
}

.type_flag.disabled {
	border: 1px solid #e6e6e6;
	background: #e6e6e6;
	color: #cecece;
}

.type_status {
	display: inline-block;
	vertical-align: middle;
}
.type_status .status_tit {
	display: block;
	font-weight: 500;
	font-size: 15px;
	line-height: 19px;
	text-align: center;
	white-space: nowrap;
}
.type_status.type_btn1 .status_tit {
	padding: 1px 4px 0;
	border: 1px solid #636363;
	border-radius: 3px;
	background: #fff;
	color: #636363;
}
.type_status.type_link1 {
	position: relative;
	padding-right: 6px;
}
.type_status.type_link1:before {
	position: absolute;
	top: 50%;
	right: 0;
	width: 4px;
	height: 4px;
	border: solid #9a6cf7;
	border-width: 1px 1px 0 0;
	transform: rotate(45deg);
	transform-origin: top right;
	content: "";
}
.type_status.type_link1 .status_tit {
	color: #9a6cf7;
	border-bottom: 1px solid #9a6cf7;
}
.type_status.type_txt1 .status_tit {
	color: #888;
}

.capsule_list {
	margin: 20px 0;
	font-size: 0;
	text-align: center;
}
.capsule_list .capsule_operation {
	display: inline-block;
	margin: 0 2px;
	font-size: 20px;
	color: #555a5e;
	vertical-align: middle;
}
.type_capsule {
	display: inline-block;
	width: 67px;
	height: 80px;
	font-weight: bold;
	text-align: center;
	vertical-align: middle;
}
.type_capsule .capsule_txt,
.type_capsule .capsule_num {
	display: block;
	height: 40px;
	line-height: 38px;
	box-sizing: border-box;
}
.type_capsule .capsule_txt {
	border: 1px solid #000;
	border-bottom: 0 none;
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
	font-size: 16px;
	color: #000;
}
.type_capsule .capsule_num {
	border: 1px solid #000;
	border-top: 0 none;
	border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px;
	background: #000;
	font-size: 24px;
	color: #fff;
}
.type_capsule .capsule_lnk {
	display: block;
}
.type_capsule .capsule_lnk .capsule_num span {
	position: relative;
}
.type_capsule .capsule_lnk .capsule_num span:after {
	position: absolute;
	top: 50%;
	width: 7px;
	height: 9px;
	margin: -3px 0 0 3px;
	background: url(../img/comm_btn.png) no-repeat;
	background-size: 320px auto;
	background-position: -150px -50px;
	content: "";
}
.type_capsule.type1 .capsule_txt {
	border-color: #7f754e;
	color: #7f754e;
}
.type_capsule.type1 .capsule_num {
	border-color: #7f754e;
	background: #7f754e;
}
.type_capsule.type2 .capsule_txt {
	border-color: #00b7ed;
	color: #00b7ed;
}
.type_capsule.type2 .capsule_num {
	border-color: #00b7ed;
	background: #00b7ed;
}
.type_capsule.type3 .capsule_txt {
	border-color: #fba200;
	color: #fba200;
}
.type_capsule.type3 .capsule_num {
	border-color: #fba200;
	background: #fba200;
}
.type_capsule.type4 .capsule_txt {
	border-color: #00a077;
	color: #00a077;
}
.type_capsule.type4 .capsule_num {
	border-color: #00a077;
	background: #00a077;
}

.deli_product {
	padding: 0 15px;
	border-bottom: 1px solid #e0e0e0;
}
.deli_product:last-child {
	border-bottom: 0 none;
}
.deli_product > ul > li {
	border-bottom: 1px dashed #e0e0e0;
	padding: 15px 0;
	position: relative;
}
.deli_product .total_info {
	width: auto;
	margin: 0 -15px;
	padding: 3px 15px 3px 47px;
	background: #f7f7f7;
	border-bottom: 1px solid #e5e5e5;
	position: relative;
}
.deli_product .total_info .pointCol1 {
	font-weight: bold;
	font-size: 18px;
	margin: 0 5px;
}
.count_detail {
	color: #999;
}
.deli_product .total_info input[type="checkbox"] {
	position: absolute;
	top: 50%;
	left: 15px;
	-webkit-transform: translate(0, -50%);
	transform: translate(0, -50%);
}
.deli_product > ul > li:last-child {
	border-bottom: none;
}
.deli_product > ul > li label {
	display: block;
	box-sizing: border-box;
	position: relative;
	padding-left: 32px;
}
.deli_product > ul > li label input[type="checkbox"] {
	position: absolute;
	left: 0;
	top: 0px;
}
.deli_product > ul > li label input[type="checkbox"]:disabled ~ .deli_num_count,
.deli_product > ul > li label input[type="checkbox"]:disabled ~ .deli_num_count .count_detail,
.deli_product > ul > li label input[type="checkbox"]:disabled ~ p {
	color: #cecece !important;
}
.deli_product > ul > li .plus_minus {
	position: absolute;
	right: 5px;
	top: 5px;
}
.plus_minus {
	text-align: center;
}
.plus_minus input[type="number"] {
	width: 44px;
	background: #fff;
	border: 0;
	font-weight: bold;
	font-size: 20px;
	text-align: center;
}
.deli_product > ul > li .deli_num_count {
	margin-top: 3px;
}

.noti_delivery {
	margin: 22px 0 20px;
	font-size: 15px;
	font-weight: bold;
	line-height: 20px;
	text-align: center;
}
.no_delivery_memo {
	margin-top: -6px;
	padding: 29px 15px;
	background: #e5e5e5;
	color: #e86c6c;
	font-size: 15px;
	font-weight: bold;
	line-height: 20px;
	text-align: center;
}

.select_conainer {
	margin-top: 15px;
	padding: 0 15px;
}
.select_conainer .selectric-wrapper {
	margin-top: 10px;
}
.select_conainer .selectric-wrapper:first-child {
	margin-top: 0;
}

/* 배송차주목록 */
.list_dot.list_tms_bot {
	padding: 25px;
}
.list_dot.list_tms_bot li {
	margin-top: 10px;
}
.list_dot.list_tms_bot li:first-child {
	margin-top: 0;
}

.menu_fixed {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 100;
	// padding: 0;
}
.menu_fixed .btn_area {
	margin-top: 0;
}
.menu_fixed ul {
	display: table;
	table-layout: fixed;
	width: 100%;
	border-top: 1px solid #555;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.33);
}
.menu_fixed li {
	display: table-cell;
	border-left: 1px solid #555;
	text-align: center;
	vertical-align: top;
}
.menu_fixed li:first-child {
	border-left: 0;
}
.menu_fixed button {
	display: block;
	width: 100%;
	min-height: 55px;
	padding: 5px 0;
	background: #fff;
	color: #555;
	font-size: 18px;
	font-weight: bold;
	word-break: break-all;
	word-wrap: break-word;
}
.menu_fixed button em {
	position: relative;
}
.menu_fixed button em:after {
	display: inline-block;
	margin-left: 5px;
	background: url(../img/comm_btn.png) no-repeat;
	background-position: -236px 0;
	background-size: 320px auto;
	vertical-align: middle;
	content: "";
}
.menu_fixed button em.downward:after {
	width: 13px;
	height: 17px;
	margin-top: -4px;
	background-position: -180px -45px;
}
.menu_fixed button em.upward:after {
	width: 13px;
	height: 17px;
	margin-top: -4px;
	background-position: -195px -45px;
}
.deli_ctrbox_page {
	position: fixed;
	right: 15px;
	bottom: 110px;
	.deli_btn_top,
	.deli_btn_down {
		position: relative;
		// display: block;
		width: 40px;
		height: 40px;
		margin-top: 8px;
		border-radius: 50%;
		border: 1px solid #d7d7d7;
		background-color: rgba(255, 255, 255, 0.9);
		box-shadow: 1px 1.7px 3px 0 rgba(0, 0, 0, 0.1);
		box-sizing: border-box;
		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-position: 50% 50%;
			background-repeat: no-repeat;
		}
	}
	.deli_btn_top {
		display: none;
		&:after {
			background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M0 0h24v24H0z'/%3E%3Cpath d='m3 15 9-9 9 9' stroke='%23444' stroke-width='1.2'/%3E%3C/g%3E%3C/svg%3E%0A");
		}
		&.show {
			display: block;
		}
	}
	.deli_btn_down {
		display: none;
		&:after {
			background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M0 0h24v24H0z'/%3E%3Cpath d='m3 9 9 9 9-9' stroke='%23444' stroke-width='1.2'/%3E%3C/g%3E%3C/svg%3E%0A");
		}
		&.show {
			display: block;
		}
	}
}

/* 00차 운행정보 */
.nav_info {
	padding: 20px 15px;
	font-size: 18px;
	border-bottom: 1px solid #e5e5e5;
}
.nav_noti {
	padding: 20px 5px;
	font-size: 18px;
	border-bottom: 1px solid #e5e5e5;
	letter-spacing: -1px;
	text-align: center;
}
.map_area {
	position: relative;
	height: 229px;
	background: #eee;
}
.map_area > img {
	width: 100%;
	height: 100%;
}
.map_area .btn_wrap {
	position: absolute;
	width: 40px;
	bottom: 20px;
	right: 5px;
}
.map_area .btn_wrap .btn_cm:first-child {
	margin-bottom: -1px;
}
.list_reciever {
	overflow: hidden;
	border-bottom: 1px solid #e5e5e5;
}
.list_reciever > li {
	position: relative;
	display: block;
	float: left;
	width: 50%;
	text-align: center;
	vertical-align: middle;
	border-left: 1px dotted #e5e5e5;
	box-sizing: border-box;
	color: #999;
}
.list_reciever > li > a {
	display: block;
	padding: 10px 5px;
}
.list_reciever .name {
	color: #555;
}
.list_reciever .info {
	display: inline-block;
	margin-left: 5px;
	vertical-align: middle;
}
.txt_addr {
	padding: 12px 15px;
	border-bottom: 1px dotted #e5e5e5;
	background: #f7f7f7;
}
.addr_modify {
	padding-top: 12px;
}
.addr_modify dt {
	margin-bottom: 10px;
	font-size: 15px;
}
.addr_modify .txt_desc {
	padding: 8px 15px 0;
	line-height: 18px;
}
/*  */
.consignee_wrap {
	border-top: 1px solid #c7c7c7;
	border-bottom: 1px solid #e5e5e5;
	box-sizing: border-box;
}
.consignee_wrap .status_name em {
	color: #999;
	margin-right: 8px;
}
.consignee {
	display: block;
	padding: 0 15px;
}
.consignee_wrap.type_notice {
	position: relative;
}
.consignee_wrap.type_notice .consignee .status_name {
	width: 50%;
}
.consignee_wrap.type_notice .consignee .consignee_tel {
	width: 50%;
	padding-right: 45px;
}
.consignee_wrap.type_notice .btn_cm.noti {
	position: absolute;
	top: 43px;
	right: 40px;
}
.consignee.open .info:after {
	background-position: -54px -19px;
}
.consignee .status {
	margin-bottom: -20px;
	padding-top: 20px;
}
.consignee .status .status_flag {
	margin-right: 5px;
}
.consignee .info {
	display: table;
	position: relative;
	box-sizing: border-box;
	width: 100%;
	padding-right: 30px;
	letter-spacing: -2px;
}
.consignee .info:after {
	position: absolute;
	right: 0;
	top: 50%;
	display: block;
	width: 16px;
	height: 9px;
	margin-top: -5px;
	background: url(../img/comm_btn.png) no-repeat;
	background-size: 320px auto;
	background-position: -20px -19px;
	content: "";
}
.consignee .status_name {
	width: 60%;
	display: table-cell;
	vertical-align: middle;
	padding: 20px 0;
}
.consignee .consignee_tel {
	width: 40%;
	display: table-cell;
	vertical-align: middle;
	padding: 20px 0;
	text-align: right;
}
.order_name {
	height: 0;
	overflow: hidden;
	transition: all 0.5s;
}
.consignee.open ~ .order_name {
	height: 62px;
}
.order_name .btn_flik {
	height: 100%;
	padding: 20px 0 20px 15px;
	border-top: 1px dashed #e5e5e5;
}
.safe_care {
	padding: 15px 15px 20px 15px;
	border-top: 1px dashed #e5e5e5;
}
.safe_care .selectric-select {
	margin-top: 10px;
}

.txt_movetime {
	background: #f7f7f7;
	line-height: 20px;
	padding: 15px;
	border-bottom: 1px solid #e5e5e5;
	display: block;
}
.txt_movetime .during_time {
	margin-top: 10px;
}

/* 출하완료 */
.deli_info_area {
	padding: 0 15px;
}
.deli_info_area .tit_dep2 {
	display: inline-block;
	color: #222;
}
.deli_info_area .tit_dep2 .pointCol1 {
	font-weight: bold;
}
.deli_info_area .btnC.sizeM {
	font-size: 16px;
}
.deli_info_area .deli_box_count {
	font-size: 16px;
}
.deli_info_area .deli_box_count:before {
	display: inline-block;
	width: 0;
	height: 0;
	margin-right: 3px;
	border-style: solid;
	border-width: 5px 0 5px 6px;
	border-color: transparent transparent transparent #9a6cf7;
	content: "";
}
.deli_info_area .deli_box_count .pointCol1 {
	font-weight: bold;
}
.deli_info_area .deli_partial_cancel {
	position: relative;
	padding-left: 20px;
	font-size: 16px;
	color: #888;
}
.deli_info_area .deli_partial_cancel:before {
	position: absolute;
	top: 6px;
	left: 0;
	width: 17px;
	height: 17px;
	background: url(../img/comm_btn.png) no-repeat;
	background-size: 320px auto;
	background-position: -302px -20px;
	content: "";
}
.deli_info_area .deli_partial_cancel a {
	display: inline-block;
	margin-right: 2px;
	padding: 2px 5px;
	border: 1px solid #9a6cf7;
	border-radius: 6px;
}
.deli_info_tbl {
	margin-top: 20px;
}

/* 미완료, 출고완료 */
.deli_info_area .deli_partial_release {
	display: flex;
	position: relative;
	margin-bottom: 25px;
	padding: 10px 0;
	text-align: center;
	font-size: 14px;
	color: #444;
}
.deli_info_area .deli_partial_release:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 1px;
	background: #e5e5e5;
}
.deli_info_area .deli_partial_release:after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 1px;
	background: #e5e5e5;
}
.deli_info_area .deli_partial_release .release_txt {
	flex: 1;
	align-items: center;
}
.deli_info_area .deli_partial_release .release_txt em {
	margin-right: 3px;
	color: #ff5b59;
}
/* 부분취소 상세리스트 상단 : 돌아가기 버튼 */
.back_btn_wrap {
	position: relative;
	margin-right: 10px;
	text-align: right;
}
.back_btn_wrap:before {
	content: "";
	display: block;
	position: absolute;
	top: 8px;
	left: 207px;
	width: 5px;
	height: 5px;
	border: solid #888;
	border-width: 1px 0 0 1px;
	transform: rotate(-45deg);
}
.back_btn_wrap .back_btn {
	position: relative;
	font-size: 13px;
	color: #888;
}
.back_btn_wrap .back_btn:after {
	content: "";
	display: block;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 1px;
	background: #888;
}

/* 배송라우팅(점포기사용, 센터기사용) */
.sup_num {
	position: relative;
}
.sup_num sup {
	display: inline-block;
	width: 16px;
	height: 16px;
	margin-left: 2px;
	border-radius: 25px;
	font-weight: bold;
	font-size: 12px;
	color: #fff;
	position: absolute;
	right: -18px;
	top: -7px;
	line-height: 16px;
}
.use_store_shipping .btn_cm.checktr {
	margin-left: -22px;
}

/* 서치영역 */
.srh_shipping .splitCell {
	table-layout: fixed;
	border-collapse: collapse;
}
.srh_shipping .cell:not(:first-child) .selectric {
	border-left: none;
}

/* 점포관리자 - 수기배송관리 */
.t_search {
	border-collapse: collapse;
	table-layout: fixed;
	margin-bottom: 14px;
}
.t_search .cell:not(:first-child) .selectric {
	border-left: none;
}
.t_search .select,
.t_search .inp {
	border: none;
}

/* SMC관리자 - 점포배차 */
.scm_list .tbl_cont {
	border-bottom: 1px solid #e5e5e5;
	padding-bottom: 25px;
}
.scm_list .tbl_cont:last-child {
	border-bottom: none;
	padding-bottom: 0;
}

/* 업체배송관리 - 상세내역 */
.box_gr_close {
	background-color: #f7f7f7;
	border-bottom: 1px solid #c7c7c7;
}
.box_gr_close .cell {
	padding: 10px 15px;
	height: 25px;
	color: #999;
	font-size: 13px;
}
.comp_order_view .txt_desc {
	padding: 20px 10px 0;
}
.btn_add .txt_l {
	font-size: 15px;
	line-height: 1.3;
}

/* 직매입재고관리 */
.stocked > li {
	padding: 23px 15px 12px 15px;
	border-bottom: 1px solid #e5e5e5;
	box-sizing: border-box;
}
.stocked > li.stocked_all_select {
	background: #f7f7f7;
	padding: 13px 15px;
}
.list_all_select {
	background: #f7f7f7;
	padding: 13px 15px;
	position: relative;
	border-top: 1px solid #e5e5e5;
	border-bottom: 1px solid #e5e5e5;
	font-size: 16px;
}
.list_all_select .total_count {
	position: absolute;
	right: 15px;
	top: 18px;
}
.list_all_select input[type="checkbox"] {
	vertical-align: middle;
}
.tab_txt.line + .list_all_select {
	border-top: 0;
}
.stocked > li .type_flag {
	margin-top: 2px;
}
.stocked > li .btn_flik {
	margin-top: 10px;
}
.dashed_order_info {
	background: #f7f7f7;
	border-top: 1px dashed #e5e5e5;
	border-bottom: 1px dashed #e5e5e5;
	padding: 10px 15px;
	position: relative;
	word-wrap: break-word;
}
.dashed_order_info.bdnone {
	border-bottom: 0;
}
.dashed_order_info p {
	font-size: 12px;
	color: #999;
}
.dashed_order_info .txt_option {
	color: #555;
}
.dashed_order_info .single_code {
	color: #999;
	font-size: 12px;
	position: absolute;
	right: 15px;
	bottom: 10px;
}
.dashed_order_info strong + p {
	margin-top: 5px;
}
.dashed_order_info strong {
	font-size: 15px;
}
.dashed_order_info .txt_s {
	display: block;
	margin-top: 3px;
	font-size: 12px;
	font-weight: bold;
	color: #555;
}
.box_order_info {
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	padding: 15px 10px;
	background-color: #f7f7f7;
	letter-spacing: -0.47px;
	color: #999;

	.box_info_sec {
		display: flex;
		flex: 0 1 auto;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		width: 100%;
		border-top: 1px solid #e5e5e5;
		&:first-child {
			border-top: 0;
			margin-top: -10px;
		}
		&:last-child {
			margin-bottom: -15px;
		}
	}
	.box_info_detail {
		display: flex;
		flex: 0 1 auto;
		align-items: center;
		color: #555;
		padding: 6px 0;
		min-height: 45px;
		box-sizing: border-box;

		&.ty_wide {
			flex-grow: 1;
		}
		.inp {
			height: 35px;
		}
		.btnB {
			width: 53px;
			padding: 0 5px;
			font-size: 14px;
			height: 35px;
			letter-spacing: -0.6px;
			line-height: 35px;
			box-sizing: border-box;
		}
		.fld_chk {
			input {
				vertical-align: middle;
				margin-right: 3px;
			}
		}
		.box_info_title {
			flex: none;
			max-width: 100px;
			padding-right: 4px;
			letter-spacing: -1px;
		}
		.box_info_area {
			flex-grow: 1;
		}
		.box_total_num {
			letter-spacing: -1.12px;
			color: #fe424f;
		}
	}
	p + .box_info_sec,
	.box_info_sec + p {
		margin-top: 12px;
	}
}
.related_info {
	padding: 0 15px;
}
.related_info .count_wrap {
	width: 100%;
	display: table;
}
.related_info .count_wrap strong {
	width: 50%;
	display: table-cell;
	vertical-align: middle;
	padding: 8px 0 5px 0;
}
.related_info .model_info li,
.related_info.first_no .model_info li:first-child {
	color: #999;
	font-size: 12px;
	font-weight: normal;
}
.related_info .model_info li.gift {
	color: #555;
	font-weight: bold;
}
.related_info .model_info li:first-child {
	font-weight: bold;
	color: #555;
}
.related_info .model_info.half {
	overflow: hidden;
}
.related_info .model_info.half li {
	float: left;
	width: 50%;
}

.txt_order_tit {
	vertical-align: super;
	font-size: 14px;
	line-height: 16px;
}
.txt_order_tit.txt_s,
.txt_sma,
.prod_info_list > li .txt_sma {
	font-size: 12px;
	color: #999;
	vertical-align: middle;
}

.business_wrap .fake_thead {
	table-layout: fixed;
}
.business_wrap .fake_thead li input[type="checkbox"] {
	position: relative;
	top: -3px;
}
.cell_wrap {
	width: 100%;
	display: table;
	table-layout: fixed;
	box-sizing: border-box;
	padding: 0 15px;
	border-bottom: 1px solid #e5e5e5;
}
.cell_wrap .t_cell {
	text-align: center;
	padding: 20px 0;
	vertical-align: middle;
	display: table-cell;
	line-height: 17px;
}
.cell_wrap .cell_check {
	width: 10%;
	vertical-align: top;
	text-align: left;
}
.cell_wrap .order_id {
	width: 30%;
	padding: 0 6px;
	box-sizing: border-box;
}
.cell_wrap .order_id p {
	word-break: break-all;
}

.gray {
	color: #999 !important;
}

.shipping_order_sch > li {
	position: relative;
	padding: 20px 15px 13px 15px;
	border-bottom: 1px solid #e5e5e5;
}
.shipping_order_sch > li input[type="checkbox"] {
	vertical-align: top;
	margin-right: 10px;
}
.shipping_order_sch label > .type_flag {
	vertical-align: middle;
}
.shipping_order_sch > li .flag_list {
	padding: 0;
	text-align: left;
	display: inline-block;
	width: auto;
}
.shipping_order_sch .manager {
	position: absolute;
	top: 20px;
	right: 15px;
	font-size: 13px;
	font-weight: bold;
	color: #555;
	letter-spacing: -1px;
}
.shipping_order_sch > li .btn_flik,
.shipping_order_sch > li .area {
	margin-top: 8px;
}
.shipping_order_sch > li .dashed_order_info {
	padding: 10px 15px;
	margin-top: 0;
}
.shipping_order_sch > li .related_info {
	padding: 0 15px;
	margin-top: 13px;
}
.shipping_order_sch > li .splitCell {
	margin-top: 13px;
}
.shipping_order_sch > li .splitCell .select {
	width: 95%;
}
.shipping_order_sch > li .splitCell .inp_wrap {
	display: block;
}
.shipping_order_sch > li .changeCount .splitCell .inp_wrap {
	display: inline-block;
}
.shipping_order_sch > li .changeCount .splitCell .inp_wrap .inp {
	height: 28px;
	vertical-align: baseline;
	line-height: 28px;
	text-align: center;
	padding: 0;
}
/* .shipping_order_sch > li .splitCell .cell:last-of-type{text-align:right;} 0721 삭제 */
.shipping_order_sch > li .splitCell .cell input[type="number"] {
	width: 95%;
}
.shipping_order_sch > li .tbl_row2 {
	margin-top: 8px;
	border-top: 1px dotted #e5e5e5;
	border-bottom: 1px dotted #e5e5e5;
}
.shipping_order_sch > li.nodata {
	font-size: 15px;
	padding: 35px 0;
}
.shipping_order_sch.type_dash {
	padding: 0 15px;
	> li {
		margin: 0 -15px;
		padding: 20px 15px;
		border-top: 1px dashed #e5e5e5;
		border-bottom: 0;
		&:first-child {
			border-top: 0;
		}
	}
	.btn_flik:first-child,
	.purchase_revise:first-child,
	.etc_info:first-child {
		margin-top: 0;
	}
}
.shipping_order_sch li.disabled {
	background-color: #eee;
	.type_flag {
		border-color: #999;
		background: initial;
	}
	.type_flag .flag_tit {
		background-color: #eee;
		color: #999;
	}
	.box_order_info {
		background-color: #e5e5e5;
	}
	.manager,
	.txt_option {
		color: #999;
	}
}
.picking_order_msg {
	padding: 8px 15px;
	font-size: 12px;
	border: solid #e5e5e5;
	border-width: 1px 0;
	letter-spacing: -0.5px;
}
.picking_option {
	border-top: 1px dashed #e5e5e5;
	padding: 10px 0 0 15px;
	margin-top: 10px;
}

/* 업체배송관리 - 상세내역 */
.box_gr_close {
	background-color: #f7f7f7;
	border-bottom: 1px solid #c7c7c7;
}
.box_gr_close .cell {
	padding: 10px 15px;
	height: 25px;
	color: #999;
	font-size: 13px;
}
.comp_order_view .tbl_row2 {
	border-top: none;
}
.comp_order_view .txt_desc {
	padding: 20px 10px 0;
}

/*  배송 search영역 */
.search_area {
	background-color: #f7f7f7;
	padding-bottom: 15px;
}
.search_area.add_sch {
	padding: 15px 30px 15px 15px;
}
.frm_search,
.frm_txt_r {
	position: relative;
}
.frm_search .inp,
.frm_txt_r .inp {
	padding-right: 40px;
}
.frm_search .btn_cm.search {
	position: absolute;
	top: 0;
	right: 0;
	width: 40px;
	height: 100%;
	vertical-align: middle;
	text-align: center;
	z-index: 1;
}
.frm_search .btn_cm.search:before {
	-webkit-transform: translateY(50%);
	transform: translateY(50%);
}
.frm_search .btn_cm.resetpwd {
	position: absolute;
	top: 0;
	right: 0;
	width: 40px;
	height: 100%;
	vertical-align: middle;
	text-align: center;
	z-index: 1;
}
.frm_search .btn_cm.resetpwd:before {
	position: relative;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}
.frm_txt_r .txt_r {
	position: absolute;
	top: 0;
	right: 0;
	width: 40px;
	vertical-align: middle;
	text-align: center;
	z-index: 1;
	-webkit-transform: translateY(50%);
	transform: translateY(50%);
}
.box_srh_result {
	margin-top: 4px;
	padding: 13px 15px;
	border: 1px solid #e5e5e5;
	background-color: #fff;
}
.box_srh_result li {
	margin-top: 2px;
	font-size: 12px;
}
.box_srh_result li:first-child {
	margin-top: 0;
}
.bot_list {
	margin-top: 15px;
}

/* 조건검색 */
.btn_sch {
	position: absolute;
	left: 0;
	width: 100%;
	bottom: -40px;
	text-align: center;
	z-index: 2;
	padding-bottom: 20px;
}
.btn_sch span {
	width: 86px;
	height: 20px;
	background: #f7f7f7;
	display: inline-block;
	color: #2dc6c8;
	border-radius: 0 0 5px 5px;
	line-height: 20px;
	font-weight: bold;
	font-size: 12px;
	box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.1);
}
.sch_panel_cont {
	width: 100%;
	position: relative;
	background-color: #f7f7f7;
}
.sch_panel_cont .sch_panel {
	display: none;
	color: #000;
}
.right_sch {
	width: 100%;
	position: absolute;
	right: -100%;
	background: #fff;
	top: 0;
	transition: all 0.5s ease;
	z-index: 5;
}
.btn_Rsch {
	width: 25px;
	padding-left: 10px;
	height: 100%;
	position: absolute;
	left: -35px;
	top: 0;
	display: inline-block;
	z-index: 1;
}
/* .btn_Rsch{width:15px; height:80px; position:absolute; left:-15px; top:50%; margin-top:-40px; display:inline-block; z-index:2; background:#fff; border-radius:5px 0 0 5px; box-shadow:-4px 2px 6px rgba(0,0,0,0.1)} */
.btn_Rsch span {
	width: 15px;
	height: 80px;
	position: absolute;
	right: 0px;
	top: 50%;
	margin-top: -40px;
	display: inline-block;
	background: #fff;
	border-radius: 5px 0 0 5px;
	box-shadow: -4px 2px 6px rgba(0, 0, 0, 0.1);
}
.btn_Rsch span:after {
	content: "";
	display: block;
	width: 8px;
	height: 20px;
	background: url(../img/comm_btn.png) no-repeat;
	background-position: -236px -22px;
	background-size: 320px auto;
	position: absolute;
	left: 50%;
	top: 50%;
	margin: -10px 0 0 -4px;
}
.add_sch_condition {
	z-index: 3;
	padding: 20px 15px;
	background: #fff;
	box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.15);
}

/* 주문 - 결제수단알림관리 */
.tbl_deps_white {
	padding-top: 6px;
	padding-bottom: 6px;
	margin: -10px 0 10px;
	background-color: #fff;
	border-top: 1px solid #e5e5e5;
	border-bottom: 1px solid #e5e5e5;
}

/* 업체 */
.tbody_scroll5 {
	max-height: 230px;
	overflow-y: scroll; /*-webkit-overflow-scrolling:touch;*/
}
.box_task {
	position: relative;
}
.box_task .inp,
.box_task .selectric {
	width: 100%;
	border: none;
	background-color: transparent;
	font-size: 12px;
}
.box_task .frm {
	display: table-cell;
	height: 45px;
	padding: 4px;
	vertical-align: middle;
	box-sizing: border-box;
}
.box_task .task_list .w40 .inp {
	text-align: center;
}
.box_task .task_list {
	overflow: hidden;
	overflow-y: auto;
	width: 50%;
	max-height: 371px;
	padding-right: 50%;
	background-color: #f7f7f7;
}
.box_task .task_list > li,
.box_task .task_list2 li {
	display: table;
	width: 100%;
	table-layout: fixed;
	box-sizing: border-box;
	height: 52px;
}
.box_task .task_list > li > .frm {
	border-right: 1px dotted #e5e5e5;
	box-sizing: border-box;
	border-top: 1px solid #e5e5e5;
	border-bottom: 1px solid #f7f7f7;
}
.box_task .task_list > li > .frm.w60 {
	border-left: 1px solid #f7f7f7;
}
.box_task .task_list > .on {
	background-color: #fff;
}
.box_task .task_list > .on > .frm {
	position: relative;
	border-bottom-color: #999;
	border-top-color: #999;
}
.box_task .task_list > .on > .frm.w60 {
	border-left: 1px solid #999;
}
.box_task .task_list > li:last-child .frm {
	border-bottom-color: #e5e5e5;
}
.box_task .task_list > li:last-child.on .frm {
	border-bottom-color: #999;
}

.box_task .task_list > .on > .w40:after {
	position: absolute;
	left: 100%;
	top: 0;
	content: "";
	border-right: 1px dotted #e6e6e6;
	z-index: 1;
	bottom: 0;
	margin-left: -2px;
	width: 2px;
	background: #fff;
}
.box_task .task_list2 {
	display: none;
	position: absolute;
	right: 1px;
	top: 0;
	width: 50%;
	height: 100%;
	overflow-y: scroll; /*-webkit-overflow-scrolling:touch*/
	background: #fff;
	border: 1px solid #999;
	box-sizing: border-box;
}
.box_task .task_list2 .frm dt,
.box_task .task_list2 .frm.fld_area {
	padding-left: 10px;
	padding-right: 4px;
	font-size: 12px;
}
.box_task .task_list2 .frm {
	border-bottom: 1px solid #e5e5e5;
	padding: 4px 4px 5px 4px;
}
.box_task .task_list > li:last-child.on .task_list2 .frm {
	border-bottom: 1px solid #e5e5e5;
}
.box_task .task_list2 > li:first-child .frm {
	border-top: 0;
}
.box_task .task_list2 .fld_chk {
	margin-right: 5px;
}
.box_task .task_list2 .rd + label {
	font-size: 12px;
}
.box_task .on .task_list2 {
	display: block;
}
.box_task .selectric-select .selectric .label {
	margin-right: 10px;
	padding-top: 11px;
	padding-bottom: 11px;
}
.box_task .on .selectric-select .selectric .label {
	margin-right: 30px;
}
.box_task .selectric-disabled.selectric-select .selectric {
	background-color: transparent;
}
.box_task .selectric-select .selectric:after,
.box_task .selectric-disabled.selectric-select .selectric:after {
	opacity: 0;
}
.box_task .mdf_mode .selectric-select .selectric .label {
	margin-right: 30px;
	padding-top: 11px;
	padding-bottom: 11px;
}
.box_task .on .inp.mdf_mode,
.box_task .on .selectric-select.mdf_mode .selectric {
	border: 1px solid #e5e5e5;
}
.box_task .on .selectric-select.mdf_mode .selectric:after {
	opacity: 1;
}
.btn_save_mdf > .btnB {
	display: none;
}

/* 상품 */
.product_img_info img,
.img_quality img {
	max-width: 100%;
	vertical-align: top;
}
.product_img_info .img_function {
	border-bottom: 1px solid #e5e5e5;
}
.prod_sm_list {
	position: relative;
	padding: 15px 0 15px 15px;
}
.prod_sm_list a,
.img_quality a {
	display: inline-block;
}
.prod_sm_list .img_fix {
	width: 60px;
	height: 60px;
	border: 1px solid #999;
}
.prod_sm_list .img_fix.on {
	width: 58px;
	height: 58px;
	border: 2px solid #2dc6c8;
}
.prod_sm_list .img_scroll {
	position: absolute;
	top: 15px;
	left: 95px;
	right: 0;
	overflow: hidden;
	overflow-x: auto;
	box-sizing: border-box;
	overflow-y: hidden; /*-webkit-overflow-scrolling:touch;*/
}
.prod_sm_list .img_list {
	width: 800px;
}
.prod_sm_list .img_scroll li {
	float: left;
	width: 60px;
	height: 60px;
	margin-right: 10px;
	border: 1px solid #e5e5e5;
}
.prod_sm_list .img_scroll .on {
	border: 2px solid #2dc6c8;
	width: 58px;
	height: 58px;
}
.img_quality {
	overflow: hidden;
	height: 100vw;
	background: url(../img/noimg.png) 50% 50% no-repeat;
	background-size: cover;
}

.dl_detail dt {
	position: relative;
	padding: 11px;
	border-top: 1px solid #e5e5e5;
	background-color: #f7f7f7;
	text-align: center;
	font-size: 12px;
	word-break: break-all;
	word-wrap: break-word;
}
.dl_detail dt strong {
	font-weight: normal;
}
.dl_detail dt a {
	position: absolute;
	top: 50%;
	right: 5px;
	margin-top: -10px;
}
.dl_detail dt .type_flag {
	overflow: hidden;
}
.dl_detail dd {
	padding: 10px;
	word-break: break-all;
	word-wrap: break-word;
}
.dl_detail.md_message {
	margin-top: 15px;
}
.list_dot.list_detail_dot {
	padding-top: 15px;
	border-top: 1px solid #e5e5e5;
}
.list_dot.list_detail_dot li {
	color: #999;
}
.editer img {
	max-width: 100%;
}

.product_img_info .img_view {
	overflow: hidden;
	position: relative;
}
.product_img_info .img_view .pinch_zone img {
	width: 100%;
}

.img_tit {
	padding: 11px;
	border-bottom: 1px solid #e5e5e5;
	background-color: #f7f7f7;
	text-align: center;
	font-size: 12px;
	word-break: break-all;
	word-wrap: break-word;
}
.img_tit strong {
	font-weight: normal;
}
.img_function {
	display: -webkit-box;
	-webkit-box-align: center;
	-webkit-box-pack: justify;
	-webkit-box-orient: horizontal;
	width: 100%;
	height: 52px;
	border-top: 1px solid #e5e5e5;
	background: #f8f8f8;
}
.img_function > .btn_img_util,
.img_function > button {
	display: block;
	-webkit-box-flex: 1;
	width: 100%;
	height: 100%;
}
.img_function > .btn_img_util input[type="file"] {
	opacity: 0;
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
}
.img_function > .btn_img_util {
	position: relative;
	text-align: center;
	vertical-align: middle;
}
.img_function > .btn_img_util:after {
	content: "";
	display: inline-block;
	width: 28px;
	height: 19px;
	background: url("../img/comm_btn.png") no-repeat;
	background-position: -42px -31px;
	background-size: 320px auto;
	margin-top: 17px;
}
.img_function .delete:after {
	content: "";
	display: inline-block;
	width: 20px;
	height: 24px;
	background: url("../img/comm_btn.png") no-repeat;
	background-position: -71px -39px;
	background-size: 320px auto;
	margin-top: 6px;
}

.prod_regist_list > li {
	margin-top: 15px;
	border: 1px solid #e6e6e6;
}
.prod_regist_list > li:first-child {
	margin-top: 0;
}
.prod_regist_list > li textarea {
	width: 100%;
	height: 175px;
	border: 0;
	padding: 5px;
	box-sizing: border-box;
	resize: none;
}
.prod_regist_list > li .img_function {
	border-top: 1px solid #e6e6e6;
	background: rgba(248, 248, 248, 1);
}
.prod_regist_list > li .img_function .btn_img_util:after {
	background-position: -42px -31px;
}
.prod_regist_list > li .img_function .delete:after {
	background-position: -71px -39px;
}
.prod_regist_list > li .regist_img {
	line-height: 0;
	font-size: 0;
}
.prod_regist_list > li .regist_img img {
	width: 100%;
}
.regist_text {
	padding: 10px;
	background: #f7f7f7;
	border-bottom: 1px solid #e6e6e6;
	word-break: keep-all;
	word-wrap: break-word;
}
.suggestion .regist_text {
	border: 1px solid #e6e6e6;
	margin-top: 10px;
}

.box_price_frm .inp {
	height: 32px;
	box-sizing: border-box;
	width: 100%;
}
.grid02 {
	width: auto;
	overflow: hidden;
	margin: 5px 0 0 -5px;
}
.grid02 > div {
	float: left;
	width: 50%;
	box-sizing: border-box;
	padding-left: 5px;
}
.box_price_frm.focus .inp {
	border-color: #fff;
}
/* .box_price_frm .inp{height:32px;padding-left:3px;padding-right:3px;}
.box_price_frm .splitFrm{margin-top:5px;}
.box_price_frm .splitFrm:first-child{margin-top:0;}
.box_price_frm.focus .inp:focus{border-color:#e6e6e6;} */

.prod_color {
	display: inline-block;
	border-radius: 100%;
	width: 25px;
	height: 25px;
}

/* 전시 */
.shipping_order_sch .tit_etc {
	margin: 10px 15px 0;
	font-size: 12px;
}
.shipping_order_sch .line_catagory {
	margin-top: 0;
	padding-bottom: 8px;
	border-bottom: 1px dashed #e5e5e5;
}
.srh_product .list_price {
	overflow: hidden;
	margin-bottom: -5px;
}
.srh_product .list_price li {
	float: left;
	margin-left: 20px;
	font-weight: bold;
}
.srh_product .list_price li:first-child {
	margin-left: 0;
}
.srh_product .box_date_time > .cell {
	width: 50%;
}
.srh_product .box_date_time > .cell.txt_c {
	width: 5px;
	padding: 0;
}
.srh_product.pos_rel {
	margin-top: 10px;
}
.srh_product.pos_rel .btn_flik {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	margin: 0;
}

.box_qna_info {
	padding: 10px 15px;
	background-color: #f7f7f7;
	border-top: 1px solid #e5e5e5;
	border-bottom: 1px solid #e5e5e5;
}
.box_qna_info .txt_desc {
	font-size: 12px;
	font-weight: bold;
}
.txt_condition {
	color: #999;
	font-size: 12px;
	margin-bottom: 3px;
}

.box_view_info .tit_b {
	padding: 12px 15px;
	font-weight: bold;
	border-top: 1px solid #e5e5e5;
	border-bottom: 1px dotted #e5e5e5;
	background-color: #f7f7f7;
}
.box_view_info .txt_desc {
	padding: 12px 15px;
	border-bottom: 1px dotted #e5e5e5;
	line-height: 17px;
}
.box_view_info .info_list li {
	margin-top: 3px;
	color: #999;
	font-size: 12px;
}
.box_view_info .info_list li:first-child {
	margin-top: 0;
}
.box_view_info dd:last-child {
	border-bottom: 1px solid #e5e5e5;
}
.box_view_info .tf {
	border: none;
}
.store_manage_list .type_flag,
.store_manage_list .txt_order_tit {
	vertical-align: middle;
}
.store_manage_list li:not(:last-child) .box_view_info dd {
	border-bottom: none;
}

/* 센터 */
.list_stock_quantity {
	overflow: hidden;
	padding: 12px 0;
	border-bottom: 1px dashed #e5e5e5;
}
.list_stock_quantity li {
	float: left;
	width: 25%;
	text-align: center;
}
.list_stock_quantity .txt_s_tit {
	display: block;
	font-size: 12px;
	color: #999;
}
.list_stock_quantity .txt_num {
	display: block;
	font-weight: bold;
}

/* new table */
li .col,
.tbl_inner td {
	font-size: 13px;
	text-align: center;
	padding: 14px 5px 12px 5px;
	display: table-cell;
	box-sizing: border-box;
	vertical-align: middle;
	word-wrap: break-word;
}
.thead {
	background: #f7f7f7;
	width: 100%;
	padding: 0 15px;
	box-sizing: border-box;
	border-top: 1px solid #e5e5e5;
	border-bottom: 1px solid #e5e5e5;
}
.tbl {
	display: table;
	table-layout: fixed;
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
	font-size: 13px;
}
.thead .col {
	border: 0;
}
.thead .col.tbl_check {
}
.thead .col.tbl_check input[type="checkbox"] {
	position: relative;
	top: -3px;
}
.thead li.no_space {
	margin: 0 -15px;
}

.tbody > li {
	border-bottom: 1px solid #e5e5e5;
}
.tbody > li.bdnone {
	border-bottom: 0;
}
.tbody > li.noline {
	border-bottom: 0;
}
.tbody > li.noline .col {
	padding-top: 8px;
	padding-bottom: 6px;
}
.tbody > li.pointCol1 .tbl > .col:first-of-type {
	position: relative;
}
.tbody > li .btn_cm.checktr {
	position: absolute;
	left: 0;
	top: 50%;
	margin-top: -10px;
}
.tbody .col.tbl_check {
	vertical-align: top;
}
.tbody .col.tbl_check input[type="checkbox"] + .num {
	margin-top: 15px;
	display: block;
	font-size: 15px;
}
.tbody.ty2 .col.tbl_check {
	vertical-align: middle;
}
li.temporary {
	background: #fff7e0;
}
li.deli_complete {
	background: #ecf5f8;
}
li.deli_change {
}
li.deli_cancel {
	background: #f8efec;
}
.shipping_degree {
	color: #e86c6c;
	display: inline-block;
	margin-top: 3px;
}
.txt_complete {
	font-weight: bold;
	display: block;
	margin-top: 5px;
	font-size: 16px;
}
.txt_complete.nextday {
	color: #e86c6c;
}

.tfoot {
	background: #f7f7f7;
	width: 100%;
	box-sizing: border-box;
}
.tfoot > li {
	border-bottom: 1px solid #e5e5e5;
}

.tbl_cont {
	padding: 0 15px;
}

.w1 {
	width: 1%;
}
.w2 {
	width: 2%;
}
.w3 {
	width: 3%;
}
.w4 {
	width: 4%;
}
.w5 {
	width: 5%;
}
.w6 {
	width: 6%;
}
.w7 {
	width: 7%;
}
.w8 {
	width: 8%;
}
.w9 {
	width: 9%;
}
.w10 {
	width: 10%;
}
.w11 {
	width: 11%;
}
.w12 {
	width: 12%;
}
.w13 {
	width: 13%;
}
.w14 {
	width: 14%;
}
.w15 {
	width: 15%;
}
.w16 {
	width: 16%;
}
.w17 {
	width: 17%;
}
.w18 {
	width: 18%;
}
.w19 {
	width: 19%;
}
.w20 {
	width: 20%;
}
.w21 {
	width: 21%;
}
.w22 {
	width: 22%;
}
.w23 {
	width: 23%;
}
.w24 {
	width: 24%;
}
.w25 {
	width: 25%;
}
.w26 {
	width: 26%;
}
.w27 {
	width: 27%;
}
.w28 {
	width: 28%;
}
.w29 {
	width: 29%;
}
.w30 {
	width: 30%;
}
.w31 {
	width: 31%;
}
.w32 {
	width: 32%;
}
.w33 {
	width: 33%;
}
.w34 {
	width: 34%;
}
.w35 {
	width: 35%;
}
.w36 {
	width: 36%;
}
.w37 {
	width: 37%;
}
.w38 {
	width: 38%;
}
.w39 {
	width: 39%;
}
.w40 {
	width: 40%;
}
.w41 {
	width: 41%;
}
.w42 {
	width: 42%;
}
.w43 {
	width: 43%;
}
.w44 {
	width: 44%;
}
.w45 {
	width: 45%;
}
.w46 {
	width: 46%;
}
.w47 {
	width: 47%;
}
.w48 {
	width: 48%;
}
.w49 {
	width: 49%;
}
.w50 {
	width: 50%;
}
.w51 {
	width: 51%;
}
.w52 {
	width: 52%;
}
.w53 {
	width: 53%;
}
.w54 {
	width: 54%;
}
.w55 {
	width: 55%;
}
.w56 {
	width: 56%;
}
.w57 {
	width: 57%;
}
.w58 {
	width: 58%;
}
.w59 {
	width: 59%;
}
.w60 {
	width: 60%;
}
.w61 {
	width: 61%;
}
.w62 {
	width: 62%;
}
.w63 {
	width: 63%;
}
.w64 {
	width: 64%;
}
.w65 {
	width: 65%;
}
.w66 {
	width: 66%;
}
.w67 {
	width: 67%;
}
.w68 {
	width: 68%;
}
.w69 {
	width: 69%;
}
.w70 {
	width: 70%;
}
.w71 {
	width: 71%;
}
.w72 {
	width: 72%;
}
.w73 {
	width: 73%;
}
.w74 {
	width: 74%;
}
.w75 {
	width: 75%;
}
.w76 {
	width: 76%;
}
.w77 {
	width: 77%;
}
.w78 {
	width: 78%;
}
.w79 {
	width: 79%;
}
.w80 {
	width: 80%;
}
.w81 {
	width: 81%;
}
.w82 {
	width: 82%;
}
.w83 {
	width: 83%;
}
.w84 {
	width: 84%;
}
.w85 {
	width: 85%;
}
.w86 {
	width: 86%;
}
.w87 {
	width: 87%;
}
.w88 {
	width: 88%;
}
.w89 {
	width: 89%;
}
.w90 {
	width: 90%;
}
.w91 {
	width: 91%;
}
.w92 {
	width: 92%;
}
.w93 {
	width: 93%;
}
.w94 {
	width: 94%;
}
.w95 {
	width: 95%;
}
.w96 {
	width: 96%;
}
.w97 {
	width: 97%;
}
.w98 {
	width: 98%;
}
.w99 {
	width: 99%;
}
.w100 {
	width: 100%;
}

.thead_white {
	background: #fff;
	width: 100%;
	padding: 0 5px;
	box-sizing: border-box;
}
/* drag_drop */
.drag_drop .tbody {
	padding: 5px;
}
.drag_drop .tbody {
	background: #e5e5e5;
}
.drag_drop .tbody > li {
	position: relative;
	background: #fff;
	margin-top: 5px;
	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
	box-sizing: border-box;
	transition: all 0.2s;
}
.drag_drop .tbody > li:first-child {
	margin-top: 0;
}
.drag_drop .tbody > li:first-child {
	margin-top: 0;
}
.drag_drop .tbody li.sugi {
	background-color: #f8efec;
}
.drag_drop .tbody li.active {
	background-color: #ebe2fd;
}
.drag_drop .address {
	padding-right: 0;
	padding-bottom: 28px;
	text-align: left;
}
.drag_drop .drag {
	height: 100%;
}
.drag_drop .box_detail {
	overflow: hidden;
	max-height: 0;
	border-top: 1px solid #e5e5e5;
	background-color: #f7f7f7;
	transition: max-height 0.2s;
}
.drag_drop .box_detail.open {
	max-height: 184px;
}
.drag_drop .btn_toggle {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
}
.drag_drop .btn_toggle .btn_cm {
	display: block;
	padding: 0 0 10px;
	text-align: center;
}
.drag_drop .txt_memo {
	margin-top: 3px;
}
.drag_drop .btn_toggle,
.drag_drop .tbl {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
}
tbody.enable_select_multiple tr[data-disable="disable"] td {
	background-color: #e1e1e1;
	color: #888;
}
.sortable-chosen {
	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.7) !important;
}
.tap {
	-webkit-tap-highlight-color: transparent; /* overflow-y:scroll;-webkit-overflow-scrolling:touch; */
}
.ghost {
	opacity: 0.4;
}

.shipping_price_term {
	padding: 0 15px;
}
.shipping_price_term > li {
	margin-top: 20px;
	position: relative;
}
.shipping_price_term > li.on {
	background: #f7f7f7;
}
td.revise_wrap {
	padding: 4px 10px;
}
.revise_wrap .selectric-select .selectric {
	height: 36px;
}
.revise_wrap .selectric-disabled.selectric-select .selectric {
	height: 36px;
	color: #555 !important;
	border-color: #fff;
	background: #fff !important;
}
.revise_wrap .selectric-disabled.selectric-select .selectric:after {
	display: none;
}
.revise_wrap .selectric-disabled.selectric-select .selectric .label {
	color: #555;
	margin: 0;
}
.revise_wrap .inp,
.revise_wrap select {
	height: 36px;
}
.revise_wrap .inp:disabled {
	color: #555;
	border: 0;
	background: #fff;
	padding: 0;
}
.btn_function_wrap {
	position: absolute;
	right: 10px;
	top: 9px;
}
.btn_white {
	height: 30px;
	line-height: 30px;
	display: inline-block;
	padding: 0 15px;
	border: 1px solid #555;
	background: #fff;
	box-sizing: border-box;
}
.btn_black {
	height: 30px;
	line-height: 30px;
	display: inline-block;
	padding: 0 15px;
	border: 1px solid #555;
	background: #555;
	color: #fff;
	box-sizing: border-box;
}
.btn_save_function {
	display: none;
}
.shipping_price_term > li.on .btn_save_function {
	display: block;
}
.shipping_price_term > li.on .btn_revise {
	display: none;
}

/* 배송비정책추가 */
.no_plus .btn_cm.add::before {
	display: none;
}
.no_plus .txt_l {
	margin-left: 0;
}

.tbl_row2 td.address_wrap {
	padding: 0;
}
.tbl_row2 td.address_wrap .form_space,
.tbl_row2 td.address_wrap .address_info {
	margin-top: 4px;
	width: 100%;
	overflow: hidden;
	height: 36px;
}
.tbl_row2 td.address_wrap .address_revise {
	line-height: 0;
	font-size: 0;
	padding: 4px;
	display: none;
}
.tbl_row2 td.address_wrap .address_info .w100 {
	margin: 0;
}
.tbl_row2 td.address_wrap .address_info .inp_wrap {
	width: 100%;
}
.tbl_row2 td.address_wrap .selectric {
	height: 36px;
}
.tbl_row2 td.address_wrap .inp {
	float: left;
	margin-right: 5px;
}
.tbl_row2 td.address_wrap .selectric-wrapper {
	width: 70px;
	display: inline-block;
	vertical-align: middle;
	float: left;
	margin-right: 5px;
	font-size: 14px;
	line-height: 17px;
}
.tbl_row2 td.address_wrap .btnB {
	height: 36px;
	width: 70px;
	padding: 0;
	text-align: center;
	font-size: 13px;
	line-height: 36px;
}

.tbl_row2 td.address_wrap .address_before {
	padding: 15px;
	overflow: hidden;
}
.tbl_row2 td.address_wrap .address_before .basic_address {
	font-size: 12px;
	color: #fdc100;
	margin-left: 5px;
}
.tbl_row2 td.address_wrap .address_before > ul {
	margin-top: 10px;
}

.shipping_price_term > li.on .tbl_row2 td.address_wrap .address_revise {
	display: block;
}
.shipping_price_term > li.on .tbl_row2 tr.contract {
	display: none;
}
.shipping_price_term > li.on .tbl_row2 td.address_wrap .address_before {
	display: none;
}

.zip_area .cell > .btnB {
	width: 100%;
	display: block;
	height: 43px;
	line-height: 43px;
	font-size: 12px;
	padding: 0;
	text-align: center;
}

.srh_product {
	position: relative;
	background: #f7f7f7;
	padding: 12px;
	box-sizing: border-box;
	border-top: 1px dashed #e5e5e5;
	border-bottom: 1px dashed #e5e5e5;
}
.srh_product.splitCell > .cell {
	padding: 0 3px;
	width: 50%;
	box-sizing: border-box;
}
.srh_product.splitCell .cell .select_tit {
	font-size: 12px;
	display: block;
	color: #999;
	margin-bottom: 5px;
}
.shipping_order_sch > li .splitCell.srh_product {
	border-top: 0;
}

/* E점별 상태관리 */
.changeCount .cell {
	width: 50%;
	color: #999;
	text-align: center;
	position: relative;
}
.changeCount .cell:first-child:after {
	content: "";
	display: block;
	width: 1px;
	height: 18px;
	background: #999;
	position: absolute;
	right: 0;
	top: 50%;
	margin-top: -9px;
}
.changeCount .cell strong {
	color: #555;
}

.rdo_status {
	border-top: 1px dashed #e5e5e5;
	margin-top: 13px;
}

.reason_info {
	width: 100%;
	overflow: hidden;
	margin-top: 13px;
}
.reason_info li {
	color: #999;
	float: left;
	padding: 0 15px;
	position: relative;
}
.reason_info li:after {
	content: "";
	display: block;
	width: 1px;
	height: 13px;
	background: #999;
	position: absolute;
	left: 0;
	top: 3px;
}
.reason_info li:first-child:after {
	display: none;
}

/* 상품정보관리 */
.prod_info_list > li {
	line-height: 0;
	font-size: 0;
	border-bottom: 1px solid #e5e5e5;
	position: relative;
}
.prod_info_list > li > a:after {
	content: "";
	display: block;
	width: 9px;
	height: 17px;
	background: url(../img/comm_ico.png) no-repeat;
	background-size: 320px auto;
	position: absolute;
	right: 15px;
	top: 50%;
	margin-top: -9px;
}
.prod_info_list .figure {
	display: table;
	table-layout: fixed;
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
	margin: 0;
}
.prod_info_list .figure .fig_check {
	width: 10%;
	display: table-cell;
	vertical-align: top;
	box-sizing: border-box;
	padding: 22px 0 22px 15px;
}
.prod_info_list .figure .fig_check + .img {
	width: 25%;
	padding-left: 5px;
}
.prod_info_list .figure .fig_check + .img + figcaption {
	width: 55%;
}
.prod_info_list .figure .img {
	width: 30%;
	display: table-cell;
	vertical-align: top;
	box-sizing: border-box;
	padding: 22px 15px;
}
.prod_info_list .figure img {
	display: block;
	width: 100%;
	border: 1px solid #e5e5e5;
}
.prod_info_list .figure figcaption {
	width: 70%;
	display: table-cell;
	vertical-align: top;
	box-sizing: border-box;
	padding: 20px 40px 18px 0;
}
.prod_info_list .figure figcaption ul {
	margin-top: 5px;
	width: 100%;
	overflow: hidden;
}
.prod_info_list .figure figcaption ul > li {
	font-size: 14px;
	line-height: 20px;
	float: left;
	width: 100%;
}
.prod_info_list .figure figcaption ul > li.place {
	font-size: 12px;
	color: #999;
}
.prod_info_list .figure figcaption ul > li.w40 {
	width: 40%;
}
.prod_info_list .figure figcaption ul > li.w50 {
	width: 50%;
}
.prod_info_list .figure figcaption ul > li.w60 {
	width: 60%;
}
.prod_info_list.arr_no > li:after {
	display: none;
}
.prod_info_list.arr_no .figure figcaption {
	padding-right: 15px;
}
.prod_info_list.sty2 .txt_order_tit {
	display: block;
	margin-top: 5px;
	font-weight: normal;
	line-height: 20px;
}
.prod_info_list.sty2 .txt_order_tit + ul {
	margin: 0;
}
.box_gray_chk {
	padding: 15px;
	box-sizing: border-box;
	background-color: #f7f7f7;
	border-bottom: 1px dotted #e5e5e5;
}
.box_gray_chk .fld_chk > label,
.box_gray_chk .ar {
	font-size: 14px;
	line-height: 1;
}

/* 상품기본정보 */
.tit_bd {
	font-size: 16px;
	padding-left: 12px;
	position: relative;
	margin-bottom: 5px;
	width: 100%;
	box-sizing: border-box;
}
.tit_bd .location {
	font-size: 14px;
	position: absolute;
	right: 0;
	top: 1px;
}
.tit_bd:after {
	position: absolute;
	left: 0;
	top: 4px;
	content: "";
	display: block;
	width: 4px;
	height: 15px;
	background: #2dc6c8;
}
.tbl_noline {
	padding: 15px 0;
}
.tbl_noline table {
	width: 100%;
	table-layout: fixed;
}
.tbl_noline table th {
	font-size: 12px;
	vertical-align: middle;
	text-align: left;
	padding: 7px 0 7px 15px;
	vertical-align: top;
	line-height: 20px;
}
.tbl_noline table td {
	font-size: 14px;
	padding: 7px 0;
	line-height: 20px;
}
.tbl_noline table td .flag_list,
.tbl_row .flag_list {
	padding: 0;
	text-align: left;
}
.tbl_noline table td .fld_area.no_space {
	position: relative;
	margin-top: -3px;
}
.bot_dashed {
	padding: 15px 0;
	margin: 0 15px;
	border-bottom: 1px dashed #e5e5e5;
}

.linked {
	position: relative;
}
.linked label {
	padding: 0;
	vertical-align: top;
	font-weight: bold;
}
.linked .company_info {
	position: absolute;
	right: 0;
	top: 0;
}
.shipping_order_sch > li .linked + .splitCell.srh_product {
	border-top: 1px dashed #e5e5e5;
}
.shipping_order_sch > li .sell_status {
	margin-top: 5px;
}
.shipping_order_sch > li .sell_status .soldout {
	color: #e86c6c;
}
.sale {
	color: #fff;
	font-weight: bold;
	padding: 0 3px;
	height: 13px;
	font-size: 11px;
	line-height: 13px;
	background: #fdc100;
	display: inline-block;
	position: absolute;
	margin: -3px 0 0 2px;
}
.tbl_row th .another_price {
	padding: 0 3px;
	height: 13px;
	font-size: 11px;
	line-height: 13px;
	color: #999;
	border: 1px solid #999;
	display: inline-block;
}
.tbl_row th.line2 {
	padding: 5px 10px 5px 0;
}

/* 셔벳브랜드 관리 */
.exhibition_list > li {
	width: 100%;
	padding: 0 15px;
	box-sizing: border-box;
	overflow: hidden;
	border-bottom: 1px solid #e5e5e5;
	line-height: 0;
	font-size: 0;
}
.exhibition_list > li table {
	width: 100%;
	table-layout: fixed;
	border-collapse: separate;
	border-spacing: 0;
}
.exhibition_list > li table td.thumb {
	padding: 12px 0 12px 15px;
	vertical-align: top;
}
.exhibition_list > li table td.thumb img {
	width: 100%;
	height: auto;
}
.exhibition_list > li table td.thumb a {
	display: block;
}
.exhibition_list > li table td.dashed {
	padding: 13px 0 10px 0;
	border-bottom: 1px dashed #e5e5e5;
	vertical-align: middle;
	text-align: center;
}
.exhibition_list > li table td.dashed input {
	vertical-align: top;
}
.exhibition_list > li table td.brand_name {
	padding: 13px 0 10px 0;
	border-bottom: 1px dashed #e5e5e5;
	vertical-align: middle;
	text-align: center;
}
.exhibition_list > li table td.brand_name a {
	font-weight: bold;
	font-size: 15px;
}
.exhibition_list > li table td.infoList {
	padding: 12px 0;
}
.exhibition_list > li table td.infoList ul li {
	color: #999;
	font-size: 13px;
}
.exhibition_list > li table td.square_number {
	padding: 12px 0;
}
.exhibition_list > li table td.alarm_cont {
	padding-left: 15px;
	text-align: left;
	border-bottom: 1px dashed #e5e5e5;
}
.exhibition_list > li table td.square_number .inp {
	text-align: center;
}
.exhibition_list > li table td.store_noti {
	padding: 13px 0;
	color: #999;
}
.exhibition_list > li table td.store_noti .basicCol {
	margin-right: 15px;
}
.exhibition_list .btn_flik {
	margin: 0 -15px;
	padding: 0 15px;
}

.brand_list > li {
	width: 100%;
	padding: 0 15px;
	box-sizing: border-box;
	overflow: hidden;
	border-bottom: 1px solid #e5e5e5;
	line-height: 0;
	font-size: 0;
}
.startEnd {
	width: 100%;
	table-layout: fixed;
	border-collapse: collapse;
	font-size: 13px;
	line-height: 1.5;
}
.startEnd td {
	text-align: center;
	padding: 11px 0;
	vertical-align: middle;
}
.startEnd td.thumb {
	padding-left: 15px;
	vertical-align: top;
}
.startEnd td.thumb img {
	width: 100%;
}
.startEnd .start td {
	border-top: 1px dashed #e5e5e5;
	padding-bottom: 3px;
}
.startEnd .end td {
	border-top: 1px dashed #e5e5e5;
	padding-top: 3px;
}
.startEnd td .pickadate {
	border-right: 0 !important;
}
.startEnd td .pickatime {
	border-left: 0 !important;
}
.shipping_order_sch .startEnd .start td {
	border-top: 0;
}
.shipping_order_sch .startEnd .end td {
	padding-bottom: 0;
}
.shipping_order_sch .startEnd .inp_wrap {
	width: 100%;
}

.pinch_wrap {
	overflow: hidden;
}
.pinch_area {
	padding: 20px 15px;
	overflow: hidden;
}
.pinch_area img {
	width: 100%;
}

.file_wrap {
	width: 100%;
	position: relative;
}
.file_wrap input[type="file"] {
	opacity: 0;
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
}
.file_wrap .btnC {
	display: block;
	padding-left: 6px;
	padding-right: 6px;
}
.file_wrap .btnC .txt_l {
	font-size: 13px;
	line-height: 35px;
}
.file_wrap .img {
	width: 1%;
	box-sizing: border-box;
}
.file_wrap .img img {
	width: 36px;
	vertical-align: top;
	border: 1px solid #e5e5e5;
}

.box_exp_area {
	padding: 10px;
	background-color: #f8f8f8;
}

/* ECMS */
.purchase_info {
	padding-top: 12px;
	border-top: 1px dashed #e5e5e5;
	line-height: 20px;
	width: 100%;
	box-sizing: border-box;
}
.purchase_info dt {
	font-weight: bold;
}
.basicCol {
	color: #555 !important;
}
.etc_info {
	width: 100%;
	overflow: hidden;
	margin-top: 5px;
	.txt_option {
		color: #555;
	}
}
.etc_info > li {
	float: left;
	width: 50%;
	color: #999;
	line-height: 23px;
}
.etc_info > li.w100 {
	width: 100%;
}
.purchase_revise {
	padding: 0 10px;
	margin-top: 10px;
}

/* 프로모션 */
.promo_detail_info {
	padding: 0 15px;
	border-bottom: 1px dashed #e5e5e5;
	background: #f7f7f7;
}
.promo_detail_info .ing_status {
	padding: 0;
}
.promo_detail_info .ing_status .col {
	padding: 14px 0 12px 0;
}
.promo_time {
	border-top: 1px dashed #e5e5e5;
	padding: 5px 0;
}
.promo_time + .etc_info {
	margin-top: 0;
	border-top: 1px dashed #e5e5e5;
	padding: 10px 0;
}
.offer_status {
	padding: 10px 15px;
}
.offer_list > li {
	border-top: 1px dashed #e5e5e5;
}
.offer_list > li:first-child {
	border-top: 0;
}
.offer_list > li .btn_flik {
	margin-top: 0;
}
.offer_status .etc_info {
	margin-top: 0;
}
.ing_status {
	padding: 0 15px 0 10px;
	border-top: 1px dashed #e5e5e5;
	width: 100%;
	box-sizing: border-box;
	display: table;
	table-layout: fixed;
}
li .ing_status .col {
	padding-bottom: 0;
}

.auth_list > li {
	padding: 8px 15px;
	border-top: 1px dashed #e5e5e5;
}
.auth_list > li:first-child {
	border-top: none;
}
.auth_list > li:last-child {
	padding-bottom: 0;
}
.auth_list .etc_info {
	margin-top: 0;
}
.auth_list .etc_info > li {
	font-size: 13px;
}
.auth_list .txt_unit {
	padding-left: 12px;
}

/* 셔벳 추가 */
.product_title {
	padding: 10px 15px;
}
.product_title h1 {
	font-size: 16px;
	color: #2dc6c8;
	padding-bottom: 10px;
	border-bottom: 1px solid #e5e5e5;
	display: block;
}
.product_title > ul {
	width: 100%;
	overflow: hidden;
	padding-top: 10px;
}
.product_title > ul li {
	float: left;
	margin-right: 20px;
	position: relative;
}
.product_title > ul li:after {
	content: "";
	display: block;
	width: 1px;
	height: 15px;
	background: #e5e5e5;
	position: absolute;
	right: -11px;
	top: 2px;
}
.product_title > ul li:last-child {
	margin-right: 0;
}
.product_title > ul li:last-child:after {
	display: none;
}
.product_title > ul li a {
	color: #cecece;
}
.product_title > ul li a.on {
	color: #555;
	font-weight: bold;
}

/*! Flickity v2.0.2 */
.flickity-enabled {
	position: relative;
}
.flickity-enabled:focus {
	outline: 0;
}
.flickity-viewport {
	overflow: hidden;
	position: relative;
	height: 100%;
}
.flickity-slider {
	position: absolute;
	width: 100%;
	height: 100%;
}
.flickity-enabled.is-draggable {
	-webkit-tap-highlight-color: transparent;
	tap-highlight-color: transparent;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.flickity-enabled.is-draggable .flickity-viewport {
	cursor: move;
	cursor: -webkit-grab;
	cursor: grab;
}
.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
	cursor: -webkit-grabbing;
	cursor: grabbing;
}
.flickity-prev-next-button {
	position: absolute;
	top: 50%;
	width: 10%;
	height: 10%;
	border: none;
	border-radius: 50%;
	background: #fff;
	background: hsla(0, 0%, 100%, 0);
	cursor: pointer;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}
.flickity-prev-next-button:hover {
}
.flickity-prev-next-button:focus {
	outline: 0;
	box-shadow: 0 0 0 5px #09f;
}
.flickity-prev-next-button:active {
	opacity: 0.6;
}
.flickity-prev-next-button.previous {
	left: 10px;
}
.flickity-prev-next-button.next {
	right: 10px;
}
.flickity-rtl .flickity-prev-next-button.previous {
	left: auto;
	right: 10px;
}
.flickity-rtl .flickity-prev-next-button.next {
	right: auto;
	left: 10px;
}
.flickity-prev-next-button:disabled {
	opacity: 0.3;
	cursor: auto;
}
.flickity-prev-next-button svg {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}
.flickity-prev-next-button .arrow {
	fill: #999;
}
.flickity-page-dots {
	position: absolute;
	width: 100%;
	bottom: -25px;
	padding: 0;
	margin: 0;
	list-style: none;
	text-align: center;
	line-height: 1;
}
.flickity-rtl .flickity-page-dots {
	direction: rtl;
}
.flickity-page-dots .dot {
	display: inline-block;
	width: 10px;
	height: 10px;
	margin: 0 8px;
	background: #333;
	border-radius: 50%;
	opacity: 0.25;
	cursor: pointer;
}
.flickity-page-dots .dot.is-selected {
	opacity: 1;
}

.carousel {
	background: #fff;
}
.carousel-cell {
	width: 100vw;
	height: 100vw;
}
.carousel-cell img {
	width: 100%;
	height: 100%;
}

/* 앱 다운로드 */
.box_appdown {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 0 20px;
	min-height: 550px;
	background-color: #2dc6c8;
	color: #fff;
	text-align: center;
}
.box_appdown:before,
.box_appdown:after {
	content: "";
	display: table;
}
.box_appdown .h_tit {
	margin-top: 65px;
	font-size: 30px;
}
.box_appdown .txt {
	font-size: 15px;
	line-height: 21px;
}
.box_appdown .appdown_list {
	position: relative;
	margin-top: 85px;
}
.box_appdown .appdown_list:after {
	display: block;
	clear: both;
	content: "";
}
.box_appdown .appdown_item {
	float: left;
	width: 50%;
}
.box_appdown .appdown_item a {
	display: block;
	font-weight: bold;
	font-size: 12px;
}
.box_appdown .appdown_item a:before {
	display: block;
	width: 113px;
	height: 113px;
	margin: 0 auto 12px;
	content: "";
}
.box_appdown .appdown_item .btn_android:before {
	background: url(../img/ico_android.png) 50% 0 no-repeat;
	background-size: 113px 113px;
}
.box_appdown .appdown_item .btn_ios:before {
	background: url(../img/ico_ios.png) 50% 0 no-repeat;
	background-size: 113px 113px;
}
.box_appdown .appdown_foot {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 12px 0;
}
.box_appdown .foot_txt {
	text-align: center;
	font-weight: normal;
	font-size: 13px;
}
.box_appdown .foot_txt a {
	text-decoration: none;
	font-weight: normal;
}

/* TMS 개선 */
.body_tms .deliverty_status .deli_dimension {
	font-size: 18px;
}
.body_tms .deliverty_status .deli_date {
	font-size: 18px;
}
.body_tms .deli_status_info {
	text-align: center;
}
.body_tms .deli_status_info .txt_sm {
	font-size: 17px;
}
.body_tms .tit_dep2 {
	font-size: 18px;
}
.body_tms .txt_deli_status span {
	font-size: 15px;
}
.body_tms .thead .col,
.body_tms .thead_white .col {
	font-size: 16px;
	letter-spacing: -0.5px;
	line-height: 1.1;
	word-break: break-all;
	word-wrap: break-word;
}
.body_tms .tbody .col,
.body_tms .tfoot .col,
.body_tms .tbl_inner td {
	font-size: 16px;
	letter-spacing: -0.5px;
	word-break: break-all;
	word-wrap: break-word;
}
.body_tms .tbody .col.tbl_check input[type="checkbox"] + .num {
	font-size: 18px;
}
.body_tms .tbl_row2 th,
.body_tms .tbl_row2 td {
	font-size: 17px;
	line-height: 20px;
	letter-spacing: -0.5px;
	word-break: break-all;
	word-wrap: break-word;
}
.body_tms .tbl_col3 tbody .ico_chk {
	width: 23px;
	height: 23px;
	background-size: 408px auto;
	background-position: -167px -51px;
}
.body_tms .deli_memo {
	display: block;
	margin-top: 5px;
	color: #ff2f2f;
}
.body_tms .txt_complete {
	font-size: 18px;
}
.body_tms .type_flag em,
.body_tms .status_flag {
	font-size: 17px;
	line-height: 20px;
}
.body_tms .txt_movetime {
	font-size: 18px;
}
.body_tms .txt_movetime p {
	margin-top: 10px;
}
.body_tms .txt_movetime p:first-child {
	margin-top: 0;
}
.body_tms .consignee_wrap {
	font-size: 18px;
}
.body_tms .consignee .status {
	min-height: 27px;
}
.body_tms .list_reciever .info {
	font-size: 18px;
}
.body_tms .txt_addr {
	font-size: 18px;
}
.body_tms .addr_modify .selectric-select {
	font-size: 18px;
}
.body_tms .list_dot li {
	font-size: 17px;
	color: #999;
}
.body_tms .list_dot li:before {
	top: 9px;
}
.body_tms .deli_type_list {
	margin: 0 8px;
	border-top: 1px dashed #e5e5e5;
}
.body_tms .addr_modify dt {
	font-size: 17px;
}
.body_tms .txt_desc {
	font-size: 17px;
}
.body_tms .deli_product {
	font-size: 17px;
}
.body_tms .chk {
	width: 23px;
	height: 23px;
	background-size: 408px auto;
	background-position: -47px 0;
}
.body_tms .chk[type="checkbox"]:checked {
	background-position: -70px 0;
}
.body_tms .chk[disabled] {
	background-position: -93px 0;
}
.body_tms .modal .txt_top_desc {
	padding: 18px;
	font-size: 18px;
	letter-spacing: -0.3px;
}
.body_tms .modal .list_check > li > a,
.body_tms .modal .list_check .lab_txt {
	padding: 18px;
	color: #555;
	font-size: 18px;
	letter-spacing: -0.3px;
}
.body_tms .modal .list_check .rd[type="radio"]:checked + .lab_txt,
.body_tms .modal .list_check > .on a,
.body_tms .modal .list_check > .on .lab_txt {
	color: #2dc6c8;
}
.body_tms .type_capsule .capsule_txt {
	font-size: 18px;
}
.body_tms .alarm_msg {
	background: #9a6cf7;
	font-size: 16px;
	line-height: 1.3;
}

/* TMS(dpo이관) */
.body_tms .pointCol1,
.body_tms .modal .list_check .rd[type="radio"]:checked + .lab_txt,
.body_tms .use_store_shipping .tbl_col1 .on td,
.body_tms .modal .list_check > .on a,
.body_tms .modal .list_check > .on .lab_txt {
	color: #9a6cf7;
}
.body_tms .pointBg1,
.body_tms .btnA,
.body_tms .header,
.body_tms .pop_layer .tit_dep1,
.body_tms .sup_num sup,
.body_tms .btn_cm.tel2,
.body_tms .modal .tit_dep1,
.body_tms .cm_toggle:checked + label {
	background-color: #9a6cf7;
}
.body_tms .selectric-select .selectric-items li.selected {
	color: #9a6cf7;
}
.body_tms .selectric-select .selectric-label {
	background-color: #9a6cf7;
}
.body_tms .btn_cm.checktr {
	border: 2px solid #9a6cf7;
}
.body_tms .btn_cm.checktr:before {
	background-position: -150px -40px;
}
.body_tms .consignee.open .info:after {
	width: 17px;
	height: 10px;
	background-position: -113px -39px;
}
.body_tms .chk[type="checkbox"]:checked {
	background-position: -167px -51px;
}
.body_tms .btn_cm.arr_bt.open:before {
	background-position: -113px -39px;
}
.body_tms .box_appdown {
	background-color: #9a6cf7;
}
.body_tms .box_appdown .h_tit {
	margin-top: 165px;
	font-size: 29px;
}
.body_tms .box_appdown em {
	display: block;
	margin: 11px 0 27px 0;
	font-size: 14px;
}
.body_tms .box_appdown .txt {
	font-size: 14px;
	line-height: 22px;
}
.body_tms .box_appdown .appdown_item {
	width: 100%;
}
.body_tms .box_appdown .appdown_list {
	margin-top: 28px;
}

/* MD상담관리 */
.mdcousel_tit_area {
	padding: 6px 15px;
}
.mdcousel_tit_area .tit_txt {
	border-bottom: 1px solid #e5e5e5;
	line-height: 34px;
	font-size: 16px;
	color: #2dc6c8;
}
.mdcousel_tit_area .btn_tolist {
	position: absolute;
	right: 15px;
	top: 17px;
	padding: 0 5px;
	border: 1px solid #c2c2c2;
	line-height: 21px;
	letter-spacing: -1px;
	font-size: 12px;
	color: #888;
}
.mdcousel_tit_area .tab_txt {
	border-bottom: 0 none;
}
.mdcousel_tit_area .tab_txt > li > a {
	padding: 7px 10px;
	letter-spacing: -1px;
}
.mdcousel_tit_area .tab_txt > li:first-child > a {
	padding-left: 0;
}
.mdcounsel_lst.tbl_cont {
	padding: 0;
}
.mdcounsel_lst.tbl_cont .tbody > li {
	padding: 0 15px 20px;
}
.mdcounsel_lst .type_flag {
	vertical-align: middle;
}
.mdcounsel_lst .dashed_order_info strong {
	font-size: 14px;
}
.mdcousel_answer {
	padding: 15px 15px 5px;
}
.mdcousel_answer .dashed_order_info {
	padding-top: 0;
}
.mdcousel_answer .article_wrap {
	padding: 10px 15px 15px;
	line-height: 24px;
	color: #4b4b4b;
}
.mdcousel_answer .article_tit {
	margin-bottom: 10px;
	padding-bottom: 10px;
	border-bottom: 1px dashed #555;
}
.mdcousel_answer .addfile_area {
	position: relative;
	padding: 9px 15px;
	border-top: 1px dotted #e5e5e5;
	line-height: 45px;
}
.mdcousel_answer .addfile_area em {
	font-weight: bold;
}
.mdcousel_answer .addfile_area .btn_addfile {
	position: absolute;
	right: 4px;
	top: 10px;
	display: inline-block;
	padding-right: 54px;
}
.mdcousel_answer .addfile_area .btn_addfile:before {
	position: absolute;
	right: 0;
	top: 0;
	width: 45px;
	height: 45px;
	border-radius: 55px;
	border: 1px solid #d7d7d7;
	text-align: center;
	line-height: 40px;
	content: "";
}
.mdcousel_answer .addfile_area .btn_addfile:after {
	position: absolute;
	right: 13px;
	top: 13px;
	width: 18px;
	height: 17px;
	background: url(../img/comm_btn.png) 0 -15px no-repeat;
	background-size: 320px auto;
	content: "";
}
.list_credit > li > a {
	padding: 15px;
	background-color: #f7f7f7;
}
.list_credit .ico_cm.arr_bt.open:after {
	background-position: -262px 0;
}

/*** mbo 쓱톡페이지 (s) ***/
/* common */
.mbo_talk input.btn_addfile,
.mbo_talk .shop_item.m_ssg_lst .talk_chk label:before,
.mbo_talk i.ic {
	display: inline-block;
	overflow: hidden;
	background-image: url(../img/talk/sp_talk_v3.png);
	background-size: 300px auto;
	line-height: 999em;
	vertical-align: middle;
}
.mbo_talk i.ic_alert {
	width: 58px;
	height: 52px;
	margin: 25px auto;
	background-position: 0 -190px;
}
.mbo_talk i.ic_star {
	width: 4px;
	height: 4px;
	margin-right: 3px;
	background-position: -30px -100px;
}
.mbo_talk i.ic_bookmark {
	width: 21px;
	height: 20px;
	margin-top: -3px;
	background-position: -60px -100px;
}
.mbo_talk i.ic_bookmark.on {
	background-position: -85px -100px;
}
.mbo_talk input.btn_addfile {
	width: 52px;
	height: 51px;
	border: 0;
	-webkit-appearance: none;
	background-position: -95px -135px;
}

.mbo_talk .point_c1 {
	color: #2dc6c8 !important;
}
.mbo_talk .point_c2 {
	color: #7989c7 !important;
}

.mbo_talk .align_left {
	text-align: left;
}
.mbo_talk .align_right {
	text-align: right;
}
.mbo_talk .align_center {
	text-align: center;
}

.mbo_talk .hr_ty1 {
	height: 9px;
	background: #f5f5f5;
}

.mbo_talk .inp {
	width: 100%;
	height: 37px;
	padding: 0 10px;
	border: 0;
	border-bottom: 2px solid #d6dbdd;
	background: #fff;
	color: #222;
	font-size: 18px;
	letter-spacing: -1px;
	line-height: 37px;
	text-align: center;
	box-sizing: border-box;
}
.mbo_talk .inp:focus {
	outline: none;
	border-color: #222;
}
.mbo_talk .inp::-webkit-input-placeholder {
	border-color: #d6dbdd;
	color: #c2c9cd;
}
.mbo_talk .inp_time {
	position: relative;
	width: 100%;
	height: 34px;
	padding: 0 8px;
	border: 2px solid #d6dbdd;
	border-radius: 0;
	background: #fff;
	color: #606367;
	font-size: 14px;
	letter-spacing: -0.5px;
	line-height: 34px;
	box-sizing: border-box;
	-webkit-appearance: none !important;
}
.mbo_talk .inp_time:after {
	position: absolute;
	top: 50%;
	right: 8px;
	width: 0;
	margin-top: -3px;
	border-top: 6px solid #d6dbdd;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	content: "";
}
.mbo_talk .inp_chk {
	width: 16px;
	height: 16px;
	vertical-align: middle;
}
.mbo_talk .inp_chk + label {
	margin-left: 4px;
	vertical-align: -1px;
}
.mbo_talk .inp_cnt {
	display: block;
	font-family: Avenir-Medium;
	font-size: 11px;
	text-align: right;
	line-height: 18px;
}
.mbo_talk .inp_area {
	display: block;
	position: relative;
}
.mbo_talk .inp_area input {
	padding: 0 25px;
}
.mbo_talk .inp_area .del {
	opacity: 0;
	overflow: hidden;
	position: absolute;
	top: 50%;
	right: 0;
	padding: 5px;
	font-size: 0;
	transform: translateY(-50%);
}
.mbo_talk .inp_area .type_flag {
	position: absolute;
	top: 50%;
	right: 30px;
	transform: translateY(-50%);
}
.mbo_talk .inp_area .ic_del {
	width: 16px;
	height: 16px;
	background-position: 0 -125px;
	vertical-align: top;
}
.mbo_talk .inp_area.on .del {
	opacity: 1;
}
.mbo_talk .inp_area.inp_l input {
	text-align: left;
	padding: 0 150px 0 0;
}
.mbo_talk .textarea {
	width: 100%;
	padding: 9px 15px;
	border: 2px solid #d6dbdd;
	letter-spacing: -1px;
	line-height: 22px;
}
.mbo_talk .textarea:focus {
	border: 2px solid #2dc6c8;
}

.mbo_talk .inp2 {
	width: 100%;
	height: 34px;
	padding: 0 10px;
	border: 2px solid #d6dbdd;
	background: #fff;
	color: #606367;
	font-size: 14px;
	letter-spacing: -1px;
	line-height: 30px;
	text-align: left;
	box-sizing: border-box;
	vertical-align: middle;
}
.mbo_talk .inp_area .inp2 {
	padding: 0 25px 0 10px;
}
.mbo_talk .inp2:focus {
	outline: none;
	border-color: #222;
}
.mbo_talk .inp2::-webkit-input-placeholder {
	border-color: #d6dbdd;
	color: #c2c9cd;
}
.mbo_talk .sel_area {
	position: relative;
	width: 100%;
}
.mbo_talk .sel_area:after {
	position: absolute;
	top: 50%;
	right: 8px;
	width: 0;
	margin-top: -3px;
	border-top: 6px solid #d6dbdd;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	content: "";
}
.mbo_talk .sel_area select {
	width: 100%;
	height: 34px;
	padding-left: 10px;
	border: 2px solid #d6dbdd;
	background-color: #fff;
	font-size: 14px;
	letter-spacing: -1px;
	line-height: 30px;
	color: #606367;
	-webkit-appearance: none;
}

.mbo_talk .frm {
	margin: 16px 0 20px;
}
.mbo_talk .frm_dl {
	padding: 0 15px;
}
.mbo_talk .frm_dl dt {
	font-size: 12px;
	letter-spacing: -1px;
}
.mbo_talk .frm_dl dt .ic_required {
	width: 7px;
	height: 7px;
	margin-left: 2px;
	background-position: 0 -100px;
	vertical-align: 2px;
}
.mbo_talk .frm_dl dt.ty2 {
	margin: 5px 0;
}
.mbo_talk .frm_dl dd .colgroup {
	display: table;
	table-layout: fixed;
	width: 100%;
}
.mbo_talk .frm_dl dd .colgroup_phone {
	display: table;
	table-layout: fixed;
	width: 100%;
	padding-bottom: 18px;
}
.mbo_talk .frm_dl dd .col {
	display: table-cell;
	color: #606367;
	font-size: 14px;
	letter-spacing: -0.5px;
	vertical-align: middle;
}
.mbo_talk .frm_dl dd .tx_bar {
	color: #d6dbdd;
}
.mbo_talk .stit + .frm_dl,
.mbo_talk .menu_list .frm_dl {
	padding: 0 30px;
}
.mbo_talk .frm_btn {
	margin-top: 18px;
	text-align: center;
}

.mbo_talk .frm_item .frm_dl {
	padding-right: 15px;
}
.mbo_talk .frm_item .frm_dl dd.find {
	position: relative;
	padding-right: 100px;
}
.mbo_talk .frm_item .frm_dl dd.find .inp {
	padding-left: 5px;
	color: #777;
	font-size: 17px;
	font-family: Avenir-Medium;
	text-align: left;
	letter-spacing: 0;
}
.mbo_talk .frm_item .frm_dl dd.find .btn {
	position: absolute;
	top: 0;
	right: 0;
}
.mbo_talk .frm_item .frm_dl dd.detail {
	position: relative;
	margin-top: 8px;
	padding-left: 28px;
}
.mbo_talk .frm_item .frm_dl dd.detail:before {
	position: absolute;
	top: 5px;
	left: 10px;
	overflow: hidden;
	width: 7px;
	height: 7px;
	border-left: 2px solid #d6dbdd;
	border-bottom: 2px solid #d6dbdd;
	content: "";
}
.mbo_talk .frm_item .frm_dl dd.detail .tx {
	display: block;
	font-size: 13px;
	word-break: break-all;
	word-wrap: break-word;
}
.mbo_talk .frm_item .frm_dl dd.detail .tx em {
	color: #947171;
}
.mbo_talk .frm_item .frm_dl dd.detail .price {
	display: block;
}
.mbo_talk .frm_item .frm_dl dd.detail .price em {
	font-size: 17px;
	font-family: Avenir-Medium;
}
.mbo_talk .frm_item .frm_dl dd.detail .price .won {
	font-size: 11px;
	letter-spacing: -1px;
}
.mbo_talk .frm_desc {
	background-color: #f5f5f5;
	margin: 0 15px 10px;
}
.mbo_talk .frm_desc ul {
	padding: 10px;
}
.mbo_talk .frm_desc ul > li {
	color: #222;
	font-size: 13px;
	letter-spacing: -0.5px;
	margin: 4px 0;
}

.mbo_talk .stit {
	padding: 0 15px;
	background: #f3f3f3;
	font-size: 12px;
	font-weight: normal;
	letter-spacing: -1px;
	line-height: 28px;
}
.mbo_talk .stit strong {
	font-weight: normal;
}
.mbo_talk .stit .cnt {
	font-family: "Avenir-Medium";
}
.mbo_talk .stit .ic_required {
	width: 5px;
	height: 5px;
	margin-left: 2px;
	background-position: -15px -100px;
	vertical-align: 4px;
}

.mbo_talk .instruction {
	padding: 20px 15px;
	background-color: #e5e5e5;
	text-align: center;
	letter-spacing: -0.5px;
}
.mbo_talk .instruction .tit {
	font-size: 16px;
	font-weight: bold;
	line-height: 16px;
	color: #555;
}
.mbo_talk .instruction .tit em {
	font-size: 19px;
	font-weight: bold;
	line-height: 16px;
	color: #2dc6c8;
}
.mbo_talk .instruction .tit + .desc {
	margin-top: 7px;
}
.mbo_talk .instruction .desc {
	color: #999;
	font-size: 12px;
	line-height: 16px;
}
.mbo_talk .instruction .desc em {
	color: #2dc6c8;
}
.mbo_talk .instruction .bta {
	padding: 17px 0 18px;
}
.mbo_talk .instruction + .frm {
	padding-top: 0;
}

.mbo_talk .btn {
	display: inline-block;
	padding: 0 15px;
	color: #fff;
	font-size: 17px;
	letter-spacing: -1px;
	line-height: 45px;
	text-align: center;
	border-radius: 3px;
	box-sizing: border-box;
}
.mbo_talk .btn:focus,
.btn:visited,
.btn:active {
	color: #fff;
}
.mbo_talk .btn.s {
	font-size: 14px;
	line-height: 38px;
}
.mbo_talk .btn.color1 {
	background: #2dc6c8;
}
.mbo_talk .btn.color2 {
	background: #606367;
}
.mbo_talk .btn.color3 {
	background: #76c2a8;
}
.mbo_talk .btn.color4 {
	background: #222;
	color: #e2e2e3;
}
.mbo_talk .btn.color5 {
	background: #ff6967;
}
.mbo_talk .btn.color6 {
	border: 1px solid #555;
	background: #fff;
	line-height: 43px;
	color: #555;
}
.mbo_talk .btn.s.color6 {
	line-height: 36px;
}
.mbo_talk .btn:disabled {
	border: 1px solid #f1f1f1;
	background: #f5f5f5;
	color: #e2e2e3;
}

.mbo_talk .btn_area {
	display: -webkit-box;
	-webkit-box-align: center;
	-webkit-box-pack: justify;
	-webkit-box-orient: horizontal;
	width: 100%;
	padding: 20px 15px;
	box-sizing: border-box;
}
.mbo_talk .btn_area .btn {
	display: block;
	-webkit-box-flex: 1;
	width: 100%;
	margin: 0 3px;
}
.mbo_talk .btn_area .btn:first-child {
	margin-left: 0;
}
.mbo_talk .btn_area .btn:last-child {
	margin-right: 0;
}

.mbo_talk .switcher {
	display: inline-block;
	position: relative;
	width: 55px;
	height: 26px;
	background: #222;
	border-radius: 13px;
	-webkit-appearance: none !important;
	vertical-align: middle;
}
.mbo_talk .switcher:before {
	content: "OFF";
	position: absolute;
	top: 0;
	right: 6px;
	color: #fff;
	font-family: "Avenir-Medium", sans-serif;
	font-size: 10px;
	line-height: 26px;
}
.mbo_talk .switcher:after {
	content: "";
	overflow: hidden;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 2px;
	z-index: 1;
	width: 22px;
	height: 22px;
	border-radius: 11px;
	background: #fff;
	-webkit-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
}
.mbo_talk .switcher:checked {
	background: #98d3e6 !important;
}
.mbo_talk .switcher:checked:before {
	content: "ON";
	left: 8px;
}
.mbo_talk .switcher:checked:after {
	left: 100%;
	margin-left: -24px;
}
.mbo_talk .switcher:checked + .switcher_content {
	display: block;
}
.mbo_talk .switcher_content {
	display: none;
	padding-bottom: 20px;
}

.mbo_talk .nodata {
	padding: 115px 0;
	color: #c6c6c8;
	font-size: 15px;
	text-align: center;
}

.mbo_talk .cm_stly {
	border-top: 1px solid #28b2b4;
	background: #2dc6c8;
}
.mbo_talk .err {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 90%;
	text-align: center;
	transform: translate(-50%, -50%);
}
.mbo_talk .err .ic_error {
	width: 58px;
	height: 52px;
	margin-bottom: 22px;
	background-position: -60px -190px;
}
.mbo_talk .err .tx {
	padding-bottom: 40px;
	font-size: 17px;
	letter-spacing: -1px;
	line-height: 23px;
	word-break: break-all;
	word-wrap: break-word;
}

/* 셀러톡 이용약관 */
.mbo_talk .termsofuse_warp {
	margin: 15px 15px 0;
}
.mbo_talk .termsofuse_warp .termsofuse_area {
	position: relative;
	height: 400px;
	margin-bottom: 25px;
	border: 1px solid #e6e6e6;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}
.mbo_talk .termsofuse_warp .termsofuse_area iframe {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}
.mbo_talk .termsofuse_warp .termsofuse_info {
	letter-spacing: -1px;
	line-height: 1.3;
	font-size: 14px;
	font-weight: bold;
	color: #999;
}
.mbo_talk .termsofuse_warp .termsofuse_date {
	letter-spacing: -1px;
	font-size: 13px;
	color: #999;
}
.mbo_talk .termsofuse_warp + .btn_area {
	margin-top: -5px;
}
/* 셀러톡 프로필 설정 */
.mbo_talk .pf_cover {
	position: relative;
	height: 193px;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
}
.mbo_talk .pf_cover:before {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.15);
	content: "";
}
.mbo_talk .pf_cover p {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	padding: 15px 60px 0 13px;
	color: #fff;
	font-size: 17px;
	letter-spacing: -1px;
	line-height: 22px;
	text-align: left;
	word-break: keep-all;
	word-wrap: break-word;
}
.mbo_talk .pf_cover .btn_cover {
	position: absolute;
	bottom: 13px;
	right: 15px;
	padding: 0 8px;
	border-radius: 5px;
	background: #fff;
	color: #222;
	font-size: 12px;
	letter-spacing: -0.5px;
	line-height: 30px;
}
.mbo_talk .pf_info {
	text-align: center;
}
.mbo_talk .pf_info .thmb {
	position: relative;
	width: 96px;
	height: 96px;
	margin: -48px auto 0;
}
.mbo_talk .pf_info .thmb .img {
	display: block;
	overflow: hidden;
	height: 100%;
	border-radius: 48px;
}
.mbo_talk .pf_info .thmb .img img {
	width: 100%;
	height: 100%;
}
.mbo_talk .pf_info .thmb .ic_live {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 26px;
	height: 26px;
	background-position: -100px -25px;
}
.mbo_talk .pf_info .thmb .btn_addfile {
	position: absolute;
	right: -17px;
	bottom: -10px;
}
.mbo_talk .pf_info h2 {
	margin-top: 5px;
	color: #222;
	font-size: 20px;
	font-weight: normal;
}
.mbo_talk .pf_info h2 .ic_edit {
	width: 13px;
	height: 13px;
	background-position: -75px 0;
	vertical-align: 2px;
}
.mbo_talk .profile_seller_info {
	position: relative;
	padding: 15px 0 5px 0;
	text-align: center;
}
.mbo_talk .profile_seller_info .summary {
	position: absolute;
	right: 15px;
	top: -42px;
	font-size: 14px;
	letter-spacing: -1px;
	color: #999;
}
.mbo_talk .profile_seller_info .store_name {
	display: block;
}
.mbo_talk .profile_seller_info .store_name .store_name_txt {
	font-size: 20px;
	font-weight: bold;
	line-height: 1;
	letter-spacing: -1px;
	color: #555;
}
.mbo_talk .profile_seller_info .store_location {
	display: block;
	margin-top: 2px;
	font-size: 14px;
	font-weight: bold;
	line-height: 1;
	letter-spacing: -1px;
	color: #999;
}
.mbo_talk .profile_seller_info .store_category_wrap {
	height: 18px;
	margin-top: 5px;
}
.mbo_talk .profile_seller_info .store_category {
	display: inline-block;
	vertical-align: top;
	height: 16px;
	padding: 0 5px;
	border: 1px solid #555;
	border-radius: 10px;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: -1px;
}
.mbo_talk .profile_seller_info .store_status_wrap {
	margin-top: 20px;
	font-size: 14px;
	font-weight: bold;
	line-height: 1;
	color: #555;
}
.mbo_talk .profile_seller_info .btn_area .btn {
	margin: 0 1px;
}
.mbo_talk .profile_seller_info .btn_area .btn:last-child {
	margin-left: 6px;
}
/* 상담시간 관리 */
.mbo_talk .weekday_list {
	padding: 0 0 15px 30px;
}
.mbo_talk .weekday_list:after {
	display: block;
	clear: both;
	content: "";
}
.mbo_talk .weekday_list > li {
	float: left;
	margin: 15px 15px 0 0;
}
.mbo_talk .weekday_list .day_chk input {
	position: absolute;
	left: -100px;
}
.mbo_talk .weekday_list .day_chk input:checked + label {
	border-color: #2dc6c8;
	background: #2dc6c8;
}
.mbo_talk .weekday_list .day_chk input:checked + label em {
	color: #fff;
}
.mbo_talk .weekday_list .day_chk input:checked + label .ic_check {
	display: block;
}
.mbo_talk .weekday_list .day_chk label {
	display: block;
	position: relative;
	width: 47px;
	height: 47px;
	border: 2px solid #d6dbdd;
	border-radius: 26px;
}
.mbo_talk .weekday_list .day_chk label em {
	display: block;
	color: #222;
	font-size: 18px;
	line-height: 47px;
	text-align: center;
}
.mbo_talk .weekday_list .day_chk .ic_check {
	display: none;
	position: absolute;
	top: -2px;
	right: -2px;
	width: 22px;
	height: 17px;
	background-position: -120px -75px;
}
/* 셀러톡 카테고리 선택 */
.mbo_talk .sel_list > li {
	position: relative;
	border-bottom: 1px solid #f5f5f5;
	text-align: center;
}
.mbo_talk .sel_list .link {
	display: inline-block;
	position: relative;
}
.mbo_talk .sel_list .tx {
	padding: 17px 30px 18px;
	color: #222;
	font-size: 17px;
	word-wrap: break-word;
	word-wrap: break-word;
}
.mbo_talk .sel_list .ic_arr {
	display: none;
}
.mbo_talk .sel_list .link.active .tx {
	color: #2dc6c8;
}
.mbo_talk .sel_list .link.active .ic_arr {
	display: block;
	position: absolute;
	top: 50%;
	transform: translateY(-75%);
	right: 0;
	width: 22px;
	height: 17px;
	background-position: -120px -94px;
}
.mbo_talk .sel_list_ty {
	margin: 15px 15px 0;
	border-top: 1px solid #e5e5e5;
	border-left: 1px solid #e5e5e5;
}
.mbo_talk .sel_list_ty:after {
	display: block;
	clear: both;
	content: "";
}
.mbo_talk .sel_list_ty > li {
	position: relative;
	float: left;
	width: 25%;
	border-right: 1px solid #e5e5e5;
	border-bottom: 1px solid #e5e5e5;
	box-sizing: border-box;
}
.mbo_talk .sel_list_ty .link {
	display: block;
	width: 100%;
	height: 100%;
}
.mbo_talk .sel_list_ty .tx {
	padding: 14px 0;
	line-height: 17px;
	text-align: center;
	font-size: 14px;
	font-weight: bold;
	letter-spacing: -1px;
	color: #555;
}
.mbo_talk .sel_list_ty .ic_arr {
	display: none;
}
.mbo_talk .sel_list_ty .link.active .tx {
	color: #2dc6c8;
}
.mbo_talk .sel_list_ty .link.active .ic_arr {
	display: block;
	position: absolute;
	top: 2px;
	right: 1px;
	width: 23px;
	height: 23px;
	background-position: -155px -50px;
}
/* 셀러멤버 관리 */
.mbo_talk .member_lst_wrap {
	margin: 15px 15px 0;
}
.mbo_talk .member_lst_wrap .no_member {
	padding: 35px 0;
	text-align: center;
	line-height: 1.2;
	font-size: 14px;
	color: #999;
}
.mbo_talk .member_lst li {
	position: relative;
	border-bottom: 1px solid #e5e5e5;
}
.mbo_talk .member_lst .member_num {
	line-height: 19px;
	font-size: 15px;
	font-weight: bold;
	letter-spacing: -0.5px;
	color: #555;
}
.mbo_talk .member_lst .reg_date {
	position: absolute;
	right: 0;
	top: 3px;
	line-height: 1;
	font-size: 12px;
	letter-spacing: -0.5px;
	color: #999;
}
.mbo_talk .member_lst .member_info {
	width: 100%;
	table-layout: fixed;
	margin-top: 6px;
	border-top: 1px dashed #e5e5e5;
}
.mbo_talk .member_lst .member_info th {
	width: 35%;
	padding: 16px 0 15px 10px;
	border-bottom: 1px dashed #e5e5e5;
	background-color: #f7f7f7;
	line-height: 19px;
	font-size: 14px;
	letter-spacing: -0.5px;
	color: #555;
}
.mbo_talk .member_lst .member_info td {
	padding: 16px 0 15px 10px;
	border-bottom: 1px dashed #e5e5e5;
	background-color: #f7f7f7;
	line-height: 19px;
	font-size: 14px;
	letter-spacing: -0.5px;
	color: #555;
}
.mbo_talk .member_lst .btn_member_wrap {
	padding: 15px 0 14px;
	text-align: right;
	font-size: 0;
}
.mbo_talk .member_lst .btn_member_wrap .btn_member {
	display: inline-block;
	margin: 0 2px;
	padding: 0 7px;
	border: 1px solid #555;
	box-sizing: border-box;
	font-size: 14px;
	letter-spacing: -1px;
	line-height: 30px;
	text-align: center;
	color: #555;
}
.mbo_talk .member_lst .btn_member_wrap .btn_member.primary {
	background-color: #555;
	color: #fff;
}
/* mbo에 셀러톡 컨텐츠 노출시 mo.css 기본스타일 리셋 : .content 구조에 .mbo_talk 추가 */
.mbo_talk .btn_area {
	margin-top: 0;
}
.mbo_talk .btn {
	font-weight: normal;
}
.mbo_talk .inp[readonly] {
	background: #fff;
}
.mbo_talk .inp:focus {
	border: 0 none;
	border-bottom: 2px solid #222;
}
/*** mbo 쓱톡페이지 (e) ***/

/* mbo 사내 핫라인(s) */
.mbo_accept {
	background: #f7f7f7;
	margin-bottom: -70px;
	padding-bottom: 30px;
}
.mbo_accept .accept_wrap {
	margin: 0 15px;
}
.mbo_accept .accept_step {
	position: relative;
	height: 46px;
	margin: 0 -15px 10px;
	border-bottom: 1px solid #d1d1d1;
	background: #fff;
	color: #888;
	font-size: 16px;
	font-weight: 600;
	letter-spacing: -1.3px;
	line-height: 46px;
	text-align: center;
}
.mbo_accept .accept_step .on {
	color: #555;
}
.mbo_accept .accept_step .on .color {
	color: #2dc6c8;
}
.mbo_accept .accept_step .ico_arr {
	display: inline-block;
	width: 11px;
	height: 20px;
	margin: 0 12px;
	background: url(../img/comm_ico.png) no-repeat -31px -30px;
	background-size: 320px auto;
	vertical-align: middle;
}
.mbo_accept .accept_term_area {
	position: relative;
	height: 400px;
	margin-bottom: 18px;
	border: 1px solid #e6e6e6;
	background: #fff;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}
.mbo_accept .accept_term_area iframe {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}
.mbo_accept .accept_form_area .section2 {
	background: none;
}
.mbo_accept .accept_form_area .author_wrap {
	position: relative;
	padding-right: 55px;
}
.mbo_accept .accept_form_area .author_wrap .fld_area {
	position: absolute;
	top: 0;
	right: 0;
	height: 43px;
	line-height: 43px;
}
.mbo_accept .accept_form_area .email_wrap {
	display: table;
	width: 100%;
	table-layout: fixed;
}
.mbo_accept .accept_form_area .email_wrap .cell {
	display: table-cell;
	padding: 0 2px;
	vertical-align: top;
}
.mbo_accept .accept_form_area .email_wrap .cell:first-child {
	padding-left: 0;
}
.mbo_accept .accept_form_area .email_wrap .cell:last-child {
	padding-right: 0;
}
.mbo_accept .accept_form_area .email_wrap .email_domain {
	position: relative;
	padding-left: 16px;
}
.mbo_accept .accept_form_area .email_wrap .email_domain .email_at {
	position: absolute;
	top: 0;
	left: 0;
	height: 43px;
	line-height: 43px;
	text-align: center;
}
.mbo_accept .accept_form_area .target_info li {
	position: relative;
	margin-top: 5px;
	padding-left: 55px;
}
.mbo_accept .accept_form_area .target_info li:first-child {
	margin-top: 0;
}
.mbo_accept .accept_form_area .target_info .lb {
	position: absolute;
	top: 0;
	left: 0;
	height: 43px;
	color: #555;
	font-size: 14px;
	letter-spacing: -1px;
	line-height: 43px;
}
.mbo_accept .accept_form_area .selectric .label {
	color: #555;
}
.mbo_accept .accept_form_area .line {
	position: relative;
	height: 1px;
	margin: 7px -15px 6px;
	background: #c7c7c7;
}
.mbo_accept .accept_wrap .accept_form_area .file_attach {
	position: relative;
	padding-right: 90px;
}
.mbo_accept .accept_wrap .accept_form_area .file_attach .inp[type="text"] {
	height: 45px;
	padding-right: 6px;
	color: #888 !important;
	font-size: 13px;
}
.mbo_accept .accept_wrap .accept_form_area .file_attach button {
	position: absolute;
	top: 0;
	right: 0;
	width: 91px;
	font-size: 14px;
}
.mbo_accept .btn_area {
	display: -webkit-box;
	-webkit-box-align: center;
	-webkit-box-pack: justify;
	-webkit-box-orient: horizontal;
	width: 100%;
	margin: 0;
	padding: 20px 15px;
	box-sizing: border-box;
}
.mbo_accept .btn_area .btn {
	display: block;
	-webkit-box-flex: 1;
	width: 100%;
	margin: 0 3px;
}
.mbo_accept .btn_area .btn:first-child {
	margin-left: 0;
}
.mbo_accept .btn_area .btn:last-child {
	margin-right: 0;
}
.mbo_accept .btn {
	display: inline-block;
	padding: 0 15px;
	color: #fff;
	font-size: 17px;
	letter-spacing: -1px;
	line-height: 44px;
	text-align: center;
	box-sizing: border-box;
}
.mbo_accept .btn_submit {
	border: 1px solid #555;
	background: #555;
}
.mbo_accept .btn_cancel {
	border: 1px solid #888;
	color: #555;
}
.mbo_accept .fld_chk .chk {
	background-position: -35px -51px;
}
.mbo_accept .fld_chk .chk[type="checkbox"]:checked {
	background-position: -35px -70px;
}
.mbo_accept .btnA {
	font-size: 14px;
}
/* mbo 사내 핫라인(e) */

/* 배송완료 사진 등록 레이어팝업 */
.modal_deliv_status {
	margin: 21px 0 20px;
}
.modal_deliv_status p {
	margin: 0 15px;
	color: #222;
	font-size: 20px;
	text-align: center;
}
.modal_deliv_area {
	padding: 15px 15px 20px;
	border-top: 8px solid #e5e5e5;
}
.modal_deliv_txt {
	margin-bottom: 15px;
	color: #222;
	font-size: 18px;
	line-height: 1.44;
	text-align: center;
}
.modal_deliv_btnarea {
	margin: 0 -1px 25px;
}
.modal_deliv_btnarea ul {
	display: table;
	width: 100%;
	margin: 15px -1px 0;
	table-layout: fixed;
}
.modal_deliv_btnarea li {
	display: table-cell;
	width: 100%;
	padding: 0 1px;
}
.modal_deliv_btnarea .modal_deliv_btn {
	position: relative;
	display: block;
	width: 100%;
	min-height: 46px;
	border: 1px solid #222;
	box-sizing: border-box;
	color: #222;
	font-size: 15px;
	letter-spacing: -0.6px;
	text-align: center;
}
.modal_deliv_btnarea .modal_deliv_btn input {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	z-index: 5;
}
.modal_deliv_btnarea .modal_deliv_complete {
	display: block;
	width: 100%;
	min-height: 46px;
	background: #555;
	box-sizing: border-box;
	color: #fff;
	font-size: 15px;
	letter-spacing: -0.6px;
	text-align: center;
}
.modal_deliv_btnarea .modal_deliv_btn span {
	position: relative;
	padding-left: 28px;
	line-height: 46px;
}
.modal_deliv_btnarea .modal_deliv_btn span:before {
	position: absolute;
	top: 50%;
	left: 0;
	width: 20px;
	height: 14px;
	margin-top: -7px;
	background: url(../img/sp_deliv_btn.png) no-repeat;
	background-size: 20px auto;
	content: "";
}
.modal_deliv_btnarea .modal_deliv_btn.btn_photo span:before {
	background-position: 0 100%;
}
.modal_deliv_btnarea .modal_deliv_btn.btn_album span:before {
	background-position: 0 0;
}
.modal_deliv_chk {
	margin-bottom: 20px;
}
.modal_deliv_slct {
	margin-bottom: 25px;
}
.modal_deliv_photo {
	margin-bottom: 25px;
}
.modal_deliv_photo .img_area {
	position: relative;
	display: inline-block;
	width: 146px;
	height: 146px;
	text-align: center;
	vertical-align: top;
}
.modal_deliv_photo .img_area img {
	width: 146px;
	height: 146px;
	vertical-align: top;
}
.modal_deliv_photo .img_area .btn_dlt {
	position: absolute;
	right: 10px;
	top: 10px;
	width: 30px;
	height: 30px;
	background: url(../img/ico_dltpic.png) no-repeat 0 0;
	background-size: 30px 30px;
}
.modal_deliv_decision {
	border-top: 7px solid #e5e5e5;
	text-align: center;
}
.modal_inpbox {
	display: table;
	width: 100%;
	height: 70px;
	padding: 0 15px;
	border-top: 1px solid #e5e5e5;
	table-layout: fixed;
	box-sizing: border-box;
}
.modal_inptit {
	display: table-cell;
	width: 150px;
	font-size: 15px;
	line-height: 20px;
	color: #222;
	font-weight: normal;
	text-align: left;
	vertical-align: middle;
	&.sizeL {
		font-size: 20px;
	}
}
.modal_inpcont {
	display: table-cell;
	vertical-align: middle;
}
.modal_inpcont .modal_deliv_slct {
	margin: 0;
}
.modal_rdlst {
	overflow: hidden;
}
.modal_rdlst li {
	float: left;
	margin-right: 20px;
}
.modal_rdlst li:last-child {
	margin-right: 0;
}
.modal_rd {
	position: relative;
	display: inline-block;
	min-height: 20px;
	vertical-align: top;
}
.modal_rd input {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	width: 20px;
	height: 20px;
	border: 0;
	opacity: 0;
	background: none;
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
}
.modal_rd label {
	display: inline-block;
	position: relative;
	padding-left: 25px;
	font-size: 15px;
	line-height: 20px;
	color: #222;
}
.modal_rd label:before {
	position: absolute;
	top: 0;
	left: 0;
	width: 18px;
	height: 18px;
	border: 1px solid #969696;
	border-radius: 10px;
	background: #fff;
	content: "";
}
.modal_rd input:checked + label:before {
	border-color: #ff5b59;
}
.modal_rd input:checked + label:after {
	position: absolute;
	top: 5px;
	left: 5px;
	width: 10px;
	height: 10px;
	border-radius: 5px;
	background: #ff5b59;
	content: "";
}
.modal_rd input:disabled + label:before {
	border-color: #d1d1d1;
	background-color: #f5f5f5;
}
.modal_rd input:disabled + label {
	color: #d1d1d1;
}
.modal_deliv_imgbx {
	padding: 15px 15px 0;
	text-align: center;
}
.modal_deliv_imgbx img {
	width: 100%;
	max-width: 750px;
	vertical-align: top;
}
.modal_deliv_imgbx + .evt_case05 .modal_inpbox:nth-of-type(1) {
	border-top: 0;
}
.modal_deliv_noticebx {
	margin-bottom: 20px;
	padding: 0 15px;
}
.modal_deliv_notice {
	padding: 8px 0 6px;
	background: #f5f5f5;
	font-size: 13px;
	font-weight: 500;
	color: #888;
	letter-spacing: -0.5px;
	line-height: 16px;
	text-align: center;
}
.modal_deliv_notice em {
	color: #ff5b59;
}
.deli_product > ul + .modal_deliv_notice {
	margin-top: 10px;
}
.modal_inpbox.ty2 {
	display: block;
	height: auto;
	// padding: 0;
	.modal_inptit {
		display: block;
		width: 100%;
		margin: 0 -15px;
		padding: 20px 15px;
		text-align: center;
		border-bottom: 8px solid #e5e5e5;
	}
	.modal_inpcont {
		display: block;
	}
	.modal_rdlst {
		// overflow: hidden;
	}
	.modal_rdlst li {
		float: none;
		margin: 0;
		padding: 25px 15px;
		&:not(:first-child) {
			border-top: 1px solid #e5e5e5;
		}
	}
}

/* MPO 포스트 고도화 */
.ico {
	display: block;
	position: absolute;
	top: 9px;
	right: 0;
	left: 0;
	width: 30px;
	height: 27px;
	margin: auto;
	background: url(../img/sp_post.png) no-repeat;
	background-size: 300px auto;
	content: "";
}

.content_post {
	margin-bottom: -10px;
}
.content_post.content_post.post_modify {
	margin-bottom: -24px;
}

.tit_subarea {
	position: relative;
	height: 44px;
	padding: 0 135px 0 15px;
	border-bottom: 1px solid #e5e5e5;
	background: #f7f7f7;
}
.tit_subarea .tit_sub {
	display: inline-block;
	padding-top: 4px;
	color: #222;
	font-size: 16px;
	font-weight: normal;
	vertical-align: top;
	line-height: 40px;
}
.selectric-select.selectric-sty4 {
	display: inline-block;
	width: auto;
}
.tit_subarea .selectric-select.selectric-sty4 {
	position: absolute;
	top: 0;
	right: 15px;
	max-width: 120px;
}
.selectric-select.selectric-sty4 .selectric {
	border: none;
	background: none;
}
.selectric-select.selectric-sty4 .selectric .label {
	margin-right: 15px;
	color: #444;
	font-size: 13px;
	line-height: 24px;
	letter-spacing: -0.5px;
}
.selectric-select.selectric-sty4 .selectric:after {
	display: block;
	position: absolute;
	top: 50%;
	right: 0;
	width: 0;
	height: 0;
	margin-top: -3px;
	border-width: 6px 5px;
	border-style: solid;
	border-color: #666 transparent transparent;
	background: none;
	content: "";
}
.tit_subarea .btn_cm.regist_guide {
	position: absolute;
	top: 0;
	right: 0;
	height: 44px;
	padding: 16px 15px 13px;
	line-height: 15px;
}

.lst_post_header {
	position: relative;
	height: 44px;
	padding: 0 100px 0 15px;
	border-bottom: 1px solid #e5e5e5;
}
.lst_post_header .btn_post_util {
	display: block;
	float: left;
	position: relative;
	padding: 2px 9px 0;
	margin: 10px 0 10px 10px;
	border: 1px solid #888;
	border-radius: 12px;
	color: #222;
	font-size: 13px;
	line-height: 20px;
	letter-spacing: -0.5px;
	vertical-align: middle;
}
.lst_post_header .btn_post_util:first-child {
	margin-left: 0;
}
.lst_post_header .btn_post_cancel {
	position: absolute;
	top: 0;
	right: 0;
	padding: 0 15px;
	color: #222;
	font-size: 16px;
	line-height: 44px;
}
.lst_post_header .ctn_post {
	position: absolute;
	top: 0;
	right: 15px;
	color: #222;
	font-size: 13px;
	line-height: 44px;
}
.lst_post_header .fld_chk label {
	padding-top: 0;
	color: #222;
	font-size: 16px;
	line-height: 44px;
}
.lst_post_header .inner:first-child {
	display: block;
}
.lst_post_header .inner:last-child {
	display: none;
}
.content_post.post_modify .lst_post_header .inner:first-child {
	display: none;
}
.content_post.post_modify .lst_post_header .inner:last-child {
	display: block;
}
.lst_post_condition {
	display: table;
	position: relative;
	width: 100%;
	padding: 14px 15px;
	border-bottom: 1px solid #e5e5e5;
	box-sizing: border-box;
}
.lst_post_condition .condition_tit {
	display: table-cell;
	color: #444;
	font-size: 13px;
	letter-spacing: -0.5px;
}
.lst_post_condition .condition_cont {
	display: table-cell;
	text-align: right;
}
.lst_post_condition .inp.pickadate {
	width: 97px;
	height: 28px;
	padding: 0;
	border-color: #e5e5e5;
	color: #222;
	font-size: 15px;
	line-height: 26px;
	text-align: center;
}
.lst_post_condition .mark {
	display: inline-block;
	vertical-align: middle;
}
@media screen and (max-width: 320px) {
	.lst_post_condition .inp.pickadate {
		width: 70px;
		font-size: 13px;
		letter-spacing: -0.5px;
	}
}
.lst_post_content li {
	position: relative;
	padding: 20px 15px;
	border-bottom: 1px solid #e5e5e5;
}
.lst_post_content li a {
	display: block;
	overflow: hidden;
	position: relative;
	min-height: 80px;
	padding: 4px 0 0 94px;
}
.lst_post_content li a .thumb {
	position: absolute;
	top: 0;
	left: 0;
	width: 82px;
	height: 82px;
	border: 1px solid #e5e5e5;
}
.lst_post_content li a .thumb img {
	width: 100%;
	height: 100%;
	vertical-align: top;
}
.lst_post_content li a .date {
	display: block;
	color: #adadad;
	font-size: 13px;
}
.lst_post_content li a .tit {
	display: block;
	overflow: hidden;
	margin-top: 2px;
	color: #222;
	font-size: 16px;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.lst_post_content li a .desc {
	display: block;
	overflow: hidden;
	max-height: 32px;
	margin-top: 2px;
	color: #888;
	font-size: 13px;
	line-height: 16px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
.lst_post_content.ty_claim li a .desc {
	max-height: initial;
	margin-top: 7px;
	color: #222;
	line-height: 16px;
	-webkit-line-clamp: inherit;
	-webkit-box-orient: vertical;
}
.lst_post_content .closed a .date,
.lst_post_content .closed a .tit,
.lst_post_content .closed a .desc {
	color: #c3c3c3;
}
.lst_post_content .fld_chk {
	float: left;
	padding-right: 10px;
}
.lst_post_content .fld_chk .chk {
	vertical-align: top;
}
.lst_post_content .fld_chk label {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 0;
}
.lst_post_content li .state {
	position: absolute;
	top: 20px;
	right: 15px;
}
.lst_post_content li .state span {
	display: block;
	height: 16px;
	padding: 2px 5px 0;
	border: 1px solid #ff6967;
	color: #ff6967;
	font-size: 13px;
	letter-spacing: -0.5px;
	vertical-align: top;
	line-height: 16px;
}
.lst_post_content li.complete .state span {
	border-color: #2dc6c8;
	color: #2dc6c8;
}
.lst_post_content li .state .on {
	display: none;
}
.lst_post_content li.complete .state .on {
	display: block;
}
.lst_post_content li.complete .state .off {
	display: none;
}

.lst_post_content .fld_chk,
.content_post.post_modify .lst_post_content .selectric-select.selectric-sty5 {
	display: none;
}
.content_post.post_modify .lst_post_content .fld_chk,
.lst_post_content .selectric-select.selectric-sty5 {
	display: block;
}

.selectric-select.selectric-sty5 {
	display: inline-block;
	position: absolute;
	top: 20px;
	right: 15px;
	width: auto;
}
.selectric-select.selectric-sty5 .selectric {
	border: 1px solid #2dc6c8;
	background: none;
}
.selectric-select.selectric-sty5 .selectric .label {
	margin-right: 20px;
	margin-left: 5px;
	padding: 2px 0 0;
	color: #2dc6c8;
	font-size: 13px;
	line-height: 16px;
	letter-spacing: -0.5px;
}
.selectric-select.selectric-sty5 .selectric:after {
	display: block;
	position: absolute;
	top: 50%;
	right: 5px;
	width: 0;
	height: 0;
	margin-top: -2px;
	border-width: 6px 5px;
	border-style: solid;
	border-color: #2dc6c8 transparent transparent;
	background: none;
	content: "";
}
.closed .selectric-select.selectric-sty5 .selectric {
	border: 1px solid #888;
}
.closed .selectric-select.selectric-sty5 .selectric .label {
	color: #888;
}
.closed .selectric-select.selectric-sty5 .selectric:after {
	border-color: #888 transparent transparent;
}

.content_post + .footer {
	position: fixed;
	bottom: 0;
	right: 0;
	left: 0;
	padding: 0;
	background: #f9f9f9;
	z-index: 20;
}
.content_post + .footer .btn_area {
	display: none;
}
.content_post + .footer .btn_area .btn {
	display: block;
	width: 100%;
	height: 46px;
	color: #fff;
	font-size: 15px;
	font-weight: normal;
	background: #ff6967;
}
.content_post + .footer .btn_area .btn:disabled {
	color: #e2e2e3;
	background: #f5f5f5;
}
.content_post.post_modify + .footer .nav {
	display: none;
}
.content_post.post_modify + .footer .btn_area {
	display: block;
	margin-top: 0;
}

.footer .nav ul {
	display: table;
	width: 100%;
	table-layout: fixed;
}
.footer .nav li {
	display: table-cell;
	position: relative;
	width: 25%;
}
.footer .nav li.active:after {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 4px;
	background: #2dc6c8;
	content: "";
}
.footer .nav li a {
	display: block;
	height: 60px;
	padding-top: 39px;
	color: #888;
	font-size: 12px;
	box-sizing: border-box;
	text-align: center;
}
.footer .nav li.active a {
	color: #2dc6c8;
}
.footer .nav li strong {
	font-weight: normal;
}
.footer .nav li .push {
	display: none;
	position: absolute;
	top: 1px;
	left: 50%;
	min-width: 10px;
	height: 20px;
	margin-left: 3px;
	padding: 0 5px;
	border-radius: 10px;
	background: #ff4150;
	color: #fff;
	line-height: 20px;
}
.footer .nav .ico_chatlist {
	background-position: 0 0;
}
.footer .nav .ico_state {
	background-position: -30px 0;
}
.footer .nav .ico_store {
	background-position: -60px 0;
}
.footer .nav .ico_claim {
	background-position: -90px 0;
}
.footer .nav .ico_setting {
	background-position: -120px 0;
}
.footer .nav .ico_library {
	background-position: -150px 0;
}
.footer .nav .ico_photo {
	background-position: -180px 0;
}
.footer .nav .ico_video {
	background-position: -210px 0;
}
.footer .nav .ico_add_prod {
	background-position: -240px 0;
}
.footer .nav .ico_sort {
	background-position: -270px 0;
}
.footer .nav .active .ico {
	background-position-y: -30px;
}

.post_reg_area .reg_guide {
	position: relative;
	height: 63px;
	margin: 0 15px;
	border-bottom: 1px solid #e5e5e5;
}
.post_reg_area .reg_guide:after {
	display: inline-block;
	width: 1px;
	height: 100%;
	margin-left: -1px;
	vertical-align: middle;
	content: "";
}
.post_reg_area .reg_guide_inner {
	display: inline-block;
	vertical-align: middle;
}
.post_reg_area .reg_guide_tit {
	display: inline-block;
	margin-right: 5px;
	color: #2dc6c8;
	font-size: 15px;
	font-weight: bold;
	vertical-align: top;
}
.post_reg_area .reg_guide_sub {
	display: inline-block;
	padding-top: 2px;
	color: #222;
	font-size: 13px;
	vertical-align: top;
	line-height: 17px;
}
.post_reg_area .reg_media_bx {
	margin: 0 15px 30px;
}
.post_reg_area .reg_guide + .reg_media_bx {
	margin-top: -1px;
}
.post_reg_area .preview_bx {
	position: relative;
	width: 100%;
}
.post_reg_area .preview_bx label {
	position: absolute;
	top: 10px;
	left: 10px;
	min-width: 44px;
	height: 28px;
	padding: 0 5px;
	border: 1px solid #fff;
	background: rgba(34, 34, 34, 0.15);
	color: #fff;
	font-size: 13px;
	line-height: 28px;
	text-align: center;
}
.post_reg_area .preview_bx label:before {
	display: inline-block;
	width: 11px;
	height: 8px;
	margin: 0 4px 4px 0;
	background: url(../img/comm_btn.png) no-repeat -139px -65px;
	background-size: 320px auto;
	vertical-align: middle;
	content: "";
}
.post_reg_area .preview_bx input[type="radio"] {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 1px;
	height: 1px;
	border: 0;
	background: none;
	-webkit-appearance: none;
	appearance: none;
}
.post_reg_area .preview_bx input[type="radio"]:checked + label {
	display: block;
	border-color: #2dc6c8;
	background: #2dc6c8;
}
.post_reg_area .preview_bx input[type="radio"]:checked + label:before {
	display: none;
}
.post_reg_area .preview_bx.is_selected:after {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 3px solid #2dc6c8;
	box-sizing: border-box;
	content: "";
}
.post_reg_area .preview_bx.is_selected label {
	display: block;
	z-index: 10;
}
.post_reg_area .preview_bx img {
	width: 100%;
	height: auto;
	vertical-align: top;
}
/* .post_reg_area .reg_media_bx.ty_video .preview_bx:before{display:block;position:absolute;top:50%;left:50%;width:60px;height:60px;margin:-30px 0 0 -30px;background:url(../img/comm_btn.png) no-repeat -260px -73px;background-size:320px auto;content:''} */
.post_reg_area .inp_bx .inp_wrap {
	display: block;
	width: 100%;
}
.post_reg_area .inp_bx .inp {
	display: block;
	width: 100%;
	height: 45px;
	margin-top: 10px;
	padding: 12px 19px;
	border: 1px solid #e5e5e5;
}
.post_reg_area .inp_bx .ta_wrap {
	display: block;
	position: relative;
	min-height: 19px;
	margin-top: 10px;
	padding: 12px 19px;
	border: 1px solid #e5e5e5;
}
.post_reg_area .inp_bx .ta {
	display: block;
	width: 100%;
	height: 19px;
	overflow: visible;
	border: none;
	line-height: 19px;
	resize: none;
}
.post_reg_area + .btn_area {
	margin: 30px 0 40px;
	padding: 0 15px;
	box-sizing: border-box;
}
.post_reg_area + .btn_area button {
	font-size: 15px;
	font-weight: normal;
}

.lst_reg li {
	display: table;
	position: relative;
	width: 100%;
	min-height: 84px;
	padding: 20px 15px;
	border-bottom: 1px solid #e5e5e5;
	background: #fff;
	box-sizing: border-box;
	table-layout: fixed;
}
.lst_reg .sortable-chosen {
	border: 3px solid #2dc6c8;
	padding: 17px 12px 18px;
	box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.3) !important;
	z-index: 10;
}
.lst_reg .ghost {
	opacity: 1;
}
.lst_reg .thumb {
	display: table-cell;
	width: 94px;
	vertical-align: middle;
}
.lst_reg .thumb .img {
	display: block;
	width: 82px;
	height: 82px;
	border: 1px solid #e5e5e5;
}
.lst_reg .thumb img {
	width: 100%;
	height: 100%;
	vertical-align: top;
}
.lst_reg .info {
	display: table-cell;
	vertical-align: middle;
}
.lst_reg .info .tit {
	display: block;
	overflow: hidden;
	max-height: 40px;
	color: #222;
	font-size: 16px;
	line-height: 20px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
.lst_reg .info .price {
	display: block;
	color: #888;
	font-size: 13px;
}
.lst_reg .info .desc {
	display: block;
	overflow: hidden;
	max-height: 32px;
	color: #888;
	font-size: 13px;
	line-height: 16px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
.lst_reg .info .tit + .desc {
	margin-top: 3px;
}
.lst_reg .del {
	display: table-cell;
	width: 52px;
	text-align: right;
	vertical-align: middle;
}
.lst_reg .handle {
	display: table-cell;
	width: 62px;
	text-align: center;
	vertical-align: middle;
}
.lst_reg .chkarea {
	display: table-cell;
	width: 30px;
	text-align: right;
	vertical-align: middle;
}
.modal .lst_reg .info .tit {
	visibility: visible;
}
.modal .lst_reg .info .desc {
	visibility: visible;
}

.modal-postguide {
	text-align: center;
}
.modal-postguide:after {
	display: inline-block;
	width: 1px;
	height: 100%;
	margin-left: -1px;
	vertical-align: middle;
	content: "";
}
.modal-postguide .inner {
	display: inline-block;
	position: relative;
	max-width: calc(100% - 30px);
	vertical-align: middle;
	text-align: left;
}
.modal-postguide .tx_guide_bx {
	margin: 30px 20px;
	color: #222;
}
.modal-postguide .tx_guide_tit {
	margin-bottom: 8px;
	font-size: 15px;
	font-weight: bold;
}
.modal-postguide .tx_guide_lst li {
	position: relative;
	margin-top: 4px;
	padding-left: 12px;
	font-size: 13px;
	line-height: 18px;
	letter-spacing: -1px;
}
.modal-postguide .tx_guide_lst li:first-child {
	margin-top: 0;
}
.modal-postguide .tx_guide_lst li:before {
	position: absolute;
	top: 6px;
	left: 3px;
	width: 3px;
	height: 3px;
	border-radius: 50%;
	background: #222;
	content: "";
}
.modal-postguide .tx_guide_lst .tx_explain {
	margin-top: 2px;
	color: #2dc6c8;
	font-size: 12px;
}

.modal.ty_util .inner {
	position: fixed;
	top: 10px;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
}
.modal.ty_util .tit_dep1 {
	padding: 16px 0 15px;
	border-bottom: 1px solid #d2d2d2;
	border-radius: 10px 10px 0 0;
	background: #fff;
	color: #222;
	font-size: 16px;
	letter-spacing: -0.5px;
	line-height: 24px;
}
.modal.ty_util .pop_content {
	position: fixed;
	top: 66px;
	bottom: 0;
	height: auto;
	background: #fff;
}
.modal.ty_util .scroll_area {
	overflow-x: hidden;
	overflow-y: auto;
	position: fixed;
	top: 66px;
	right: 0;
	bottom: 0;
	left: 0;
	-webkit-overflow-scrolling: touch;
}
.modal.ty_util .btn_cm.close {
	width: 55px;
	height: 55px;
	padding: 0;
}
.modal.ty_util .btn_cm.close:before {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 18px;
	height: 18px;
	margin: -9px 0 0 -9px;
	background-position: -172px -65px;
}

.modal-selectprod .pop_content {
	height: auto;
	bottom: 46px !important;
}
.modal-selectprod .prod_srch {
	padding: 8px 15px;
	background: #f5f5f5;
}
.modal-selectprod .prod_srch .inp_wrap {
	display: block;
}
.modal-selectprod .prod_srch .inp {
	width: 100%;
	height: 30px;
	padding: 0 10px;
	border: 0;
	border-radius: 4px;
	color: #222;
	font-size: 13px;
	-webkit-appearance: none;
	appearance: none;
}
.modal-selectprod .prod_srch .inp::-webkit-input-placeholder {
	text-align: center;
}
.modal-selectprod .prod_selected {
	display: none;
	overflow-x: auto;
	overflow-y: hidden;
	height: 70px;
	padding: 15px;
	background: #f5f5f5;
	-webkit-overflow-scrolling: touch;
}
.modal-selectprod .prod_selected.active {
	display: block;
}
.modal-selectprod .lst_selected_prod {
	display: inline-block;
	width: auto;
	white-space: nowrap;
}
.modal-selectprod .lst_selected_prod:after {
	display: block;
	clear: both;
	content: "";
}
.modal-selectprod .lst_selected_prod li {
	display: inline-block;
	position: relative;
	margin-left: 16px;
	vertical-align: top;
}
.modal-selectprod .lst_selected_prod li:first-child {
	margin-left: 0;
}
.modal-selectprod .lst_selected_prod .thumb {
	width: 68px;
	height: 68px;
	border: 1px solid #e5e5e5;
}
.modal-selectprod .lst_selected_prod .thumb img {
	width: 100%;
	height: 100%;
	vertical-align: top;
}
.modal-selectprod .pop_content .scroll_area {
	top: 112px;
	bottom: 46px;
}
.modal-selectprod .pop_content .prod_selected.active + .scroll_area {
	top: 212px;
}
.modal-selectprod .pop_content .no_data {
	height: 100%;
	text-align: center;
}
.modal-selectprod .pop_content .no_data:after {
	display: inline-block;
	width: 1px;
	height: 100%;
	margin-left: -1px;
	vertical-align: middle;
	content: "";
}
.modal-selectprod .pop_content .no_data span {
	display: inline-block;
	color: #c6c6c8;
	font-size: 15px;
	vertical-align: middle;
}
.modal-selectprod .pop_content .btn_area {
	position: fixed;
	bottom: 0;
	right: 0;
	left: 0;
	height: 46px;
	margin-top: 0;
}
.modal-selectprod .pop_content .btn_area .btn {
	height: 46px;
	background: #555;
	color: #fff;
	font-size: 15px;
	font-weight: normal;
	letter-spacing: -0.5px;
}
.modal-selectprod .pop_content .btn_area .btn:disabled {
	background: #f5f5f5;
	color: #e2e2e3;
}

.modal .change_order_guide {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.7);
	z-index: 100;
}
.modal .change_order_guide .img1 {
	position: fixed;
	right: 67px;
	top: 162px;
	width: 69px;
	height: auto;
}
.modal .change_order_guide .img2 {
	position: fixed;
	right: 73px;
	top: 233px;
	width: 12px;
	height: auto;
}
.modal .change_order_guide .tx_explain {
	position: fixed;
	right: 85px;
	top: 239px;
	width: auto;
	padding-left: 15px;
	color: #fff;
	font-size: 13px;
	line-height: 14px;
}
.modal .change_order_guide .fld_chk {
	position: fixed;
	bottom: 15px;
	left: 15px;
}
.modal .change_order_guide .fld_chk label {
	color: #fff;
}

/* 역마진관리 */
.sub_nav {
	border-bottom: 1px solid #d1d1d1;
}
.sub_nav ul {
	display: table;
	width: 100%;
}
.sub_nav li {
	display: table-cell;
	height: 43px;
	line-height: 43px;
	text-align: center;
}
.sub_nav a {
	display: block;
	position: relative;
	height: 100%;
	color: #888;
	font-size: 15px;
}
.sub_nav li.on a {
	color: #222;
	font-weight: bold;
}
.sub_nav li.on a span {
	color: #2dc6c8;
}
.sub_nav a:before {
	display: block;
	position: absolute;
	top: 50%;
	left: 0;
	width: 1px;
	height: 16px;
	margin-top: -8px;
	background: #e5e5e5;
	content: "";
}

.alarm_msg {
	padding: 15px 15px 13px;
	background: #ff6967;
	color: #fff;
	font-size: 13px;
	line-height: 17px;
	letter-spacing: -0.5px;
	text-align: center;
}

.tit_dropdown_head {
	padding: 0 15px;
	border-bottom: 1px solid #e5e5e5;
	background: #f7f7f7;
}
.tit_dropdown_head:after {
	display: block;
	clear: both;
	content: "";
}
.tit_dropdown_btn {
	float: right;
	padding: 10px 0;
	color: #444;
	font-size: 13px;
	line-height: 24px;
}
.tit_dropdown_btn:after {
	display: inline-block;
	margin: 0 0 -2px 5px;
	border-width: 6px 5px;
	border-style: solid;
	border-color: #666 transparent transparent;
	vertical-align: middle;
	content: "";
}
.tit_dropdown.on .tit_dropdown_btn:after {
	transform: rotate(180deg);
	transform-origin: 50% 24%;
}
.tit_dropdown_area {
	overflow: hidden;
	height: 0;
	transition: height 0.4s;
}
.tit_dropdown.on .tit_dropdown_area {
	height: 179px;
}
.tit_dropdown_area:after {
	display: block;
	height: 8px;
	background: #e5e5e5;
	content: "";
}
.tit_dropdown .tbl_wrap {
	padding-top: 7px;
	padding-bottom: 10px;
}
.tit_dropdown .tbl_row th {
	color: #222;
	letter-spacing: -1.12px;
}
.tit_dropdown .btn_area {
	margin-top: 7px;
}
.tit_dropdown .btn_area button {
	font-size: 15px;
	font-weight: normal;
	letter-spacing: -0.6px;
}

.state_label {
	display: inline-block;
	height: 17px;
	font-size: 14px;
	padding: 1px 4px 0;
	border-width: 1px;
	border-style: solid;
	background: #fff;
	vertical-align: top;
	line-height: 17px;
}
.state_label.ty_onoff {
	border-color: #222;
	color: #222;
}
.state_label.ty_kind {
	border-color: #60a6a7;
	color: #60a6a7;
}
.state_label.ty_margin {
	border-color: #ff6967;
	color: #ff6967;
}
.state_label.ty_alarm {
	border-color: #2dc6c8;
	color: #2dc6c8;
}
.state_label.ty_out {
	color: #fff;
}
.lst_action.handled .state_label.ty_out {
	border-color: #2dc6c8;
	background: #2dc6c8;
}
.lst_action.force_handled .state_label.ty_out {
	border-color: #ff6967;
	background: #ff6967;
}
.state_label em {
	font-weight: bold;
}

.action_item {
	padding-bottom: 30px;
}
.action_item:before {
	display: block;
	height: 8px;
	background: #e5e5e5;
	content: "";
}
.action_item:first-child:before {
	display: none;
}
.action_item_state {
	padding: 20px 15px 10px;
	line-height: 20px;
}
.state_noaction {
	margin-bottom: 10px;
	padding-bottom: 10px;
	border-bottom: 1px dotted #e5e5e5;
}
.state_noaction .state_noaction_tx {
	display: inline-block;
	height: 19px;
	padding-top: 1px;
	margin-left: 3px;
	color: #ff6967;
	font-size: 14px;
	letter-spacing: -0.5px;
	vertical-align: top;
}
.action_item_state > .state_label {
	float: right;
}
.state_margin {
	display: inline-block;
	height: 19px;
	padding-top: 1px;
	color: #222;
	font-size: 16px;
	letter-spacing: -0.5px;
	vertical-align: top;
}
.action_item_prod {
	margin: 0 15px;
	padding: 15px;
	background: #f7f7f7;
	line-height: 20px;
	letter-spacing: -0.5px;
}
.action_item_prod .prod_name {
	color: #222;
	font-size: 16px;
}
.action_item_prod .prod_info {
	margin-top: 8px;
	color: #888;
	font-size: 15px;
	word-break: keep-all;
	word-wrap: break-word;
}
.action_item_prod .prod_info .prod_num {
	text-decoration: underline;
}
.action_item_order {
	margin: 0 15px;
	padding: 10px 15px 0;
	color: #888;
	font-size: 15px;
	letter-spacing: -0.5px;
	word-break: keep-all;
	word-wrap: break-word;
}
.action_item_offer {
	margin: 20px 15px 0;
	padding-top: 20px;
	border-top: 1px dotted #e5e5e5;
}
.action_item_offer .scroll_area {
	overflow-x: auto;
	overflow-y: hidden;
	margin: 0 -15px;
	padding: 0 15px;
	-webkit-overflow-scrolling: touch;
}
.action_item_offer .lst_offer {
	white-space: nowrap;
}
.action_item_offer .lst_offer_item {
	display: inline-block;
	width: 250px;
	margin-left: 15px;
	vertical-align: top;
}
.action_item_offer .lst_offer_item:first-child {
	margin-left: 0;
}
.action_item_offer .lst_offer_item:last-child {
	margin-right: 15px;
}
.action_item_offer .fld_chk {
	display: block;
	position: relative;
}
.action_item_offer .chk {
	position: absolute;
	top: 0;
	left: 0;
}
.action_item_offer label {
	display: block;
	padding: 0 0 0 30px;
}
.action_item_offer .state_label {
	margin-right: 1px;
}
.action_item_offer .offer_item_dtl {
	display: block;
	margin: 10px 0 0 -30px;
	padding: 18px 28px;
	border: 2px solid #f7f7f7;
	background: #f7f7f7;
	color: #888;
	font-size: 15px;
	letter-spacing: -0.5px;
	line-height: 19px;
	white-space: initial;
}
.action_item_offer .offer_item_dtl span {
	display: block;
}
.action_item_offer .offer_item_dtl .offer_dc {
	color: #222;
}
.action_item_offer .offer_item_dtl .offer_name {
	display: block;
	display: -webkit-box;
	overflow: hidden;
	min-height: 38px;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
}
.action_item_offer .chk {
	width: 20px;
	height: 20px;
	background-position: -192px -75px;
}
.action_item_offer .chk:checked {
	background-position: -212px -75px;
}
.action_item_offer .chk:checked + label .offer_item_dtl {
	border-color: #2dc6c8;
}
.action_item_offer .chk:disabled {
	display: none;
}
.action_item_offer .chk:disabled + label {
	padding-left: 0;
}
.action_item_offer .chk:disabled + label .offer_item_dtl {
	margin-left: 0;
}
.lst_action.handled .action_item_offer .chk:checked:disabled + label .offer_item_dtl {
	background: #2dc6c8;
	color: #fff;
}
.lst_action.force_handled .action_item_offer .chk:checked:disabled + label .offer_item_dtl {
	border-color: #ff6967;
	background: #ff6967;
	color: #fff;
}
.lst_action.handled .action_item_offer .chk:checked:disabled + label .offer_dc,
.lst_action.force_handled .action_item_offer .chk:checked:disabled + label .offer_dc {
	color: #fff;
}
.lst_action.handled .action_item_offer .chk,
.lst_action.force_handled .action_item_offer .chk {
	display: none;
}
.action_item_btnarea {
	margin-top: 20px;
	padding: 0 15px;
}
.action_item_btnarea .btn_area {
	margin-top: 10px;
}
.action_item_btnarea .btn_area .btnA:disabled {
	background: #555;
}
.action_item_btnarea .btn_area button,
.action_item_btnarea .btn_area a {
	font-size: 15px;
	font-weight: normal;
	letter-spacing: -0.5px;
}
.modal-alert.show {
	text-align: center;
	vertical-align: middle;
}
.modal-alert.show:after {
	display: inline-block;
	width: 1px;
	height: 100%;
	margin-left: -1px;
	vertical-align: middle;
	content: "";
}
.modal-alert .inner {
	display: inline-block;
	vertical-align: middle;
}
.modal-alert p {
	margin: 30px 34px 28px;
	color: #555;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: -0.3px;
}

/* 역마진 알림 설정 */
.tit_subarea.type_txt {
	display: table;
	width: 100%;
	padding: 0;
}
.tit_subarea.type_txt .tit_sub {
	display: table-cell;
	padding: 4px 15px 0;
	vertical-align: middle;
}
.tit_subarea.type_txt .tit_sub_detail {
	display: table-cell;
	padding: 4px 15px 0;
	text-align: right;
	vertical-align: middle;
}
.tit_sub_detail {
	color: #444;
	font-size: 13px;
	letter-spacing: -0.5px;
}

.lst_switch {
	margin: 16px 0 22px;
}
.lst_switch ul {
	overflow: hidden;
}
.lst_switch li {
	padding: 0 16px 0 18px;
	border-bottom: 1px solid #e5e5e5;
}
.lst_switch_txt {
	display: inline-block;
	color: #555;
	font-size: 14px;
	font-weight: normal;
	line-height: 44px;
	letter-spacing: -1.12px;
}
.lst_switch_input {
	float: right;
	line-height: 44px;
	vertical-align: top;
}
.lst_switch_input .switch_area {
	padding-top: 8px;
	vertical-align: top;
}

/* cross browsing */
.Android_4_0 .modal,
.Android_4_1 .modal,
.Android_4_2 .modal,
.Android_4_3 .modal {
	-webkit-transition: none;
	-moz-transition: none;
	-o-transition: none;
	transition: none;
}

.Android_4_0 .btn_flik .btn_wrap,
.Android_4_1 .btn_flik .btn_wrap,
.Android_4_2 .btn_flik .btn_wrap,
.Android_4_3 .btn_flik .btn_wrap {
	-webkit-transition: all 0s ease;
	-moz-transition: all 0s ease;
	-o-transition: all 0s ease;
	transition: all 0s ease;
}

.Android_4_0 .drag_drop .box_detail,
.Android_4_1 .drag_drop .box_detail,
.Android_4_2 .drag_drop .box_detail,
.Android_4_3 .drag_drop .box_detail {
	-webkit-transition: max-height 0s;
	-moz-transition: max-height 0s;
	-o-transition: max-height 0s;
	transition: max-height 0s;
}

.Android_4_0 .modal .lst_reg .info .tit,
.Android_4_1 .modal .lst_reg .info .tit,
.Android_4_2 .modal .lst_reg .info .tit,
.Android_4_3 .modal .lst_reg .info .tit {
	visibility: inherit;
}

.Android_4_0 .modal .lst_reg .info .desc,
.Android_4_1 .modal .lst_reg .info .desc,
.Android_4_2 .modal .lst_reg .info .desc,
.Android_4_3 .modal .lst_reg .info .desc {
	visibility: inherit;
}

/* 쪽지상세 (PrivateMessage) */
#pm_view::-webkit-scrollbar,
.pm_editor .pm_editor_textarea textarea::-webkit-scrollbar {
	width: 5px;
}
#pm_view::-webkit-scrollbar {
	border-left: 1px solid #eee;
	background-color: #fbfbfb;
}
#pm_view::-webkit-scrollbar-thumb,
.pm_editor .pm_editor_textarea textarea::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, 0.25);
}

.pm_html,
.pm_body {
	height: 100%;
}
#pm_wrap {
	position: relative;
	min-width: 320px;
	height: 100%;
}
#pm_header {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 10;
}
#pm_container,
#pm_content {
	min-height: 100%;
}
#pm_view {
	overflow-x: hidden;
	overflow-y: auto;
	position: absolute;
	top: 102px;
	right: 0;
	bottom: 167px;
	left: 0;
	padding-bottom: 25px;
	background: #f7f7f7;
	-webkit-overflow-scrolling: touch;
}
#pm_input {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	border-top: 1px solid #d9dadd;
	background: #fff;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
.pm_btn_ty1 {
	padding: 4px 12px 2px;
	border: 1px solid #888;
	border-radius: 15px;
	box-sizing: border-box;
	font-size: 13px;
	font-weight: 500;
	line-height: 16px;
	color: #222;
	letter-spacing: -0.43px;
}
.pm_sta .pm_sta_service {
	padding: 19px 20px 18px;
	background: #fff;
	text-align: center;
}
.pm_sta .pm_sta_title {
	font-size: 16px;
	font-weight: bold;
	line-height: 19px;
	color: #222;
	letter-spacing: -0.5px;
}
.pm_sta .pm_sta_profile {
	padding: 15px;
	background: #2dc6c8;
	line-height: 16px;
}
.pm_sta .pm_sta_profile:after {
	display: block;
	clear: both;
	content: "";
}
.pm_sta .pm_sta_name {
	float: left;
	font-size: 14px;
	font-weight: bold;
	color: #fff;
}
.pm_sta .pm_sta_tool {
	float: right;
}
.pm_sta .pm_sta_tool .pm_refresh_btn {
	width: 16px;
	height: 16px;
	background: url(../img/comm_btn.png) no-repeat -108px -85px;
	background-size: 320px auto;
	vertical-align: top;
}
.pm_reference {
	margin-top: 15px;
	font-size: 13px;
	line-height: 20px;
	color: #222;
	word-break: break-all;
	word-wrap: break-word;
}
.pm_reference:first-child {
	margin-top: 0;
}
.pm_reference:after {
	display: block;
	clear: both;
}
.pm_reference .pm_reference_name {
	float: left;
	margin-right: 5px;
}
.pm_reference .pm_reference_link {
	display: block;
	overflow: hidden;
	font-weight: bold;
	color: inherit;
	text-decoration: underline;
}
.pm_message {
	margin-top: 13px;
	font-size: 13px;
	line-height: 18px;
	color: #666;
	word-break: break-all;
	word-wrap: break-word;
}
.pm_message:first-child {
	margin-top: 0;
}
.pm_message a {
	color: inherit;
	text-decoration: underline;
}
.pm_attach_list {
	display: -webkit-flex-;
	display: flex;
	margin-top: 17px;
}
.pm_attach_list:first-child {
	margin-top: -5px;
}
.pm_attach_list li {
	min-width: 0;
	max-width: 111px;
	margin-right: 5px;
}
.pm_attach_list li:last-child {
	margin-right: 0;
}
.pm_attach_list .pm_attach_btn {
	display: block;
	position: relative;
	padding: 3px 7px 2px 28px;
	border: 1px solid #959595;
	border-radius: 3px;
}
.pm_attach_list .pm_attach_btn:before {
	position: absolute;
	top: 4px;
	left: 7px;
	width: 16px;
	height: 17px;
	background: url(../img/comm_btn.png) no-repeat -126px -85px;
	background-size: 320px auto;
	vertical-align: top;
	content: "";
}
.pm_attach_list .txt_crop {
	display: block;
	overflow: hidden;
	font-size: 12px;
	font-weight: bold;
	line-height: 20px;
	color: #000;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.pm_writer {
	font-size: 12px;
	line-height: 16px;
	color: #666;
}
.pm_writer .pm_writer_name {
	position: relative;
	margin-right: 10px;
}
.pm_writer .pm_writer_name:after {
	position: absolute;
	top: 1px;
	right: -5px;
	width: 1px;
	height: 10px;
	background: #d8d8d8;
	content: "";
}
.pm_root {
	margin-top: 8px;
	border-bottom: 1px solid #d8d8d8;
	background: #fff;
}
.pm_root:first-child {
	margin-top: 0;
}
.pm_root .pm_root_subject {
	position: relative;
	margin: 0 15px;
	padding: 13px 70px 10px 0;
	border-bottom: 1px solid #d8d8d8;
}
.pm_root .pm_root_subject:after {
	display: block;
	clear: both;
	content: "";
}
.pm_root .pm_urgency_icon {
	float: left;
	width: 23px;
	height: 24px;
	margin-right: 8px;
	background: url(../img/comm_btn.png) no-repeat -116px -105px;
	background-size: 320px auto;
	vertical-align: top;
}
.pm_root .pm_root_title {
	float: left;
	margin-top: 8px;
	font-size: 14px;
	font-weight: bold;
	line-height: 16px;
	color: #222;
	vertical-align: top;
}
.pm_root .pm_root_tool {
	position: absolute;
	top: 13px;
	right: 0;
}
.pm_root .pm_root_detail {
	padding: 16px 15px 8px;
}
.pm_root .pm_writer {
	margin-top: 7px;
	text-align: right;
}
.pm_root .pm_writer:first-child {
	margin-top: 0;
}
.pm_unit {
	margin-top: 8px;
}
.pm_unit:after {
	content: "";
	display: block;
	clear: both;
}
.pm_unit:first-child {
	margin-top: 0;
}
.pm_unit .pm_msg_bx {
	position: relative;
}
.pm_unit .pm_bubble {
	position: relative;
	padding: 15px;
	border: 1px solid #d6d6d6;
	border-radius: 7px;
	box-sizing: border-box;
}
.pm_unit .pm_writer {
	line-height: 15px;
}
.pm_unit .pm_writer .pm_writer_name {
	font-size: 13px;
	font-weight: bold;
	color: #000;
}
.pm_unit .pm_message {
	margin-top: 9px;
}
.pm_unit .pm_message:first-child {
	margin-top: 0;
}
.pm_unit .pm_attach_list {
	margin-top: 13px;
}
.pm_unit .pm_info {
	position: absolute;
	right: 100%;
	bottom: 6px;
	margin-right: 8px;
}
.pm_unit .pm_txt_read {
	display: block;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: #adadad;
}
.pm_unit .pm_txt_read.done {
	background: #2dc6c8;
}
.pm_unit.other .pm_msg_bx {
	margin-right: 56px;
	margin-left: 36px;
}
.pm_unit.other .pm_bubble {
	background: #f1ffff;
}
.pm_unit.other .pm_bubble:before {
	position: absolute;
	top: 11px;
	right: 100%;
	width: 13px;
	height: 16px;
	margin-right: 8px;
	background: url(../img/comm_btn.png) no-repeat -144px -85px;
	background-size: 320px auto;
	content: "";
}
.pm_unit.user .pm_msg_bx {
	margin-right: 15px;
	margin-left: 80px;
}
.pm_unit.user .pm_bubble {
	background: #fff;
}
.pm_editor {
	position: relative;
}
.pm_editor .pm_editor_col.textarea {
	padding-right: 105px;
}
.pm_editor .pm_editor_col.tool {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	width: 105px;
}
.pm_editor .pm_editor_textarea textarea {
	display: block;
	overflow-x: hidden;
	overflow-y: auto;
	width: 100%;
	height: 126px;
	padding: 15px 18px 15px 16px;
	border: 0;
	box-sizing: border-box;
	font-size: 13px;
	line-height: 18px;
	color: #666;
	resize: none;
}
.pm_editor .pm_editor_btn {
	width: 100%;
	height: 100%;
	background: #2dc6c8;
	font-size: 16px;
	color: #fff;
}
.pm_file {
	height: 39px;
	padding: 0 16px;
	border-top: 1px solid #e2e2e2;
}
.pm_file:after {
	content: "";
	display: block;
	clear: both;
}
.pm_file .pm_file_attach {
	position: relative;
	float: left;
	margin-top: 7px;
}
.pm_file .pm_file_attach input[type="file"] {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	width: 100%;
	height: 100%;
	border: 0;
	-webkit-appearance: none;
	opacity: 0;
}
.pm_file .pm_file_btn {
	display: inline-block;
	width: 26px;
	height: 26px;
	background: url(../img/comm_btn.png) no-repeat -88px -106px;
	background-size: 320px auto;
	vertical-align: top;
}
.pm_file .pm_file_desc {
	margin-left: 7px;
	font-size: 12px;
	line-height: 20px;
	color: #b0b0b0;
}
.pm_file .pm_file_list {
	display: -webkit-flex;
	display: flex;
	overflow: hidden;
	margin-top: 12px;
	padding-left: 7px;
}
.pm_file .pm_file_list li {
	position: relative;
	min-width: 0;
	margin-right: 20px;
	padding-right: 17px;
}
.pm_file .pm_file_list li:last-child {
	margin-right: 0;
}
.pm_file .pm_file_list .pm_filedel_btn {
	position: absolute;
	top: 0;
	right: 0;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background: #000;
}
.pm_file .pm_file_list .pm_filedel_btn:before,
.pm_file .pm_file_list .pm_filedel_btn:after {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 1px;
	height: 7px;
	margin-top: -3px;
	margin-left: -1px;
	background: #fff;
	-webkit-transfrom: rotate(45deg);
	transform: rotate(45deg);
	content: "";
}
.pm_file .pm_file_list .pm_filedel_btn:after {
	-webkit-transfrom: rotate(-45deg);
	transform: rotate(-45deg);
}

.pm_file .pm_file_name {
	display: block;
	overflow: hidden;
	font-size: 13px;
	line-height: 15px;
	color: #000;
	text-overflow: ellipsis;
	white-space: nowrap;
}

/* 온도 준수소명 */
.btn_temperature {
	padding: 0 6px;
	border: 1px solid #222;
	border-radius: 5px;
	font-size: 18px;
	white-space: nowrap;
}
.btn_temperature.register {
	border-color: #9a6cf7;
	color: #9a6cf7;
}

/* 서브계정 가입 */
.inp_message {
	margin-top: 3px;
	font-size: 12px;
	line-height: 17px;
	letter-spacing: -0.46px;
	color: #ff6967;
}
.sub_account .txt_desc {
	padding: 15px 0;
}
.sub_account .btn_area {
	margin-top: 27px;
}
.sub_account .btnB:disabled {
	background-color: #f5f5f5;
	color: #c3c3c3;
	cursor: default;
}
.sub_account .btnC {
	font-size: 13px;
}
.sub_account .btnC,
.sub_account .btnC:first-child {
	width: 77px;
	padding: 0 13px;
	line-height: 41px;
	letter-spacing: -0.75px;
}
.sub_account .splitFrm .type_btn {
	vertical-align: top;
}
.sub_account .split_cell {
	width: 100%;
}
.sub_account label {
	letter-spacing: -1.12px;
}
.sub_account .inp_wrap .inp {
	padding-right: 24px;
}
.sub_account .inp {
	letter-spacing: -0.8px;
}
.sub_account .inp_message.ty_error {
	display: none;
}
.sub_account .inp_message.ty_info {
	color: #888;
}
.sub_account .has_alert .inp_message.ty_error {
	display: block;
}
.sub_account .list_tit {
	font-weight: bold;
}
.sub_account .cont_area {
	display: none;
	padding: 20px 15px;
	background-color: #f7f7f7;
	border-top: 1px solid #e5e5e5;
	font-size: 13px;
}
.sub_account .firstShow .cont_area {
	display: block !important;
}
.sub_account .list_policy .chk {
	position: absolute;
	top: 17px;
	left: 0;
}
.sub_account .policy_area .txt_desc {
	display: inline-block;
	padding: 15px 0 15px 15px;
	font-weight: bold;
}
.sub_account .list_cm > li > a {
	width: calc(100% - 32px);
	margin-left: 32px;
	padding-left: 0;
}
.sub_account .complete_info {
	padding: 57px 0 39px;
	text-align: center;
}
.sub_account .complete_info .ico_cm {
	position: relative;
	margin-bottom: 34px;
	padding: 0 15px;
}
.sub_account .complete_info .ico_cm:before {
	display: inline-block;
	width: 72px;
	height: 72px;
	border-radius: 100px;
	border: 1px solid #555;
	background: none;
}
.sub_account .complete_info .ico_cm:after {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 37px;
	height: 24px;
	margin: -12px 0 0 -18px;
	background: url(../img/comm_ico.png) no-repeat -228px -10px;
	background-size: 320px auto;
	content: "";
}
.sub_account .complete_info .txt_error {
	margin-bottom: 13px;
}
.sub_account .complete_info .txt_b {
	letter-spacing: -0.59px;
}
.sub_account .complete_info .txt_desc {
	padding: 0;
	letter-spacing: -0.37px;
}
.sub_account .complete_link {
	margin: 17px 49px 0;
	padding-top: 17px;
	border-top: 1px solid #e5e5e5;
}
.sub_account .complete_link:last-child {
	padding-bottom: 18px;
	border-bottom: 1px solid #e5e5e5;
}
.sub_account .complete_link_inner {
	display: table;
	width: 100%;
}
.sub_account .complete_link .txt_desc {
	display: table-cell;
	width: 55.59%;
	padding: 0;
	font-weight: bold;
	letter-spacing: -1.12px;
}
.sub_account .complete_link .link_wrap {
	display: table-cell;
}
.sub_account .complete_link .link_item + .link_item {
	margin-top: 13px;
}
.sub_account .complete_link .btn_cm {
	font-size: 14px;
	font-weight: 500;
	color: #2dc6c8;
	letter-spacing: -0.56px;
	text-decoration: underline;
	vertical-align: top;
}
.sub_account .complete_link .btn_cm:before {
	display: none;
}
.sub_account .complete_link .btn_cm:after {
	display: inline-block;
	margin: -2px 0 0 4px;
	vertical-align: middle;
	content: "";
}
.sub_account .complete_link .btn_cm.shortcut:after {
	width: 8px;
	height: 9px;
	background: url(../img/comm_btn.png) no-repeat -232px -36px;
	background-size: 320px auto;
}
.sub_account .complete_link .btn_cm.download:after {
	width: 16px;
	height: 16px;
	background: url(../img/comm_btn.png) no-repeat -300px 2px;
	background-size: 320px auto;
}

/* 보냉가방 지급/회수 */
.tfoot.new {
	margin-top: 2.5%;
	background: #e9f2ff;
}
.tfoot.new > li {
	border-top: 1px solid #d4e6ff;
	border-bottom: 1px solid #d4e6ff;
}
.evt_case06 .deli_product,
.evt_case07 .deli_product,
.evt_case08 .deli_product {
	margin-bottom: 20px;
	border-bottom: none;
}
.evt_case02 .deli_product > ul > li,
.evt_case06 .deli_product > ul > li,
.evt_case07 .deli_product > ul > li,
.evt_case08 .deli_product > ul > li {
	border-bottom: 1px dashed #e0e0e0;
}
.evt_case06 .deli_product > ul > li:last-of-type,
.evt_case07 .deli_product > ul > li:last-of-type,
.evt_case08 .deli_product > ul > li:last-of-type {
	border: none;
}
.evt_case02 .deli_product ul li,
.evt_case06 .deli_product ul li,
.evt_case07 .deli_product ul li,
.evt_case08 .deli_product ul li {
	display: flex;
	align-items: flex-end;
}
.evt_case02 .deli_product ul li .btnEVT,
.evt_case06 .deli_product ul li .btnEVT,
.evt_case07 .deli_product ul li .btnEVT,
.evt_case08 .deli_product ul li .btnEVT {
	background: #555555;
	color: #fff;
	padding: 2px 8px;
	box-sizing: border-box;
	margin: 0 0 0 auto;
}
.evt_case02 .deli_product .total_info {
	padding: 3px 15px 3px 15px;
}
.modal-delivery_bag2 .pop_content,
.modal-delivery_bag3 .pop_content {
	padding: 15px 3% 42px;
	background-color: #f3f3f3;
}
.modal-delivery_bag2 .modal_deliv_decision,
.modal-delivery_bag3 .modal_deliv_decision {
	border: none;
	width: 94%;
}
.modal-delivery_bag2 .modal_deliv_decision .bag_section,
.modal-delivery_bag3 .modal_deliv_decision .bag_section {
	margin-bottom: 30px;
}
.modal-delivery_bag2 .modal_deliv_decision h4 {
	background-color: #ffe1d5;
	padding: 4px 0;
	color: #ff4800;
	margin-bottom: 10px;
}
.modal-delivery_bag3 .modal_deliv_decision h4 {
	background-color: #dadee4;
	padding: 4px 0;
	color: #535860;
	margin-bottom: 10px;
}
.modal-delivery_bag3 .modal_deliv_decision p {
	margin-bottom: 10px;
	font-weight: bold;
}
.modal-delivery_bag3 .modal_deliv_decision p span {
	color: #ff5b59;
}
.modal-delivery_bag2 .modal_deliv_btnarea,
.modal-delivery_bag3 .modal_deliv_btnarea {
	margin: 0;
	width: 94%;
}
.modal-delivery_bag2 .modal_deliv_decision .bag_section .bag_section_flex,
.modal-delivery_bag3 .modal_deliv_decision .bag_section .bag_section_flex {
	display: flex;
	justify-content: space-between;
}
.modal-delivery_bag2 .modal_deliv_decision .bag_section .modal_bag_imgbx,
.modal-delivery_bag3 .modal_deliv_decision .bag_section .modal_bag_imgbx {
	width: 48%;
}
.modal-delivery_bag2 .modal_deliv_decision .bag_section .modal_bag_imgbx img,
.modal-delivery_bag3 .modal_deliv_decision .bag_section .modal_bag_imgbx img {
	width: 100%;
}
.modal-delivery_bag2 .modal_deliv_decision .bag_section .bag_section_flex .selected,
.modal-delivery_bag3 .modal_deliv_decision .bag_section .bag_section_flex .selected {
	width: 48%;
	margin-left: 2%;
}
.modal-delivery_bag2
	.modal_deliv_decision
	.bag_section
	.bag_section_flex
	.selected
	input[type="radio"],
.modal-delivery_bag3
	.modal_deliv_decision
	.bag_section
	.bag_section_flex
	.selected
	input[type="radio"] {
	display: none;
}
.modal-delivery_bag2
	.modal_deliv_decision
	.bag_section
	.bag_section_flex
	.selected
	input[type="radio"]
	+ label,
.modal-delivery_bag3
	.modal_deliv_decision
	.bag_section
	.bag_section_flex
	.selected
	input[type="radio"]
	+ label {
	display: inline-block;
	cursor: pointer;
	padding: 4px 0;
	width: 100%;
	text-align: center;
	font-weight: bold;
	font-size: 13px;
	margin-bottom: 5px;
}
.modal-delivery_bag2
	.modal_deliv_decision
	.bag_section
	.bag_section_flex
	.selected
	input[type="text"]
	+ label,
.modal-delivery_bag3
	.modal_deliv_decision
	.bag_section
	.bag_section_flex
	.selected
	input[type="text"]#selDirect {
	width: 100%;
	padding: 4px 0;
	border: none;
	text-align: center;
}
.modal-delivery_bag3
	.modal_deliv_decision
	.bag_section
	.bag_section_flex
	.selected
	input[type="text"]#selDirect::placeholder {
	text-align: center;
	color: #000;
}
.modal-delivery_bag2
	.modal_deliv_decision
	.bag_section
	.bag_section_flex
	.selected
	input[type="radio"]
	+ label,
.modal-delivery_bag3
	.modal_deliv_decision
	.bag_section
	.bag_section_flex
	.selected
	input[type="radio"]
	+ label {
	background-color: #fff;
	color: #222;
}
.modal-delivery_bag2
	.modal_deliv_decision
	.bag_section
	.bag_section_flex
	.selected
	input[type="radio"]:checked
	+ label,
.modal-delivery_bag3
	.modal_deliv_decision
	.bag_section
	.bag_section_flex
	.selected
	input[type="radio"]:checked
	+ label {
	background-color: #9a6cf7;
	color: #fff;
}
.pop_layer.alert.evt_case09 strong {
	margin-bottom: 20px;
}
.pop_layer.alert.evt_case09 ul {
	width: 75%;
	margin: 0 auto;
	border: 1px solid #e5e5e5;
	padding: 20px 0;
}
.pop_layer.alert.evt_case09 .pop_wrap {
	transform: translateY(-50%);
}
.pop_layer.alert ul li {
	width: 90%;
	margin: 0 auto;
}
.pop_layer.alert ul li p span.txt_desc {
	color: #9a6cf7;
}
.pop_layer.alert ul li p:first-of-type {
	margin-bottom: 5px;
}
.pop_layer.alert ul li:first-of-type {
	border-bottom: 1px solid #e5e5e5;
	margin-bottom: 10px;
	padding-bottom: 10px;
}
.modal-delivery_bag2
	.modal_deliv_decision
	.bag_section
	.bag_section_flex
	.selected
	div.flex_wrapper,
.modal-delivery_bag3
	.modal_deliv_decision
	.bag_section
	.bag_section_flex
	.selected
	div.flex_wrapper {
	display: flex;
	width: 100%;
}
.modal-delivery_bag2
	.modal_deliv_decision
	.bag_section
	.bag_section_flex
	.selected
	div.flex_wrapper
	input,
.modal-delivery_bag3
	.modal_deliv_decision
	.bag_section
	.bag_section_flex
	.selected
	div.flex_wrapper
	input[type="radio"] {
	width: 57%;
}
.modal-delivery_bag2
	.modal_deliv_decision
	.bag_section
	.bag_section_flex
	.selected
	div.flex_wrapper
	.print_bg,
.modal-delivery_bag3
	.modal_deliv_decision
	.bag_section
	.bag_section_flex
	.selected
	div.flex_wrapper
	.print_bg {
	display: flex;
	align-items: center;
	background: #fff;
	width: 50%;
}
.modal-delivery_bag2
	.modal_deliv_decision
	.bag_section
	.bag_section_flex
	.selected
	div.flex_wrapper
	.print_bg
	input[type="text"],
.modal-delivery_bag3
	.modal_deliv_decision
	.bag_section
	.bag_section_flex
	.selected
	div.flex_wrapper
	.print_bg
	input[type="text"] {
	border: none;
	text-align: right;
	width: 50%;
}
.modal-delivery_bag2
	.modal_deliv_decision
	.bag_section
	.bag_section_flex
	.selected
	div.flex_wrapper
	.print_bg
	input[type="text"]
	+ label,
.modal-delivery_bag3
	.modal_deliv_decision
	.bag_section
	.bag_section_flex
	.selected
	div.flex_wrapper
	.print_bg
	input[type="text"]
	+ label {
	width: 50%;
	text-align: left;
}

/* 검수목록 새로고침 버튼 */
.deliverty_status.btntype {
	display: flex;
	align-items: center;
}
.deliverty_status.btntype .deli_status_info {
	flex: 1;
}
.deliverty_status.btntype .btn_refresh {
	position: relative;
	width: 45px;
	height: 62px;
}
.deliverty_status.btntype .btn_refresh:before {
	position: absolute;
	top: 9px;
	left: 0;
	width: 1px;
	height: 46px;
	background-color: #e2e7e9;
	content: "";
}
.btn_refresh:after {
	position: absolute;
	top: 50%;
	left: 50%;
	display: inline-block;
	width: 20px;
	height: 20px;
	transform: translate(-50%, -50%);
	background: url(../img/ico_refresh.png) no-repeat;
	background-size: 20px auto;
	content: "";
}
.btn_area .btn_refresh {
	position: relative;
	margin-left: 9px;
	height: 45px;
	border-left: 1px solid #555;
}

/* 검수목록 부재용봉투 그리드변경 */
.grid_group.thead {
	justify-content: space-around;
}
.grid_group.thead .type_flag {
	margin-right: 0;
}
.grid_group.thead .grid {
	padding: 14px 5px 16px;
}
.grid_group {
	display: flex;
	align-items: center;
}
.grid_group .grid {
	padding: 14px 20px 16px;
	text-align: center;
	letter-spacing: -0.5px;
	word-break: break-all;
	word-wrap: break-word;
	font-size: 16px;
	line-height: 1.2;
}
.grid_group .grid:last-child {
	flex: 1;
}
.grid_group .grid:first-of-type {
	padding: 14px 5px 16px;
}
.grid_group .grid_cell {
	font-size: 16px;
}
.grid_group .grid .name {
	margin-top: 3px;
}
.grid_inr {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.grid_inr.bg {
	margin: 11px 5px 0;
	padding: 5px 0;
	box-sizing: border-box;
	line-height: 1;
}
.grid_inr.bg.blue {
	background-color: #e9f2ff;
}
.grid_inr.bg.gray {
	background-color: #f7f7f7;
}
.grid_inr.bg .grid_cell {
	position: relative;
	padding: 0 5px;
	width: calc(100% / 3);
	font-size: 13px;
	box-sizing: border-box;
}
.grid_inr.bg .grid_cell .type {
	font-size: 13px;
	color: #838383;
	word-break: break-word;
}
.grid_inr.bg .grid_cell + .grid_cell:before {
	display: inline-block;
	position: absolute;
	top: 2px;
	left: 0;
	width: 1px;
	height: 10px;
	background: #c8d1de;
	content: "";
}

.txt_box {
	margin-top: 35px;
}
.txt_box .txt_desc {
	font-size: 16px;
	line-height: 1.3;
}
.txt_box .txt_desc + .txt_desc {
	position: relative;
	margin-top: 20px;
	padding-left: 17px;
}
.txt_box .txt_desc.amp:before {
	display: inline-block;
	position: absolute;
	top: 0;
	left: 0;
	content: "※";
}

/* 부재용봉투 뱃지 */
.type_flag.vinyl {
	border: 1px solid #222;
	background: #222;
}
.type_flag.vinyl .flag_tit {
	color: #222;
}
.body_tms .deli_type_list .flag_list li {
	margin-bottom: 5px;
}

/* 부재용봉투 팝업 */
.absence_box {
	padding: 10px 15px 20px;
	box-sizing: border-box;
}
.choice_wrap {
	margin-top: 20px;
}
.absence_grid {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.absence_grid + .absence_grid {
	border-top: 1px solid #e2e7e9;
	margin-top: 15px;
	padding-top: 15px;
}
.absence_grid .choice_tit {
	width: 100%;
	margin-right: 5px;
	text-align: left;
	font-size: 15px;
	color: #222;
}
.absence_grid .choice_select {
	width: 100%;
}
.absence_grid .modal_deliv_slct {
	margin-bottom: 0;
}

/* 배송기사 앱 메인 현황 */
.deli_line {
	padding-top: 8px;
	background-color: #e5e5e5;
}
.deli_info_date {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 12px 15px 11px;
	font-size: 12px;
	color: #888;
	letter-spacing: -0.4px;
	line-height: 1;
	.deli_term {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		min-height: 22px;
		padding: 3px 10px 1px;
		vertical-align: top;
		border-radius: 11px;
		border: solid 1px #9a6cf7;
		box-sizing: border-box;
		line-height: 1;
	}
	.deli_term_tit {
		margin-right: 3px;
		color: #9a6cf7;
	}
}
.deli_sec {
	padding: 15px 0 25px;
	.deli_info_area {
		line-height: 1;
	}
	.deli_info_tbl {
		margin-top: 10px;
		padding: 0 15px;
	}
	.deli_name {
		font-size: 13px;
		letter-spacing: -0.4px;
		color: #888;
	}
	.deli_score {
		display: inline-block;
		margin-top: 2px;
		font-size: 16px;
		font-weight: bold;
		letter-spacing: -0.5px;
		color: #9a6cf7;
		line-height: 1;
		.deli_score_total {
			display: inline-block;
			font-size: 23px;
			letter-spacing: -0.7px;
		}
		> span {
			vertical-align: 2px;
		}
		&.ty_red {
			color: #ff5b59;
		}
	}
	.deli_score_num {
		font-size: 16px;
		letter-spacing: -0.5px;
		color: #555;
		&:before {
			content: "";
			display: inline-block;
			width: 12px;
			height: 12px;
			margin-right: 2px;
			background-image: svgIcon("555");
		}
		&.num_1 {
			color: #ff5b59;
			&:before {
				background-image: svgIcon("ff5b59");
			}
		}
		&.num_5 {
			color: #9a6cf7;
			&:before {
				background-image: svgIcon("9a6cf7");
			}
		}
	}
	.deli_score_case {
		text-decoration: underline;
		&.num_1 {
			color: #ff5b59;
		}
		&.num_5 {
			color: #9a6cf7;
		}
	}
	.deli_per_sec {
		margin-top: 6px;
		padding: 0 15px;
	}
	.deli_per_area {
		padding: 14px 0 18px;
		&:not(:first-child) {
			border-top: solid 1px #e5e5e5;
		}
		&.ty_red {
			.deli_per {
				color: #ff5b59;
			}
			.deli_per_bar {
				background-color: #ff5b59;
			}
		}
	}
	.deli_per_title {
		display: inline-block;
		font-size: 16px;
		letter-spacing: -0.5px;
		color: #222;
		// margin-top: 2px;
		vertical-align: middle;
	}
	.deli_per {
		display: inline-block;
		font-size: 18px;
		letter-spacing: -0.6px;
		color: #9a6cf7;
		vertical-align: middle;
	}
	.deli_per_box {
		margin-top: 6px;
		position: relative;
		height: 5px;
		background-color: #f0f0f0;
		.deli_per_bar {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			background-color: #c4abf6;
		}
	}
}
/* 고객 만족도 평가 상세 레이어팝업 */
.deli_star_box {
	position: relative;
	overflow: hidden;
	width: 60px;
	height: 12px;
	margin: 0 auto;
	background-image: svgIcon("e5e5e5");
	background-repeat: repeat-x;
	.deli_star_bar {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		background-image: svgIcon("222");
	}
}

/* 로그인 2단계 인증 */
.verifi_guide {
	font-size: 15px;
	line-height: 20px;
	letter-spacing: -0.6px;
	color: #999;
}
.verifi_btnarea {
	margin-top: 19px;
	> button {
		width: 100%;
		margin-top: 8px;
		background-color: #fff;
		border: 1px solid #777;
		font-size: 15px;
		line-height: 38px;
		letter-spacing: -0.46px;
		color: #777;
		&:first-child {
			margin-top: 0;
		}
		em {
			color: #ff5b59;
		}
	}
}
.verifi_desc {
	margin-top: 8px;
	font-size: 12px;
	line-height: 17px;
	letter-spacing: -0.4px;
	color: #999;
}
.verifi_inp {
	margin-top: 19px;
}

/* 패킹스캔 */
.notice_info {
	margin-top: 8px;
	font-size: 12px;
	letter-spacing: -0.46px;
	color: #999;
	em {
		color: #ff6967;
	}
}

/* 출하검수 */
.deli_enabletab:before {
	content: "";
	display: block;
	border-top: 8px solid #e5e5e5;
}
.deli_tab {
	border-bottom: 1px solid #e5e5e5;
	&_list {
		display: flex;
		width: 100%;
		table-layout: fixed;
		border-collapse: collapse;

		li {
			flex: 1;
			position: relative;
			box-sizing: border-box;
			vertical-align: top;
			a {
				position: relative;
				display: block;
				height: 47px;
				line-height: 47px;
				text-align: center;
				color: #888;
				font-size: 16px;
				letter-spacing: -1px;
				box-sizing: border-box;
				&:not([href]) {
					color: #555;
					font-weight: normal;
				}
			}
			&.active {
				&:after {
					content: "";
					position: absolute;
					left: 0;
					right: 0;
					bottom: -1px;
					border-bottom: 2px solid #9a6cf7;
					z-index: 5;
				}
				a {
					color: #222;
					font-weight: bold;
				}
				em {
					color: #9a6cf7;
					font-weight: bold;
				}
			}
		}
	}
}
.deli_scroll {
	overflow-y: auto;
	// max-height: 1000px;
	max-height: calc(100vh - 193px);
	-webkit-overflow-scrolling: touch;
}
.deli_schre_text {
	margin: 10px 20px;
	padding: 24px 0;
	box-sizing: border-box;
	border-radius: 4px;
	border: solid 1px #e1e1e1;
	background-color: #eaeaea;
	p {
		text-align: center;
		font-weight: bold;
		color: #222;
		font-size: 18px;
		line-height: 1.33;
		letter-spacing: -0.6px;
	}
	&.error {
		p {
			color: #ff5b59;
		}
	}
}

.deli_barcodebox {
	padding: 10px;
	background: #fff;
	.deli_tabcontents & {
		margin-top: 10px;
	}
	.barcode_area {
		display: flex;
		// flex: 1;
		align-items: center;
		// justify-content: space-between;
		margin-top: 18px;
		&:first-child:only-child {
			margin-top: 0;
		}
		.barcode_area_input {
			flex-grow: 1;
			flex-shrink: 1;
			flex-basis: auto;
			display: block;
			width: 100%;
			position: relative;
			padding-right: 35px;
			border: 1px solid #e8e8e8;
			background-color: #fff;
			height: 40px;
			box-sizing: border-box;
			label {
				display: block;
				height: 100%;
				padding-left: 40px;
				&:before {
					content: "";
					display: block;
					position: absolute;
					left: 10px;
					top: 50%;
					width: 20px;
					height: 20px;
					transform: translateY(-50%);
					background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='%23CFCFCF' stroke-width='2' fill='none' fill-rule='evenodd'%3E%3Cpath d='M5 19H2a1 1 0 0 1-1-1v-3M15 19h3a1 1 0 0 0 1-1v-3M5 1H2a1 1 0 0 0-1 1v3M15 1h3a1 1 0 0 1 1 1v3'/%3E%3Cpath stroke-linecap='square' stroke-dasharray='1,3.25' d='M1 10h18.5'/%3E%3C/g%3E%3C/svg%3E%0A");
				}
			}
			.barcode_btn_reset {
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
				width: 35px;
				height: 40px;
				& > span {
					display: block;
					width: 14px;
					height: 14px;
					margin: auto;
					background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 0a7 7 0 1 1 0 14A7 7 0 0 1 7 0zm2.744 4.099a.75.75 0 0 0-.978.074L7 5.94 5.234 4.173a.757.757 0 0 0-.978-.074l-.084.073a.75.75 0 0 0 .001 1.062L5.94 7 4.173 8.766a.757.757 0 0 0-.074.978l.073.084.084.073a.75.75 0 0 0 .978-.074L7 8.06l1.766 1.766a.757.757 0 0 0 .978.074l.084-.073.073-.084a.75.75 0 0 0-.074-.978L8.06 7l1.766-1.766a.757.757 0 0 0 .074-.978l-.073-.084z' fill='%23B3B3B3' fill-rule='evenodd'/%3E%3C/svg%3E%0A");
				}
			}
		}
		input {
			width: 100%;
			height: 100%;
			padding: 0;
			font-size: 16px;
			color: #222;
			text-align: left;
			border: 0;
			background: transparent;
			font-weight: bold;
			outline: none;
			&::placeholder {
				color: #888;
				font-weight: normal;
				padding-top: 3px;
				text-align: left;
				font-size: 16px;
				color: #999;
			}
		}
		button {
			flex-grow: 1;
			flex-shrink: 1;
			flex-basis: auto;
			width: 82px;
			line-height: 40px;
			font-weight: normal;
			font-size: 14px;
			box-sizing: border-box;
			margin: 0;
		}
		.btnB {
			width: 87px;
			padding: 0 10px;
			white-space: nowrap;
		}
	}
}

/* 스캐너설정 */
.scanner_area_input {
	display: block;
	width: 100%;
	height: 45px;
	border: solid 1px #e5e5e5;
	background-color: #f5f5f5;

	input {
		display: block;
		width: 100%;
		height: 100%;
		border: 0;
		background: none;
		text-align: center;
		font-size: 15px;
		letter-spacing: -0.8px;
		color: #222;
		&[readonly] {
			outline: none;
		}
	}
}

/* [MBO] 비밀번호 찾기 UI/UX 신규 적용 */
.mbo_fpw {
	overflow: hidden;
	padding: 62px 45px 0;
}

.mbo_fpw .tp_section {
	border-bottom: 1px solid #000;
}

.mbo_fpw .md_section {
	padding: 40px 0;
}

.mbo_fpw .txt_info {
	margin-bottom: 10px;
	color: #ff5b59;
	font-size: 18px;
	font-weight: 400;
	line-height: 26px;
	text-align: center;
}

.mbo_fpw .txt_info .info {
	display: block;
}

.mbo_fpw .txt_desc {
	color: #777;
	font-size: 15px;
	font-weight: 400;
	line-height: 21px;
	text-align: center;
}

.mbo_fpw .tp_section h2 {
	padding-bottom: 20px;
	color: #222;
	font-size: 24px;
	font-weight: 700;
	line-height: 28px;
}

.mbo_fpw .tab_ui:after {
	content: "";
	display: block;
	clear: both;
}

.mbo_fpw .tab_ui li {
	float: left;
	width: 50%;
}

.mbo_fpw .tab_ui li a {
	display: block;
	height: 48px;
	color: #222;
	font-size: 14px;
	font-weight: 400;
	line-height: 48px;
	text-align: center;
}

.mbo_fpw .tab_ui li.active a {
	border-bottom: 4px solid #000;
	font-weight: 700;
}

.mbo_fpw .inp_section {
	overflow: hidden;
	display: flex;

	& + .inp_section {
		margin-top: 8px;
	}
}

.mbo_fpw .inp_section .col_item {
	display: inline-block;
	box-sizing: border-box;
}

.mbo_fpw .inp_section.ty_solo .col_item {
	width: 100%;
}

.mbo_fpw .inp_section.ty_eamil .col_item {
	width: calc(100% - 96px);
	padding-right: 8px;
}

.mbo_fpw .inp_section.ty_eamil .col_item + .col_item {
	width: 96px;
	padding-right: 0;
}

.mbo_fpw .inp_section.ty_eamil button {
	width: 100%;
	height: 43px;
	padding: 0;
	color: #222;
	font-size: 12px;
	font-weight: 400;
	line-height: 43px;
}

.mbo_fpw .inp_section .inp_wrap {
	display: inline-block;
	width: 100%;
}

.mbo_fpw .btn_section .btn_area {
	margin-top: 40px;
}

.mbo_fpw .inp_desc_section {
	padding-bottom: 8px;
	color: #777;
	font-size: 12px;
	font-weight: 400;
	line-height: 17px;
}

/* 장바구니 준비여부 확인 팝업 */
.pop_layer.pop_layer_shoppingbag .pop_wrap {
	left: 20px;
	right: 20px;
}
.pop_layer.pop_layer_shoppingbag .pop_content {
	padding-top: 25px;
	padding-bottom: 25px;
}
.pop_layer.alert .shoppingbag_tit {
	display: block;
	margin-bottom: 25px;
	font-weight: normal;
	font-size: 18px;
	line-height: 25px;
	color: #222;
	letter-spacing: -1px;
}
.pop_layer.alert .shoppingbag_imgdesc {
	display: block;
	margin-bottom: 8px;
	font-size: 13px;
	line-height: 16px;
	color: #666;
}
.pop_layer.alert .shoppingbag_img {
	width: 170px;
	height: 150px;
	vertical-align: top;
}
.pop_layer.alert .shoppingbag_txtbx {
	max-width: 320px;
	margin: 25px auto 0;
	line-height: 20px;
}
.pop_layer.alert .shoppingbag_txt {
	display: inline-block;
	position: relative;
	font-weight: bold;
	font-size: 16px;
	color: #222;
	box-shadow: inset 0 -8px 0 rgba(154, 108, 247, 0.2);
	vertical-align: top;
}
.pop_layer.alert .shoppingbag_txt + .shoppingbag_txt {
	margin-top: 8px;
}
.pop_layer.alert .shoppingbag_txt em {
	font-weight: inherit;
	color: #ff5b59;
}
